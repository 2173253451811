import React, { useEffect, useState } from "react";
import "./coursepage.css";
import { useUserAuth } from "../../Context/UseAuthContext";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import axios from "axios";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { truncateText } from "../trunacateText";
import { CiSearch } from "react-icons/ci";
import coursesimg from "../../images/coursesimg.gif";
import NoData from "../../images/NoData.gif";
import Slider from "react-slick";
import VideoPlayer from "../../Component/VideoPlayer";

const CoursePage = () => {
  const {
    getUserData,
    fetchDataCourse,
    courses,
    searchCourse,
    setSearchCourse,
    setSingleCourseId,
    user,
    token,
    allMainCategory,
    allSubCategory,
    // setCategoryFilter,
    categoryFilter,
    subCategoryFilter,
    // setSubCategoryFilterCourses,
    navigate,
  } = useUserAuth();

  const [isOpen, setIsOpen] = useState(true);
  const [secOpen, setISecsOpen] = useState(true);
  // const [isOpenbtn, setIsOpenbtn] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  const [showAllCategories, setShowAllCategories] = useState(null);
  const [showAllSubCategories, setShowAllSubCategories] = useState(null);

  // const [checkedCategory, setCheckedCategory] = useState(null);
  // const [subcheckedCategory, setSubCheckedCategory] = useState(null);

  // const handleCheckboxChange = (category) => {
  //   setCategoryFilter(category);
  //   setSubCategoryFilterCourses("");
  //   if (checkedCategory === category) {
  //     // If the clicked category is already checked, uncheck it
  //     setCategoryFilter("");
  //     fetchDataCourse();
  //     setCheckedCategory(null);
  //   } else {
  //     // Otherwise, set the clicked category as checked
  //     setCheckedCategory(category);
  //   }
  // };
  // const handleSUBCheckboxChange = (category) => {
  //   setCategoryFilter("");
  //   setSubCategoryFilterCourses(category);
  //   if (subcheckedCategory === category) {
  //     // If the clicked category is already checked, uncheck it
  //     setSubCategoryFilterCourses("");
  //     fetchDataCourse();
  //     setSubCheckedCategory(null);
  //   } else {
  //     // Otherwise, set the clicked category as checked
  //     setSubCheckedCategory(category);
  //   }
  // };

  // const toggleDropdown = () => {
  //   setIsOpenbtn(!isOpenbtn);
  // };

  const toggleShowAllCategories = (data) => {
    if (showAllCategories !== null) {
      return setShowAllCategories(null);
    }
    setShowAllCategories(data);
  };
  const toggleShowAllSubCategories = (data) => {
    if (showAllSubCategories !== null) {
      return setShowAllSubCategories(null);
    }
    setShowAllSubCategories(data);
  };
  const toggleCoursesAccordion = () => {
    setIsOpen(!isOpen);
  };

  const toggleCoursesAccordionSecound = () => {
    setISecsOpen(!secOpen);
  };

  const handleAddToCart = (e) => {
    if (!token) {
      toast.error("Please Login to your Account");
      return;
    }
    const id = user._id;

    // Check if the ID is already in the cart
    const isAlreadyInCart = user.cart.some((item) => item.ID === e._id);

    // If the ID is already in the cart, do not add it again
    if (isAlreadyInCart) {
      toast("Item is already in the cart.", {
        icon: "ℹ️",
        style: {
          borderRadius: "10px",
          background: "#blue",
          color: "#222",
          padding: "10px",
        },
      });

      return; // Exit the function early
    }
    // If the ID is not in the cart, add it
    const payload = {
      cart: [
        ...user.cart,
        {
          VendorID: e.userID,
          ID: e._id,
          courseCategory: e.courseCategory,
          imageUrl: e.imageUrl,
          mainCategory: e.mainCategory,
          subCategory: e.subCategory,
          postDesc: e.postMessage,
          postName: e.postName,
          Price: e.price,
          tags: e.tags,
          videoUrl: e.videoUrl,
          zipUrl: e.zipUrl,
          timestamp: Date.now(),
        },
      ],
    };

    axios
      .patch(`https://smartuter.com/api/enduser/edit/${id}`, payload)
      .then((res) => {
        if (res.data.msg === true) {
          toast.success("Course Added Successfully to the Cart");
          getUserData(token);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (courses === null) {
      fetchDataCourse();
    }
    fetchDataCourse();
  }, [categoryFilter, subCategoryFilter, searchCourse]);

  //MUlti Categories selections
  const [CategoryCourses, setCatergoryCourse] = useState([]);
  const [selectedMainCategories, setSelectedMainCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);

  useEffect(() => {
    if (selectedMainCategories.length > 0 || selectedSubCategories.length > 0) {
      fetchCourses();
    } else {
      setCatergoryCourse([]);
    }
  }, [selectedMainCategories, selectedSubCategories, searchQuery]);

  const fetchCourses = async () => {
    try {
      const response = await axios.get(
        "https://smartuter.com/api/enduser/multiCourses",
        {
          params: {
            mainCategories: selectedMainCategories.join(","),
            subCategories: selectedSubCategories.join(","),
            search: searchQuery,
          },
        }
      );
      setCatergoryCourse(response.data);
      console.log(response.data, "=======================");
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const handleMainCategoryChange = (category) => {
    setSelectedMainCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
  };

  const handleSubCategoryChange = (category) => {
    setSelectedSubCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
  };

  console.log(courses);

  
  return (
    <div className="course-section-card-container">
      <div className="all-courses-main">
        <div>
          <h3 className="course-section-card-head">ALL COURSES</h3>
          <p className="course-section-card-para">
            Write An Introductory Description of the Category.
          </p>

          <div className="header-navitems">
            <Link to={"/"}>
              <span className="header-links">Home</span>
            </Link>
            <span className="mx-1">{">"}</span>
            <Link to={"/coursepage"}>
              <span className="header-links underline-headerlink">Courses</span>
            </Link>
          </div>
        </div>
        <img src={coursesimg} alt="No Course Found" />
      </div>

      <div className="course-section-all-card">
        <div className="course-section-category-container">
          <div
            className={`${isOpen ? "open" : ""} course-section-each-category`}
            onClick={toggleCoursesAccordion}
          >
            <div className="course-section-each-category-title">
              Course Category
            </div>
            <div className="course-section-each-category-arrow">
              {isOpen ? (
                <RiArrowDropUpLine className="up-arrow" />
              ) : (
                <RiArrowDropDownLine className="down-arrow" />
              )}
            </div>
          </div>
          {isOpen && (
            <div className="course-section-category-container-each">
              {allMainCategory &&
                allMainCategory
                  .slice(
                    0,
                    showAllCategories === "category"
                      ? allMainCategory.length
                      : 3
                  )
                  .map((cat, i) => (
                    <label
                      key={i}
                      className="course-section-category-checkbox-container"
                    >
                      <input
                        className="course-section-category-checkbox"
                        type="checkbox"
                        value={cat.category}
                        onChange={() => handleMainCategoryChange(cat.category)}
                      />
                      <p className="course-section-category-checkbox-para">
                        {cat.category}
                      </p>
                    </label>
                  ))}
              {(allMainCategory && allMainCategory.length) > 3 && (
                <button
                  style={{ color: "blue" }}
                  onClick={() => toggleShowAllCategories("category")}
                  className="study-material-show-more"
                >
                  {showAllCategories === "category" ? "Show Less" : "Show More"}
                </button>
              )}
            </div>
          )}
          <hr />

          <div
            className={`Courses-accordion-header ${
              secOpen ? "open" : ""
            } course-section-each-category`}
            onClick={toggleCoursesAccordionSecound}
          >
            <div className="course-section-each-category-title">
              SubCategory
            </div>
            <div className="course-section-each-category-arrow">
              {secOpen ? (
                <RiArrowDropUpLine className="up-arrow" />
              ) : (
                <RiArrowDropDownLine className="down-arrow" />
              )}
            </div>
          </div>
          {secOpen && (
            <div className="course-section-category-container-each">
              {allSubCategory &&
                allSubCategory
                  .slice(
                    0,
                    showAllSubCategories === "category"
                      ? allSubCategory.length
                      : 3
                  )
                  .map((cat, i) => (
                    <label
                      key={i}
                      className="course-section-category-checkbox-container"
                    >
                      <input
                        type="checkbox"
                        value={cat.category}
                        onChange={() => handleSubCategoryChange(cat.category)}
                      />
                      <p className="course-section-category-checkbox-para">
                        {cat.category}
                      </p>
                    </label>
                  ))}
              {(allSubCategory && allSubCategory.length) > 3 && (
                <button
                  style={{ color: "blue" }}
                  onClick={() => toggleShowAllSubCategories("category")}
                  className="study-material-show-more"
                >
                  {showAllSubCategories === "category"
                    ? "Show Less"
                    : "Show More"}
                </button>
              )}
            </div>
          )}
          <hr />
        </div>
        <div className="course-section-display-container">
          <div className="course-section-display-filter">
            <p className="course-section-filter-para pb-0">
              Showing {courses && courses.length} total results
            </p>
            <div className="course-section-search">
              <div className="input-container">
                <CiSearch className="search-icon" />
                <input
                  type="search"
                  value={searchCourse}
                  onChange={(e) => setSearchCourse(e.target.value)}
                  placeholder="Search Courses..."
                  className="courses-search"
                />
              </div>
            </div>
          </div>

          {/* 
          <div className="course-section-display-cards">
            {(CategoryCourses && CategoryCourses.length > 0 ? CategoryCourses: courses)? courses
              : []
            .map((course) => (
              <div
                className="course-section-display-each-card"
                key={course._id}
              >
                <div className="course-section-display-card">
                  <Link
                    to={`/course/${course._id}`}
                    onClick={() => setSingleCourseId(course._id)}
                  >
                    <img
                      src={course.imageUrl}
                      className=""
                      alt={course.postName}
                    />
                  </Link>
                  <div className="course-section-display-card-body">
                    <h1 className="course-section-display-card-body-title">
                      <Link
                        to={`/course/${course._id}`}
                        onClick={() => setSingleCourseId(course._id)}
                      >
                        {truncateText(course.postcourseName, 3)}
                      </Link>
                    </h1>

                    <div className="course-section-display-card-body">
                      <div className="course-section-display-card-ul-list-item">
                        <Link
                          to={`/course/${course._id}`}
                          onClick={() => setSingleCourseId(course._id)}
                          className="CourseTitle"
                        >
                          {course.postName.substring(0, 30) + "..."}
                        </Link>
                        
                      </div>

                      <ul className="course-section-display-card-body-ul-list-item">
                        {course.tags.slice(0, 3).map((tag) => (
                          <span key={tag._id} className="post-tag">
                            {tag.text}
                          </span>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="CourseAddtoCart">
                  {user &&
                    user.cart &&
                    user.orders &&
                    (user.cart.some(
                      (cartItem) => cartItem.ID === course._id
                    ) ? (
                      <Link to={"/cart"}>
                        <button className="disableBTN">Already In Cart</button>
                      </Link>
                    ) : user.orders.some(
                        (orderItem) => orderItem.ID === course._id
                      ) ? (
                      <Link to={"/order"}>
                        <button className="disableBTN">
                          Please Go to Orders
                        </button>
                      </Link>
                    ) : (
                      <button
                        className="enableBTN"
                        onClick={() => handleAddToCart(course)}
                      >
                        Add To Cart
                      </button>
                    ))}
                  {!user && (
                    <button
                      className="enableBTN"
                      onClick={() => (
                        navigate("/login"), handleAddToCart(course)
                      )}
                    >
                      Add To Cart
                    </button>
                  )}
                </div>

                <div className="CoursePrice">
                  <p>
                    Price: <span className="card-Price">₹{course.price}</span>
                  </p>
                </div>
              </div>
            ))
          :
          <p>No Data Found</p>
        }
          </div> */}

          <div className="course-section-display-cards">
            {CategoryCourses && CategoryCourses.length > 0 ? (
              CategoryCourses.map((course) => (
                <div
                  className="course-section-display-each-card"
                  key={course._id}
                >
                  <div className="course-section-display-card">
                    <Link
                      to={`/course/${course._id}`}
                      onClick={() => setSingleCourseId(course._id)}
                    >
                      <img
                        src={course.imageUrl}
                        className=""
                        alt={course.postName}
                      />
                    </Link>
                    <div className="course-section-display-card-body">
                      <h1 className="course-section-display-card-body-title">
                        <Link
                          to={`/course/${course._id}`}
                          onClick={() => setSingleCourseId(course._id)}
                        >
                          {truncateText(course.postcourseName, 3)}
                        </Link>
                      </h1>
                      <div className="course-section-display-card-body">
                        <div className="course-section-display-card-ul-list-item">
                          <Link
                            to={`/course/${course._id}`}
                            onClick={() => setSingleCourseId(course._id)}
                            className="CourseTitle"
                          >
                            {course.postName.substring(0, 30) + "..."}
                          </Link>
                        </div>
                        <ul className="course-section-display-card-body-ul-list-item">
                          {course.tags.slice(0, 3).map((tag) => (
                            <span key={tag._id} className="post-tag">
                              {tag.text}
                            </span>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="CourseAddtoCart">
                    {user &&
                      user.cart &&
                      user.orders &&
                      (user.cart.some(
                        (cartItem) => cartItem.ID === course._id
                      ) ? (
                        <Link to={"/cart"}>
                          <button className="disableBTN">
                            Already In Cart
                          </button>
                        </Link>
                      ) : user.orders.some(
                          (orderItem) => orderItem.ID === course._id
                        ) ? (
                        <Link to={"/order"}>
                          <button className="disableBTN">
                            Please Go to Orders
                          </button>
                        </Link>
                      ) : (
                        <button
                          className="enableBTN"
                          onClick={() => handleAddToCart(course)}
                        >
                          Add To Cart
                        </button>
                      ))}
                    {!user && (
                      <button
                        className="enableBTN"
                        onClick={() => (
                          navigate("/login"), handleAddToCart(course)
                        )}
                      >
                        Add To Cart
                      </button>
                    )}
                  </div>
                  <div className="CoursePrice">
                    <p>
                      Price: <span className="card-Price">₹{course.price}</span>
                    </p>
                  </div>
                </div>
              ))
            ) : courses && courses.length > 0 ? (
              courses.map((course) => (
                <div
                  className="course-section-display-each-card"
                  key={course._id}
                >
                  <div className="course-section-display-card">
                      <Link
                        to={`/course/${course._id}`}
                        onClick={() => setSingleCourseId(course._id)}
                      >
                        <img
                          src={course.imageUrl}
                          className=""
                          alt={course.postName}
                        />
                      </Link>
                   

                    <div className="course-section-display-card-body">
                      <h1 className="course-section-display-card-body-title">
                        <Link
                          to={`/course/${course._id}`}
                          onClick={() => setSingleCourseId(course._id)}
                        >
                          {truncateText(course.postcourseName, 3)}
                        </Link>
                      </h1>
                      <div className="course-section-display-card-body">
                        <div className="course-section-display-card-ul-list-item">
                          <Link
                            to={`/course/${course._id}`}
                            onClick={() => setSingleCourseId(course._id)}
                            className="CourseTitle"
                          >
                            {course.postName.substring(0, 30) + "..."}
                          </Link>
                        </div>
                        <ul className="course-section-display-card-body-ul-list-item">
                          {course.tags.slice(0, 3).map((tag) => (
                            <span key={tag._id} className="post-tag">
                              {tag.text}
                            </span>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="CourseAddtoCart">
                    {user &&
                      user.cart &&
                      user.orders &&
                      (user.cart.some(
                        (cartItem) => cartItem.ID === course._id
                      ) ? (
                        <Link to={"/cart"}>
                          <button className="disableBTN">
                            Already In Cart
                          </button>
                        </Link>
                      ) : user.orders.some(
                          (orderItem) => orderItem.ID === course._id
                        ) ? (
                        <Link to={"/order"}>
                          <button className="disableBTN">
                            Please Go to Orders
                          </button>
                        </Link>
                      ) : (
                        <button
                          className="enableBTN"
                          onClick={() => handleAddToCart(course)}
                        >
                          Add To Cart
                        </button>
                      ))}
                    {!user && (
                      <button
                        className="enableBTN"
                        onClick={() => (
                          navigate("/login"), handleAddToCart(course)
                        )}
                      >
                        Add To Cart
                      </button>
                    )}
                  </div>
                  <div className="CoursePrice">
                    <p>
                      Price: <span className="card-Price">₹{course.price}</span>
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-courses-awailabale">
                <p>No Data Available</p>
                <img src={NoData} alt="No Data"/>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursePage;

// If i hover on  course card which is in course-cards-container then hover card should display   as cards
