import React from "react";
import { RiSearchLine } from "react-icons/ri";
import "./search.css";
import { useUserAuth } from "../../Context/UseAuthContext";
import { NavLink } from "react-router-dom";

const Search = ({setShowSearch}) => {
  const {
    searchText,
    setSearchText,
    searchData,
    fetchDataSearchInput,
    setSingleCourseId,
    setSingleProjectId
  } = useUserAuth();

    function debounce(func, timeout = 300){
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
      };
    }
    const processChange = debounce(() => fetchDataSearchInput());
  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
    processChange();
  };

  // Interleave courses and projects
  const interleaveResults = (courses, projects) => {
    let interleaved = [];
    let maxLength = Math.max(courses.length, projects.length);
    for (let i = 0; i < maxLength; i++) {
      if (i < courses.length) interleaved.push({ ...courses[i], type: "course" });
      if (i < projects.length) interleaved.push({ ...projects[i], type: "project" });
    }
    return interleaved;
  };

  const interleavedResults = searchData
    ? interleaveResults(searchData.courses, searchData.projects)
    : [];

  return (
    <div className="search-container">
      <div className="search-input-card">
        <div className="search-bar-card">
          <button className="search-button">
            <RiSearchLine />
          </button>
          <input
            placeholder="What do you want to learn?"
            type="text"
            value={searchText}
            onChange={handleSearchInputChange}
            required
          />
          <p className="close-icon">X</p>
        </div>
      </div>
      <div className="search-result-card">
        <ul className="result-list">
          <p className="popular-text">Popular Right Now</p>
          {interleavedResults.map((item, index) => (
            <li key={index}>
              <p>
                {item.type === "course" ? (
                  <NavLink
                    to={`/course/${item._id}`}
                    onClick={() => {setSingleCourseId(item._id);setShowSearch(false)}}
                    className="CourseTitle"
                  >
                    <img src={item.imageUrl} alt={item.postName} />{item.postName}
                  </NavLink>
                ) : (
                  <NavLink
                    to={`/project/${item._id}`}
                    onClick={() => {setSingleProjectId(item._id);setShowSearch(false)}}
                    className="CourseTitle"
                  >
                    <img src={item.OutputScreens} alt={item.ProjectTitle} />{item.ProjectTitle}
                  </NavLink>
                )}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Search;
