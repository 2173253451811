import React from 'react';
import "./Policies.css"

const PrivacyPolicy = () => {
  return (
    <div
      
      className="private-policy-container"
    >
      <h2>Privacy Policy</h2>
      <p>
        At smartuter.com, we respect your privacy and are committed to
        protecting your personal information. This Privacy Policy outlines how
        we collect, use, and safeguard your information.
      </p>

      <h4>1. Information We Collect</h4>
      <ul>
        <li>
          We collect information that you provide directly to us, such as when
          you create an account, make a purchase, or contact us for support.
        </li>
        <li>
          This information may include your name, email address, billing
          address, and payment information.
        </li>
        <li>
          We also collect information automatically as you navigate our site,
          including your IP address, browser type, and usage data.
        </li>
      </ul>

      <h4>2. How We Use Your Information</h4>
      <ul>
        <li>
          To process and fulfill your orders and provide customer support.
        </li>
        <li>To improve our website, products, and services.</li>
        <li>
          To communicate with you about promotions, updates, and other news
          related to smartuter.com.
        </li>
        <li>
          To comply with legal obligations and enforce our terms and conditions.
        </li>
      </ul>

      <h4>3. Sharing Your Information</h4>
      <ul>
        <li>
          We do not sell, trade, or otherwise transfer your personal information
          to outside parties, except as necessary to fulfill your orders or
          comply with the law.
        </li>
        <li>
          We may share your information with trusted third parties who assist us
          in operating our website and conducting our business, as long as those
          parties agree to keep this information confidential.
        </li>
      </ul>

      <h4>4. Security of Your Information</h4>
      <ul>
        <li>
          We implement a variety of security measures to maintain the safety of
          your personal information.
        </li>
        <li>
          However, no method of transmission over the internet or electronic
          storage is 100% secure, and we cannot guarantee absolute security.
        </li>
      </ul>

      <h4>5. Your Rights</h4>
      <ul>
        <li>
          You have the right to access, correct, or delete your personal
          information. To exercise these rights, please contact us at{" "}
          <a href="mailto:smartuter@gmail.com">smartuter@gmail.com</a>.
        </li>
        <li>
          You may also unsubscribe from our marketing communications at any time
          by following the instructions included in each communication.
        </li>
      </ul>

      <h4>6. Changes to This Privacy Policy</h4>
      <ul>
        <li>
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page, and we encourage you to review this policy
          periodically.
        </li>
      </ul>

      <h4>7. Contact Us</h4>
      <ul>
        <li>
          If you have any questions or concerns about our Privacy Policy, please
          contact us at{" "}
          <a href="mailto:smartuter@gmail.com">smartuter@gmail.com</a>.
        </li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
