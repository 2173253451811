import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useUserAuth } from "../../Context/UseAuthContext";
import { FaEye, FaEyeSlash } from "react-icons/fa6";

const ForgotPassword = () => {
  const { id, token } = useParams();
  const { navigate } = useUserAuth();
  const [pass, setPassword] = useState("");

  const [confirmPass, setconfirmPass] = useState("");
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const userValid = async () => {
    const res = await fetch(
      `https://smartuter.com/api/enduser/forgotpassword/${id}/${token}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    if (data.status === 201) {
    } else {
      // navigate("/error");
    }
  };

  const setVal = (e) => {
    setPassword(e.target.value);
  };

  const sendPassword = async (e) => {
    e.preventDefault();
    if (!validatePassword(pass)) {
      return toast.error("Password must be at least 6 characters");
    }
    if (pass !== confirmPass) {
      return toast.error("Passwords do not match");
    }

    const res = await fetch(
      `https://smartuter.com/api/enduser/${id}/${token}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pass }),
      }
    );

    const data = await res.json();
    if (data.status === 201) {
      setPassword("");
      toast.success("Password Update Successfully");
      navigate("/login");
    } else {
      navigate("/login");

      toast.error("! Token Expired generate new link");
    }
  };
  useEffect(() => {
    userValid();
  }, []);
  return (
    <div className="main_login">
      <div className="login-container resetpassword">
        <h1>Forgot Password</h1>
        <form onSubmit={sendPassword} className="input-group reset-input-group">
          <label>Password:</label>

          <input
            type="password"
            name="pass"
            placeholder="Enter Your new password"
            value={pass}
            onChange={setVal}
          />

          <input
            type={showConfirmPass ? "text" : "password"}
            placeholder="Confirm password"
            value={confirmPass}
            onChange={(e) => setconfirmPass(e.target.value)}
            required
          />
          <span
            className="register-eyebtn"
            onClick={() => setShowConfirmPass(!showConfirmPass)}
          >
            {!showConfirmPass ? (
              <FaEyeSlash color="#A7A9AA" />
            ) : (
              <FaEye color="#A7A9AA" />
            )}
          </span>

          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
