// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .image-container {
    flex: 1 1;
    display: flex;
    justify-content: center;
  }
  
  .course-image {
    max-width: 100px;
    height: auto;
  }
  
  .text-container {
    flex: 2 1;
    text-align: center;
    padding: 0 20px;
  }
  
  .text-container h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .text-container p {
    font-size: 16px;
    color: #666;
  }
  `, "",{"version":3,"sources":["webpack://./src/Pages/Courses/SingleCourseBanner.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,wCAAwC;EAC1C;;EAEA;IACE,SAAO;IACP,aAAa;IACb,uBAAuB;EACzB;;EAEA;IACE,gBAAgB;IAChB,YAAY;EACd;;EAEA;IACE,SAAO;IACP,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,WAAW;EACb","sourcesContent":[".course-container {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 20px;\n    border: 1px solid #ddd;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  }\n  \n  .image-container {\n    flex: 1;\n    display: flex;\n    justify-content: center;\n  }\n  \n  .course-image {\n    max-width: 100px;\n    height: auto;\n  }\n  \n  .text-container {\n    flex: 2;\n    text-align: center;\n    padding: 0 20px;\n  }\n  \n  .text-container h1 {\n    font-size: 24px;\n    margin-bottom: 10px;\n  }\n  \n  .text-container p {\n    font-size: 16px;\n    color: #666;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
