import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import "./register.css";
import { useUserAuth } from "../../Context/UseAuthContext";
import toast from "react-hot-toast";
import { Loading } from "../../Component/Loading/Loading";

const Register = () => {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [confirmPass, setconfirmPass] = useState("");
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const [errors, setErrors] = useState({});

  const [emailValid, setEmailValid] = useState(true);

  const [otp, setOtp] = useState("");
  const [emailVerified, setEmailVerified] = useState(false);
  const [showEmailOptInput, setShowEmailOtpInput] = useState(false);

  const commonEmailDomains = [
    "gmail.com",
    "yahoo.com",
    "outlook.com",
    "hotmail.com",
    "icloud.com",
    "aol.com",
  ];
  const [loading, setLoading] = useState(false);
  const { navigate } = useUserAuth();

  //added
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateEmailDomain = (email) => {
    const domain = email.split("@")[1];
    return commonEmailDomains.includes(domain);
  };

  const validateNumber = (number) => {
    const regex = /^\d{10}$/;
    return regex.test(number);
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  useEffect(() => {
    const validateAndCheckEmail = async () => {
      if (email && validateEmail(email)) {
        const validDomain = validateEmailDomain(email);
        setEmailValid(validDomain);
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: !validDomain ? "Email domain is not valid" : "",
        }));
      }
    };
    validateAndCheckEmail();
  }, [email]);

  //ended

  const handleSubmit = (e) => {
    e.preventDefault();
    let formErrors = {};

    if (!name) {
      formErrors.name = "Username is required";
    }
    if (!validateEmail(email)) {
      formErrors.email = "Invalid email format";
    } else if (!emailValid) {
      formErrors.email = "Email domain is not valid";
    }
    if (!validateNumber(number)) {
      formErrors.number = "Number must be 10 digits";
    }
    if (!validatePassword(pass)) {
      formErrors.pass = "Password must be at least 6 characters";
    }
    if (pass !== confirmPass) {
      formErrors.confirmPass = "Passwords do not match";
    }
    if (!emailVerified) {
      formErrors.emailOtp = "Email is Not Verified Please click on Verify";
    }

    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      const payload = {
        email,
        pass,
        name,
        number,
      };

      fetch("https://smartuter.com/api/enduser/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.msg === true) {
            toast.success(`${email} Registration successful`);
            setEmail("");
            setPass("");
            setName("");
            setNumber("");
            navigate("/login");
          } else {
            toast.error(`${res.msg}`);
          }
        })
        .catch((err) => console.log(err));
      // } else {
      //   toast.error("Incorrect Password");
      // }
    }
  };

  const emailOtpGeneration = async (email) => {
    if (!email) {
      return toast.error("Please Enter Your Email", { duration: 6000 });
    }
    if (!validateEmail(email)) {
      return toast.error("Please Enter Valid Email", { duration: 6000 });
    }
    if (!validateEmailDomain(email)) {
      return toast.error("Please Enter Valid Domain Email", { duration: 6000 });
    }

    if (!emailVerified) {
      try {
        setLoading(true);

        const url = "https://smartuter.com/api/enduser/email/otp/generation";
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        };
        const response = await fetch(url, options);
        const result = await response.json();

        if (result.status) {
          setLoading(false);

          toast.success(`We Have Sent an OTP your ${email} Id`, {
            duration: 7000,
          });
          setShowEmailOtpInput(true);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      toast.success("Email already Verified", {
        duration: 6000,
      });
    }
  };

  const emailOtpVerfication = async ({ email, otp }) => {
    try {
      const url = "https://smartuter.com/api/enduser/email/otp/verification";
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, otp }),
      };
      const response = await fetch(url, options);
      const result = await response.json();
      // console.log(result);
      if (result.status) {
        setShowEmailOtpInput(false);
        setEmailVerified(true);
      } else {
        toast.error(
          "Invalid Otp, please Enter Valid OTP OR Check Your Email ID",
          {
            duration: 6000,
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="signup-container">
      <div className="signup-register-container">
        <div className="signup-register-sub-container">
          <h2>REGISTER</h2>
          <p>
            Already have an account?{" "}
            <NavLink className={"navlink"} to={"/login"}>
              {" "}
              Login
            </NavLink>
          </p>
        </div>

        <form className="register-form" onSubmit={handleSubmit}>
          <div className="input-users-row">
            <label>
              {/* Username*  */}
              <br />
              <input
                className="register-username no-transform"
                type="text"
                placeholder="Enter Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              {errors.name && (
                <span className="error-message">*{errors.name}</span>
              )}
            </label>

            <label>
              {/* Email address* */}
               <br />
              <input
                type="email"
                className="register-username no-transform"
                placeholder="Enter Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
                required
              /> 
              <span
                className="register-eyebtn"
                onClick={() => emailOtpGeneration(email)}
              >
                {emailVerified ? (
                  <span style={{ color: "green", backgroundColor: "white" }}>
                    verified
                  </span>
                ) : (
                  <span style={{ color: "blue", backgroundColor: "white" }}>
                    verify
                  </span>
                )}
              </span>
              {errors.email && (
                <span className="error-message">*{errors.email}</span>
              )}
              {errors.emailOtp && emailVerified === false && (
                <span className="error-message">*{errors.emailOtp}</span>
              )}
            </label>
            {showEmailOptInput ? (
              <label>
                {/* Enter Your Otp Here */}
                 <br />
                <input
                  type="text"
                  className="register-username"
                  placeholder="Enter Your Otp"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                />
                <span
                  className="register-eyebtn"
                  onClick={() => emailOtpVerfication({ email, otp })}
                >
                  {emailVerified ? <span>verified</span> : <span>verify</span>}
                  <span>Submit</span>
                </span>
                {errors.email && (
                  <span className="error-message">*{errors.email}</span>
                )}
              </label>
            ) : null}

            <label>
              {/* Number*  */}
              <br />
              <input
                type="number"
                className="register-username no-transform"
                placeholder="Enter Your Number"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                required
              />
              {errors.number && (
                <span className="error-message">*{errors.number}</span>
              )}
            </label>

            <label>
              {/* Password*  */}
              <br />
              <input
                type={showPass ? "text" : "password"}
                className="register-username no-transform"
                placeholder="Enter Your password"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                required
              />
              <span
                className="register-eyebtn"
                onClick={() => setShowPass(!showPass)}
              >
                {!showPass ? (
                  <FaEyeSlash color="#A7A9AA" />
                ) : (
                  <FaEye color="#A7A9AA" />
                )}
              </span>
              {errors.pass && (
                <span className="error-message">*{errors.pass}</span>
              )}
            </label>

            <label>
              {/* ConfirmPassword*  */}
              <br />
              <input
                type={showConfirmPass ? "text" : "password"}
                className="register-username no-transform"
                placeholder="Confirm password"
                value={confirmPass}
                onChange={(e) => setconfirmPass(e.target.value)}
                required
              />
              <span
                className="register-eyebtn"
                onClick={() => setShowConfirmPass(!showConfirmPass)}
              >
                {!showConfirmPass ? (
                  <FaEyeSlash color="#A7A9AA" />
                ) : (
                  <FaEye color="#A7A9AA" />
                )}
              </span>
              {errors.confirmPass && (
                <span className="error-message">*{errors.confirmPass}</span>
              )}
            </label>
          </div>
          <div className="login-form-btn">
            <button type="submit">Register</button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Register;
