import React, { useState } from "react";
import "./smallNavbar.css";
import logo from "../../images/logo.png"
import { useUserAuth } from "../../Context/UseAuthContext";
import { Link, NavLink } from "react-router-dom";
import { encodeCategoryForURL } from "../EncodedUrlComponent";

import { CgProfile } from "react-icons/cg";
import Search from "../../Pages/Search/Search";
import { IoIosArrowDropdownCircle } from "react-icons/io";

function SmallNavbar() {
  const {
    user,
    allMainCategory,
    setCategoryFilter,
    setCategoryFiltersproject,
    setCategoryFilterstudymaterial,
  } = useUserAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const toggleOffcanvasNew = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`offcanvas-navbar-1${isOpen ? "-open" : ""}`}>
      <div className="offcanva-headerbar-1">
        <NavLink to={"/"}>
          <img src={logo} alt="Logo" className="logo" />
        </NavLink>
        <div>
          {/* <NavLink > */}
          <span className="icon " onClick={() => setShowSearch(!showSearch)}>
            <i
              className="fa-solid fa-magnifying-glass fa-xl navabr-icons"
              // style={{ marginRight:"1%" }}
            ></i>
          </span>

          {/* </NavLink> */}
          {user && (
            <NavLink to={"/profile"}>
              {" "}
              <button className="cart-btn nav-cart-icon ">
                <CgProfile className="react_icon_smartuter fa-xl navabr-icons" />{" "}
              </button>
            </NavLink>
          )}
          {user && (
            <NavLink to={"/cart"}>
              <button className="cart-btn-1 nav-cart-icon">
                <i className="fa-solid fa-cart-arrow-down   navabr-icons"></i>
                <span className="cart-badge">{user && user.cart.length}</span>
              </button>{" "}
            </NavLink>
          )}
          <button
            className="toggleOffcanvas-btn-1"
            onClick={toggleOffcanvasNew}
          >
            <span className="nav-menu-icon">
              <i className="fa-solid fa-bars fa-2xl px-2 "></i>
            </span>
          </button>
        </div>
      </div>

      <div className={`offcanvas-1 ${isOpen ? "open" : ""}`}>
        <div className="offcanvas-content-1">
          <div className="smallscreen-head-1">
            {user == null && (
              <Link
                className="small-login"
                onClick={toggleOffcanvasNew}
                to="/login"
              >
                Login
              </Link>
            )}
            {user == null && (
              <Link
                className="small-signup"
                onClick={toggleOffcanvasNew}
                to="/register"
              >
                Sign Up
              </Link>
            )}
          </div>
          <button
            className="button-close-1"
            onClick={toggleOffcanvasNew}
            aria-label="Close"
          >
            <i
              className="fa-solid fa-xmark fa-xl"
              style={{ color: "black" }}
            ></i>
          </button>
          <div className="offcanvas-body-1">
            <div className="small-navbar-center-1">
              {/* <div className="small-dropdown-1">
                <button  onClick={()=>navigate("/")} className="small-dropbtn-1">
                  HOME{" "}
                  <span className="nav-icons">
                    <i className="fa-solid fa-chevron-right"></i>
                  </span>
                </button>
               
              </div> */}
              <div className="small-dropdown-1">
                <button className="small-dropbtn-1">
                  
                  Courses{" "}
                  <span className="small-nav-icons">
                  <IoIosArrowDropdownCircle className="fs-2" />

                  {/* <i className="fa-regular fa-arrow-down-short-wide fa-xl"></i>      */}
                               </span>
                </button>
                <div className="small-dropdown-content-1">
                  <Link
                    to={"/coursepage"}
                    onClick={() => {
                      setCategoryFilter("");
                      toggleOffcanvasNew();
                    }}
                  >
                    ALL COURSES
                  </Link>
                  {allMainCategory &&
                    allMainCategory.map((e, i) => (
                      <Link
                        key={i}
                        to={`/coursecatgory/${encodeCategoryForURL(
                          e.category
                        )}`}
                        onClick={() => {
                          setCategoryFilter(encodeCategoryForURL(e.category));
                          toggleOffcanvasNew()
                        }}
                      >
                        {e.category}
                      </Link>
                      // <a key={i} href={e._id}>{e.category}</a>
                    ))}
                </div>
              </div>
              <div className="small-dropdown-1">
                <button className="small-dropbtn-1">
                  Projects{" "}
                  <span className="small-nav-icons">
                  <IoIosArrowDropdownCircle className="fs-2" />

                    {/* <i className="fa-solid fa-chevron-right"></i> */}
                  </span>
                </button>
                <div className="small-dropdown-content-1">
                  <Link
                    to={"/project"}
                    onClick={() => {
                      setCategoryFiltersproject("");
                      toggleOffcanvasNew();
                    }}
                  >
                    ALL PROJECTS
                  </Link>
                  {allMainCategory &&
                    allMainCategory.map((e, i) => (
                      <Link
                        key={i}
                        to={`/projectcatgory/${encodeCategoryForURL(
                          e.category
                        )}`}
                        onClick={() => {
                          setCategoryFiltersproject(
                            encodeCategoryForURL(e.category)
                          );
                          toggleOffcanvasNew();
                        }}
                      >
                        {e.category}
                      </Link>
                      // <a key={i} href={e._id}>{e.category}</a>
                    ))}
                </div>
              </div>
              <div className="small-dropdown-1">
                <button className="small-dropbtn-1">
                  {/* STUDYMATERIALS */}
                  Studymaterials{" "}
                  <span className="small-nav-icons">
                  <IoIosArrowDropdownCircle className="fs-2" />

                    {/* <i className="fa-solid fa-chevron-right"></i> */}
                  </span>
                </button>
                <div className="small-dropdown-content-1">
                  <Link
                    to={"/studymaterial"}
                    onClick={() => {
                      setCategoryFilterstudymaterial("");
                      toggleOffcanvasNew();
                    }}
                  >
                    ALL STUDYMATERIALS
                  </Link>
                  {allMainCategory &&
                    allMainCategory.map((e, i) => (
                      <Link
                        key={i}
                        to={`/studymaterialcatgory/${encodeCategoryForURL(
                          e.category
                        )}`}
                        onClick={() => {
                          setCategoryFilterstudymaterial(
                            encodeCategoryForURL(e.category)
                          );
                          toggleOffcanvasNew();
                        }}
                      >
                        {e.category}
                      </Link>
                      // <a key={i} href={e._id}>{e.category}</a>
                    ))}
                </div>
              </div>


             
                
              {user && (
                 <div className="small-dropdown-1 p-2">
                  <Link to="/cart" onClick={() => { toggleOffcanvasNew(); }}>
                    CART
                    </Link>
                    </div>
                  )}
         

              
                
              {user && (
                <div className="small-dropdown-1 p-2">
                    <Link to={"/order"} onClick={() => { toggleOffcanvasNew(); }}>
                      ORDERS
                    </Link>
                    </div>
                  )}
          

              <div className="small-dropdown-1 p-2">
                
              <Link to={"/about"} onClick={() => { toggleOffcanvasNew(); }}>
                    ABOUT
                  </Link>
              </div>

              <div className="small-dropdown-1 p-2">
                
              <Link to={"/contact"} onClick={() => { toggleOffcanvasNew(); }}>
                    CONTACT
                  </Link>
              </div>

              {/* <div className="small-dropdown-1 p-2">
                
              <Link to={"/login"} onClick={() => { toggleOffcanvasNew(); }}>
                    LOGIN
                  </Link>
              </div> */}
            
              {user && (
                  <div className="small-dropdown-1 p-2">
                    <Link to={"/payments"} onClick={() => { toggleOffcanvasNew(); }}>
                      Payments
                    </Link>
                    </div>
                  )}
      


{/* 
              <div className="small-dropdown-1">
                <button className="small-dropbtn-1">
                  PAGES{" "}
                  <span className="small-nav-icons">
                    <i className="fa-solid fa-chevron-right"></i>
                  </span>
                </button>
                <div className="small-dropdown-content-1">
                  <Link to={"/allvendor"} onClick={toggleOffcanvasNew}>
                    INSTRUCTORS
                  </Link>

              
               
                
                
                  <Link to={"/register"} onClick={toggleOffcanvasNew}>
                    REGISTER
                  </Link>
                  {user && (
                    <Link to={"/profile"} onClick={toggleOffcanvasNew}>
                      PROFILE
                    </Link>
                  )}
               
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div
          className={`offcanvas-overlay-1 ${isOpen ? "open" : ""}`}
          onClick={toggleOffcanvasNew}
        ></div>
      </div>
      {showSearch && <Search />}
    </div>
  );
}

export default SmallNavbar;
