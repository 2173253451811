import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

const UpiDetailsDialog = ({ open, onClose, onSave }) => {
  const [formValues, setFormValues] = useState({
    upiid: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSave = () => {
    onSave(formValues);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Enter UPI ID </DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          name="upiid"
          label="UPI ID"
          type="text"
          fullWidth
          value={formValues.upiid}
          onChange={handleChange}
        />
       
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpiDetailsDialog;
