import React from 'react';

const VideoPlayer = ({ src, type, width, height, controls, autoplay, muted, loop, poster }) => {
  return (
    <video
      width={width}
      height={height}
      controls={controls}
      autoPlay={autoplay}
      muted={muted}
      loop={loop}
      poster={poster}
    >
      <source src={src} type={type} />
      Your browser does not support the video tag.
    </video>
  );
};

export default VideoPlayer;
