// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.refer-button{
    height:auto;
    background-color: green;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 5px;
    padding-bottom: 10px;
    margin: 10px;
}




/* HTML: <div className="loader"></div> */
.loader-main-div{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 50vh;
    color: #06517a;
}
.loader {
    width: 15px;
    aspect-ratio: 1;
    position: relative;
  }
  .loader::before,
  .loader::after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50%;
    background: #06517a;
  }
  .loader::before {
    box-shadow: -25px 0;
    animation: l8-1 1s infinite linear;
  }
  .loader::after {
    transform: rotate(0deg) translateX(25px);
    animation: l8-2 1s infinite linear;
  }
  
  @keyframes l8-1 {
      100%{transform: translateX(25px)}
  }
  @keyframes l8-2 {
      100%{transform: rotate(-180deg) translateX(25px)}
  }`, "",{"version":3,"sources":["webpack://./src/Component/Tree/tree.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,uBAAuB;IACvB,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,YAAY;IACZ,oBAAoB;IACpB,YAAY;AAChB;;;;;AAKA,yCAAyC;AACzC;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,cAAc;AAClB;AACA;IACI,WAAW;IACX,eAAe;IACf,kBAAkB;EACpB;EACA;;IAEE,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,kBAAkB;IAClB,mBAAmB;EACrB;EACA;IACE,mBAAmB;IACnB,kCAAkC;EACpC;EACA;IACE,wCAAwC;IACxC,kCAAkC;EACpC;;EAEA;MACI,KAAK,2BAA2B;EACpC;EACA;MACI,KAAK,2CAA2C;EACpD","sourcesContent":[".refer-button{\n    height:auto;\n    background-color: green;\n    color: white;\n    border: none;\n    border-radius: 10px;\n    padding: 5px;\n    padding-bottom: 10px;\n    margin: 10px;\n}\n\n\n\n\n/* HTML: <div className=\"loader\"></div> */\n.loader-main-div{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100vw;\n    height: 50vh;\n    color: #06517a;\n}\n.loader {\n    width: 15px;\n    aspect-ratio: 1;\n    position: relative;\n  }\n  .loader::before,\n  .loader::after {\n    content: \"\";\n    position: absolute;\n    inset: 0;\n    border-radius: 50%;\n    background: #06517a;\n  }\n  .loader::before {\n    box-shadow: -25px 0;\n    animation: l8-1 1s infinite linear;\n  }\n  .loader::after {\n    transform: rotate(0deg) translateX(25px);\n    animation: l8-2 1s infinite linear;\n  }\n  \n  @keyframes l8-1 {\n      100%{transform: translateX(25px)}\n  }\n  @keyframes l8-2 {\n      100%{transform: rotate(-180deg) translateX(25px)}\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
