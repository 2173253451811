import React, { useEffect, useState } from "react";
import axios from "axios";
import coursesimg from '../../images/coursesimg.gif'
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Typography,
  Box,
} from "@mui/material";
import { useUserAuth } from "../../Context/UseAuthContext";
import { Link } from "react-router-dom";

export const Payment = () => {
  const { user } = useUserAuth();
  const [salesData, setSalesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(5);
  const [count, setCount] = useState(0);

  // const [userName, setUserName] = useState('');
  useEffect(() => {
    // Fetch data from the API
    if (user) {
      axios
        .get(
          `https://smartuter.com/api/enduser/userreferralsdata?userid=${user._id}`
        )
        .then((response) => {
          setSalesData(response.data.Referrals);
          // console.log("---response.data.Referrals---------------",response.data.Referrals)
          // setUserName(response.data["User Name"]);
        })
        .catch((error) => {
          console.error("Error fetching the sales data:", error);
        });
    }
  }, [user, currentPage]);

  // Flatten sales data to include each item in totalSaleItems and filter out empty referrals
  const flattenedSalesData = salesData
    .flatMap((referralGroup) => referralGroup.Referral)
    .flatMap((sale) =>
      sale.totalSaleItems.map((item) => ({
        ...item,
        orderId: sale.orderId,
        paymentId: sale.paymentId,
        totalSaleAmount: sale.totalSaleAmount,
        saleDate: sale.saleDate,
      }))
    )
    .filter((item) => item.referralUser === `${user._id}`);

  // Calculate the number of pages
  const pageCount = Math.ceil(flattenedSalesData.length / rowsPerPage);

  // Get the sales data for the current page
  const salesDataToDisplay = flattenedSalesData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    setCount((newPage - 1) * 5);
  };

  return (
    <>
     <div className="all-courses-main">
        <div>
        <h3 className="course-section-card-head">Payments</h3>
        <p className="course-section-card-para">
        Secure and flexible payment options ensure a smooth transaction every time.

</p>
     
<div className="header-navitems">
<Link to={"/"}><span className="header-links">Home</span></Link>
        <span className="mx-1">{">"}</span>
        <Link to={""}><span className="header-links underline-headerlink">Payments</span></Link>
</div>
        </div>
        <img src={coursesimg} />
      </div>
    
    <Container>
      <Box my={4}>
        <Typography       style={{ color: '#06517a' }}
 variant="h4" component="h1" gutterBottom>
          Sales Data for {user && user.name}
        </Typography>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>S.NO</TableCell>
                <TableCell>Sale Date</TableCell>
                <TableCell>Order ID</TableCell>
                <TableCell>Course name</TableCell>
                <TableCell>Referral Amount</TableCell>
                <TableCell>Transaction Id</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {salesDataToDisplay &&
                salesDataToDisplay.length > 0 &&
                salesDataToDisplay.map((item, index) => (
                  <TableRow key={item._id}>
                    <TableCell> {index + 1 + count}</TableCell>
                    <TableCell>
                      {new Date(item.saleDate).toLocaleString()}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item.orderId}
                    </TableCell>
                    <TableCell>{item.courseName}</TableCell>
                    <TableCell>
                      {item.referralAmount
                        ? Math.round(
                            (item.referralAmount + Number.EPSILON) * 100
                          ) / 100
                        : 0}
                    </TableCell>

                    <TableCell
                      className={
                        item.referralUserPaymentId
                          ? "paymentComplated"
                          : "paymentPending"
                      }
                      align="right"
                    >
                      {item.referralUserPaymentId
                        ? item.referralUserPaymentId
                        : "Pending"}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display="flex" justifyContent="center" my={2}>
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={handleChangePage}
            color="primary"
          />
        </Box>
      </Box>
    </Container>
    </>
  );
};

export default Payment;
