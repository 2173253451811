import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
import Tree from "react-d3-tree";
import "./tree.css";
import { useUserAuth } from "../../Context/UseAuthContext";
import { Link } from "react-router-dom";
import coursesimg  from '../../images/coursesimg.gif'


const ReferralTree = () => {
  const { user, referralNotification, fetchUserReferralNotifications } =
    useUserAuth();
  const { _id, name } = user !== null && user;
  const [treeData, setTreeData] = useState({ name: name, children: [] });
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [highlightedNodes, setHighlightedNodes] = useState([]);

  const fetchTreeData = async (_id, name) => {
    setLoading(true);

    try {
      const response = await fetch(
        "https://smartuter.com/api/enduser/getTree",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: _id }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        const data = result.userTree;
        const formattedData = buildTree(data);
        setTreeData({
          name: name,
          children: formattedData,
        });
      } else {
        console.error("Failed to fetch tree data");
      }
    } catch (error) {
      console.error("Error fetching tree data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (_id && name) {
      fetchTreeData(_id, name);
    }
  }, [_id, name]);

  useEffect(() => {
    fetchUserReferralNotifications();

    if (referralNotification && referralNotification.length > 0) {
      const newHighlightedNodes = new Set(highlightedNodes);

      referralNotification.forEach((referralId) => {
        newHighlightedNodes.add(referralId);
        setTimeout(() => {
          setHighlightedNodes((prev) => prev.filter((id) => id !== referralId));
          handleDeleteReferralIds([referralId]);
        }, 1 * 60 * 1000); // 1 minute in milliseconds
      });

      setHighlightedNodes(Array.from(newHighlightedNodes));
    }
  }, [referralNotification]);

  const handleDeleteReferralIds = async (idsToDelete) => {
    try {
      const response = await fetch(
        "https://smartuter.com/api/notification/deleteuserreferralIds",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ referralIds: idsToDelete }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete referral IDs");
      }

      const data = await response.json();
      // console.log(data.message); // Log success message
    } catch (error) {
      console.error("Error deleting referral IDs:", error.message);
    }
  };

  const buildTree = (users) => {
    let count1 = 0;

    const createLevelNodes = (nodes) => {
      const levelNodes = [];
      for (let i = 0; i < nodes.length; i += 2) {
        const children = nodes.slice(i, i + 2);
        levelNodes.push({
          name: `level${i / 2 + 1}`,
          children: children,
        });
      }
      return levelNodes;
    };
    return createLevelNodes(
      users.map(
        (user) => (
          (count1 += 1),
          setCount(count1),
          {
            name: (user.name.toLowerCase().substring(0,7)+"::"+(user.referralCount?user.referralCount:0)),
            attributes: {
              id: user._id,
              email: user.email,
            },
            children: user.children ? buildTree(user.children) : [], // Recursively build children
          }
        )
      )
    );
  };

  const customNode = ({ nodeDatum }) => {
    const isHighlighted = highlightedNodes.includes(
      nodeDatum && nodeDatum.attributes && nodeDatum.attributes.id
    );

    const getColor = (node) =>
      isHighlighted
        ? "green"
        : node.attributes && node.attributes.id
        ? "darkblue"
        : "gray";
    const nodeColor = getColor(nodeDatum);
    const dummyImageURL =
      "https://cdn.pixabay.com/photo/2015/03/04/22/35/head-659651_960_720.png";

    const isTopNode = nodeDatum.name === treeData.name;

    return (
      <g>
        <circle r="15" fill={nodeColor} />
        <image href={dummyImageURL} x="-15" y="-15" width="30" height="30" />
        <text             style={{ textTransform: "capitalize" }}
 fill="#06517a" fontWeight="normal" strokeWidth="0" x="13" y="20" text-anchor="start">
          {nodeDatum.name}
        </text>
        {isTopNode && (
          <text
            fill="#01384a"
            font-weight="normal"
            strokeWidth=".3"
            x="20"
            y="5"
            text-anchor="start"
            style={{ textTransform: "capitalize" }}
          >
            Referrals: {count}
          </text>
        )}
      </g>
    );
  };

  if (loading) {
    return <div className="loader-main-div"><h3 className="mx-5">Loading...</h3> <div className="loader"></div></div>;
  }

  return (
    <>
      <div className="all-courses-main">
        <div>
        <h3 className="course-section-card-head">Tree</h3>


        <p className="course-section-card-para">
        Your intuitive reference tree, guiding you to what you need.</p>







     
<div className="header-navitems">
<Link to={"/"}><span className="header-links">Home</span></Link>
        <span className="mx-1">{">"}</span>
        <Link to={""}><span className="header-links underline-headerlink">Tree</span></Link>
</div>
        </div>
        <img src={coursesimg} alt="courseImg"/>
      </div>
   
    <div id="treeWrapper" style={{ width: "100%", height: "100vh" }}>
      <button className="refer-button">No of Referrals:{count}</button>
      <Tree
        svgClassName="link__path"
        data={treeData}
        renderCustomNodeElement={customNode}
        orientation="vertical"
        pathFunc="elbow"
        separation={{ siblings: 1, nonSiblings: 1 }}
        translate={{ x: 300, y: 50 }}
        zoomable={true}
        collapsible={true}
      />
    </div>
     </>
  );
};

export default ReferralTree;
