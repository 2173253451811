import React from "react";
import "./learnskills.css";
import Waves from "../waves/Waves";
import { useNavigate } from "react-router-dom";

const LearnSkills = () => {
  const navigate=useNavigate();
  return (
    <div className="LearnSkills-container">
      <div className="LearnSkills-sub-container">
        <div className="Leanskils-heading">
          <h2>
            Learn New Skills Online with Top{" "}
            <span className="Leanskils-heading-text">Smartuter</span>
          </h2>
          <p>
            Build skills with courses, certificates, and degrees online from
            world-className universities and companies.
          </p>
          <div className="learnmore-btn">
            <button className="button-1" onClick={() => navigate("/contact")}>
              Join For free
            </button>
            <button
              className="button-2"
              onClick={() => navigate("/coursepage")}
            >
              Find Courses
            </button>
          </div>
          <div className="learnmore-span">
            {/* <span>
              <i className="fa-solid fa-graduation-cap fa-xl"></i> Learn
              anything online
            </span> */}
            <span>
              <i className="fa-brands fa-youtube fa-xl"></i> Over 12 million
              students
            </span>
            <span>
              <i className="fa-solid fa-chalkboard-user fa-xl"></i> More than
              60,000 courses
            </span>
          </div>
        </div>

        <div className="learnmore-images">
          <div>
            <img
              src="https://educrat-nextjs.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2F1.4a57919a.png&w=384&q=75"
              alt="dummy-img"
            />
            <p className="learnmore-image-span-1">
              <span className="learnmore-image-span-icon ">
                <i className="fa-solid fa-briefcase fa-xl"></i>300+
              </span>
              <br /> Free Courses
            </p>
          </div>
          <div className="learnmore-images-2">
            <div>
              <img
                src="https://educrat-nextjs.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2F2.15b6c1d2.png&w=256&q=75"
                alt="img-text"
              />
              <p className="learnmore-image-span-2">
                <span className="learnmore-image-span-icon ">
                  <i className="fa-solid fa-briefcase fa-xl"></i>Ali Tufan
                </span>
                <br />
                UI/UX Designer
                <span className="alitufan-rating">
                  <i
                    className="fa-solid fa-star"
                    style={{ color: "yellow" }}
                  ></i>
                  <i
                    className="fa-solid fa-star"
                    style={{ color: "yellow" }}
                  ></i>
                  <i
                    className="fa-solid fa-star"
                    style={{ color: "yellow" }}
                  ></i>
                  <i
                    className="fa-solid fa-star"
                    style={{ color: "yellow" }}
                  ></i>
                  <i
                    className="fa-solid fa-star-half-stroke"
                    style={{ color: "yellow" }}
                  ></i>
                </span>
              </p>
            </div>
            <div className="learnmore-images-3">
              <img
                src="https://educrat-nextjs.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2F3.1930f7c8.png&w=256&q=75"
                alt="img-text"
              />
              <p className="learnmore-image-span-3">
                <span className="learnmore-image-span-icon ">
                  <i className="fa-solid fa-briefcase fa-xl"></i>Congrats..!
                </span>
                <br />
                Your Admition Complated{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <Waves />
    </div>
  );
};

export default LearnSkills;
