import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { MdArrowOutward } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import "./vendor.css";
import { useUserAuth } from "../../Context/UseAuthContext";
import vendorImg from "../../images/logo.png";
export default function Vendor() {
  const { subAdmins, loading, timestampToDateTime ,navigate} = useUserAuth();
  function getSkeletonHeight() {
    if (window.innerWidth < 768) {
      // Small screens
      return 20;
    } else if (window.innerWidth >= 768 && window.innerWidth < 992) {
      // Medium screens
      return 25;
    } else {
      // Large screens
      return 30;
    }
  }

  function getSkeletonWidth(isSecondSkeleton = false) {
    if (isSecondSkeleton && window.innerWidth < 768) {
      // Width for second Skeleton on small screens
      return 80;
    } else if (
      isSecondSkeleton &&
      window.innerWidth >= 768 &&
      window.innerWidth < 992
    ) {
      // Width for second Skeleton on medium screens
      return 150;
    } else {
      // Default width for Skeleton
      return 200;
    }
  }
  useEffect(() => {}, []);
  return (
    <div className="home1-container">
      <div className="instructor-h-c">
        {loading ? (
          <div className="skeleton-instructor-h-c">
            <div>
              <Skeleton height={30} width={300} />
            </div>
            <div>
              <Skeleton height={30} width={250} />
            </div>
          </div>
        ) : (
          <div className="instuctor-head">
            <h1 className="instructor-heading">
              Learn From The Best Instructors
            </h1>
            <p className="instructor-des">
              Learning from best Instructor helps us to keep pursuing our
              personal and professional development goals.
            
                It is said that 'Experience is the best teacher '. However, this
                experience doesn't necessarily have to be undergone personally,
                but can be learnt through others as well.
            
            </p>
         </div>
        )}
        {loading ? (
          <div>
            <Skeleton className="skeleton-view-all-instructors-btn" />
          </div>
        ) : (
          <Link to="/allvendor" className="link-tag">
            <button type="button" className="view-all-instructors-btn">
              Instructors <MdArrowOutward />
            </button>
          </Link>
        )}
      </div>
       
      {loading ? (
        // Show skeleton UI when loading
        <ul className="instructor-ul">
          {[...Array(4)].map((_, index) => (
            <li key={index} className="instructor-card-c">
              <Skeleton />
              <Skeleton
                height={getSkeletonHeight()}
                width={getSkeletonWidth()}
              />
              <Skeleton
                height={getSkeletonHeight()}
                width={getSkeletonWidth(true)}
              />
            </li>
          ))}
        </ul>
      ) : (
        // Show actual content when data is loaded
        <ul className="instructor-ul">
          {subAdmins &&
            subAdmins.slice(0, 4).map((eachItem) => (
              <li key={eachItem._id} className="instructor-card-c">
                {eachItem.profileUrl ? (
                  <img
                    src={eachItem.profileUrl}
                    className="instructor-img ins-new-img"
                    alt="Instructor"
                    onClick={()=>navigate(`/allvendor/${eachItem._id}`)}


                  />
                ) : (
                  <img
                    src={vendorImg}
                    className="instructor-img ins-new-img"
                    alt="Instructor"
                    onClick={()=>navigate(`/allvendor/${eachItem._id}`)}
                  />
                )}
                <div className="overlay"></div>
                <div className="instructor-des-c neww">
                  <Link to={`/allvendor/${eachItem._id}`} className="link-tag">
                    <div className="instructor-sub-h">
                      <p className="instructor-sub-h-name">{eachItem.name}</p>
                      <p className="instructor-sub-h-orders">
                        {eachItem.salesInfoOrderIdsArray.length}+
                      </p>
                    </div>
                    {/* <h1 className="instructor-sub-h">
                      Purchase :{eachItem.salesInfoOrderIdsArray.length}
                    </h1> */}
                    {/* <p className="instructor-des">
                      Join:-{timestampToDateTime(eachItem.timestamp)}
                    </p> */}
                  </Link>
                </div>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
}
