import { useEffect } from "react";
import "./App.css";
import  {  Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'animate.css';
import socketIOClient from "socket.io-client";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AllRoutes from "./Component/AllRoutes";
import Footer from "./Component/Footer/Footer";
import NavbarComponent from "./Component/Navbar/Navbar";
import { useUserAuth } from "./Context/UseAuthContext";
import ScrollToTop from "./Pages/Scrooltotopfunction";
const ENDPOINT = "https://smartuter.com";

// https://www.timeoutdubai.com/cloud/timeoutdubai/2021/09/11/hfpqyV7B-IMG-Dubai-UAE-1200x800.jpg
function App() {
  ScrollToTop();
  const { updateSocket, setUser, TOKEN_KEY, getUserData } =
    useUserAuth();
  useEffect(() => {
    const token = Cookies.get(TOKEN_KEY);
    if (token) {
      getUserData(token);
    } else {
      setUser(null);
    }

    window.scrollTo(0, 0);
    const iosocket = socketIOClient(ENDPOINT);

    iosocket.on("dataChange", (change) => {
      updateSocket(change);
    });

    return () => iosocket.disconnect();
  }, []);
  // if(loading){
  //   return <LoaderIcon/>
  // }
  return (
    <div className="App">
      <Toaster />
      <NavbarComponent />
      <AllRoutes />
      <Footer />
    </div>
  );
}

export default App;
