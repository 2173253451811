// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parent {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
}

.child {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-style: preserve-3d;
    transition: all 0.5s ease-in-out;
    border-radius: 50%;
    margin: 0 5px;
}

.child:hover {
    background-color: white;
    background-position: -100px 100px, -100px 100px;
    transform: rotate3d(0.5, 1, 0, 30deg);
    transform: perspective(180px) rotateX(60deg) translateY(2px);
    box-shadow: 0px 10px 10px rgb(1, 49, 182);
}

.button123 {
    border: none;
    background-color: transparent;
    font-size: 25px;
}

.button123:hover {
    width: inherit;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate3d(0px, 0px, 25px) perspective(180px) rotateX(-35deg) translateY(2px);
    border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/Pages/buttonGrid.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,4BAA4B;IAC5B,gCAAgC;IAChC,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,uBAAuB;IACvB,+CAA+C;IAC/C,qCAAqC;IACrC,4DAA4D;IAC5D,yCAAyC;AAC7C;;AAEA;IACI,YAAY;IACZ,6BAA6B;IAC7B,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,yFAAyF;IACzF,kBAAkB;AACtB","sourcesContent":[".parent {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-top: 200px;\n}\n\n.child {\n    width: 80px;\n    height: 80px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    transform-style: preserve-3d;\n    transition: all 0.5s ease-in-out;\n    border-radius: 50%;\n    margin: 0 5px;\n}\n\n.child:hover {\n    background-color: white;\n    background-position: -100px 100px, -100px 100px;\n    transform: rotate3d(0.5, 1, 0, 30deg);\n    transform: perspective(180px) rotateX(60deg) translateY(2px);\n    box-shadow: 0px 10px 10px rgb(1, 49, 182);\n}\n\n.button123 {\n    border: none;\n    background-color: transparent;\n    font-size: 25px;\n}\n\n.button123:hover {\n    width: inherit;\n    height: inherit;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    transform: translate3d(0px, 0px, 25px) perspective(180px) rotateX(-35deg) translateY(2px);\n    border-radius: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
