import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";

import { useUserAuth } from "../../Context/UseAuthContext";
import toast from "react-hot-toast";
import "./login.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Login = () => {
  const { getUserData, navigate, TOKEN_KEY } = useUserAuth();

  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [showPass, setShowPass] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      email,
      pass,
    };

    fetch("https://smartuter.com/api/enduser/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.msg === true) {
          toast.success(`${email} Login successful`);
          setEmail("");
          setPass("");
          try {
            res.token && Cookies.set(TOKEN_KEY, res.token, { expires: 1 });

            res.userID && localStorage.setItem("referralCode", res.userID); //referralcode

            // Cookies.set(TOKEN_KEY, res.token, { expires: 2 / 1440 });
            res.token && getUserData(res.token);
          } catch (error) {
            console.error("Error setting token in Cookies:", error);
          }

          //  (res.token && localStorage.getItem("endusertoken")&& getUserData());
          const referredLink = localStorage.getItem("referredLink");
          if (referredLink) {
            navigate(referredLink);
            localStorage.removeItem("referredLink");
          } else {
            navigate("/");
          }
        } else {
          toast.error(`${res.msg}`);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="login-component">
      <div className="login-container">
        <h2>Login</h2>
        <p>
          Don't have an account yet?{" "}
          <NavLink className={"navlink"} to={"/register"}>
            Register for free
          </NavLink>
        </p>
        <form className="login-from">
          <label>
            {/* Email: */}
            <input
              placeholder="Enter Your Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
              name="usernameOrEmail"
              className="no-transform"
            />
          </label>

          <label>
            {/* Password: */}
            <input
              placeholder="Enter Your password"
              type={showPass ? "text" : "password"}
              name="password"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
              className="no-transform"
            />
            <span
              className="register-eyebtn"
              onClick={() => setShowPass(!showPass)}
            >
              {/* {!showPass ? "Show" : "Hide"} */}
              {!showPass ? (
                <FaEyeSlash color="#A7A9AA" />
              ) : (
                <FaEye color="#A7A9AA" />
              )}
            </span>
          </label>
          <NavLink to={"/passwordreset"} className={"navlink"}>
            <p>Forgot password?</p>
          </NavLink>

          <button type="submit" onClick={handleSubmit}>
            Login
          </button>
        </form>
      </div>
    </div>
  );
};
export default Login;
