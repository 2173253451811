import React from "react";
import noShippingImage from "../../images/noshipping.png";
import { Link } from "react-router-dom";

const ShippingDelivery = () => {
  return (
    <div style={{ textAlign: "center", padding: "20px", color: "red" }}>
      <h1>No Shipping and Delivery Available</h1>
      <img src={noShippingImage} className="no-shipping-image" alt="No-shipping" />

      <p>
        Unfortunately, we do not offer shipping and delivery services at this
        time. Please check back later or contact us for more information. Return
        To Home
      </p>
      <Link to={"/"}>
        <button className="shipping-button">Home</button>
      </Link>
    </div>
  );
};

export default ShippingDelivery;
