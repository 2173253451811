import { MdArrowBackIos } from "react-icons/md";


const SamplePrevArrow=(props) =>{
    const { className, style, onClick } = props;
    return (
      <MdArrowBackIos
        className={className}
        style={{  ...style, display: "block",color:"#6440fb",borderRadius:"50%"}}
        onClick={onClick}
      />
    );
  }
  export default SamplePrevArrow;