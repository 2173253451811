import {  MdArrowForwardIos } from "react-icons/md";


  const SampleNextArrow=(props) =>{
    const { className, style, onClick } = props;
    return (
      <MdArrowForwardIos  className={className}
      style={{ ...style, display: "block",color:"#6440fb",borderRadius:"50%"}}
      onClick={onClick} />
     
    );
  }
  export default SampleNextArrow;