import React from 'react';
import './Terms&conditions.css'

const TermsAndConditions = () => {
  return (
    <div className="private-policy-container">
      <div>
        <h1>Terms and Conditions</h1>

        <p>
          Welcome to smartuter.com. By accessing or using our website, you agree
          to be bound by these Terms and Conditions. If you do not agree to
          these terms, please do not use our services.
        </p>

        <h4>1. Use of the Website</h4>
        <ul>
          <li>You must be at least 18 years old to use this website.</li>
          <li>
            You agree to use the website only for lawful purposes and in a way
            that does not infringe the rights of others or restrict their use
            and enjoyment of the website.
          </li>
          <li>
            You are responsible for maintaining the confidentiality of your
            account and password and for restricting access to your computer.
          </li>
        </ul>

        <h4>2. Purchase of Products and Services</h4>
        <ul>
          <li>
            All prices are listed in Indian Rupees (INR) and are subject to
            change without notice.
          </li>
          <li>
            We reserve the right to refuse or cancel any order for any reason,
            including limitations on quantities available for purchase,
            inaccuracies, or errors in product or pricing information.
          </li>
          <li>
            By placing an order, you are offering to purchase a product subject
            to these terms and conditions.
          </li>
        </ul>

        <h4>3. Intellectual Property</h4>
        <ul>
          <li>
            All content on this website, including text, graphics, logos,
            images, and software, is the property of smartuter.com or its
            content suppliers and is protected by international copyright laws.
          </li>
          <li>
            You may not use, reproduce, or distribute any content from this
            website without our prior written permission.
          </li>
        </ul>

        <h4>4. Limitation of Liability</h4>
        <ul>
          <li>
            smartuter.com will not be liable for any damages of any kind arising
            from the use of this site, including, but not limited to, direct,
            indirect, incidental, punitive, and consequential damages.
          </li>
          <li>
            Our liability for any claim arising out of or in connection with the
            use of the website or the purchase of any products or services shall
            not exceed the amount paid by you for the products or services in
            question.
          </li>
        </ul>

        <h4>5. Governing Law</h4>
        <ul>
          <li>
            These terms and conditions are governed by and construed in
            accordance with the laws of Telangana, India, and you agree to
            submit to the exclusive jurisdiction of the courts located in
            Telangana for the resolution of any disputes.
          </li>
        </ul>

        <h4>6. Changes to Terms and Conditions</h4>
        <ul>
          <li>
            We reserve the right to modify these terms and conditions at any
            time. Any changes will be posted on this page, and your continued
            use of the website following the posting of any changes constitutes
            acceptance of those changes.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TermsAndConditions;
