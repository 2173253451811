import React from 'react'
import { Link } from 'react-router-dom'

const VendorProject = ({project}) => {
  return (
    <div className="ven-cours-container">
      <h1 className="description">VendorProject</h1>
      {project && project.length > 0 ? (
        <div className="courses-container">
          {project.map((e, i) => (
            <div key={i} className="instructor-card-cour">
              <img
                src={e.OutputScreens}
                alt={`course${i}`}
                className="instructor-card-cour-img"
                onError={(e) => {
                  e.target.src =
                    "https://www.timeoutdubai.com/cloud/timeoutdubai/2021/09/11/hfpqyV7B-IMG-Dubai-UAE-1200x800.jpg";
                }}
              />
              {/* <div className="overlay"></div> */}
              <div className="instructor-card-cour-des-c">
                <Link to={`/project/${e._id}`}>
                  <h1 className="instructor-card-cour-sub-h">
                    {e.ProjectTitle}
                  </h1>
                </Link>
                <p className="instructor-card-cour-des">{e.courseCategory}</p>

                <p className="instructor-card-cour-des">₹{e.Price}/-</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="no-course-found-container">
          <p className="no-course-found-text">No projects found.</p>
          <img
            src="https://res.cloudinary.com/dyqpzx80s/image/upload/v1713333642/no-course-found_ifervi.jpg"
            className="no-course-found-img"
            alt="No Course"
          />
          {/* <p className="no-course-found-text">
            No  projects found.
          </p> */}
        </div>
      )}
    </div>
  );
}

export default VendorProject