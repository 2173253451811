import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../Context/UseAuthContext";
import { Link } from "react-router-dom";
import axios from "axios";
import "./project.css";
import { encodeCategoryForURL } from "../../Component/EncodedUrlComponent";
import { TfiArrowCircleLeft, TfiArrowCircleRight } from "react-icons/tfi";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import toast from "react-hot-toast";
import { truncateText } from "../trunacateText";
import { CiSearch } from "react-icons/ci";
import NoData from "../../images/NoData.gif";
import project from "../../images/project.gif";

const Project = () => {
  const {
    Projects,
    searchProject,
    setSearchProject,
    setSingleProjectId,

    fetchDataProject,
    user,
    getUserData,
    token,
    allMainCategory,
    allSubCategory,

    setCategoryFiltersproject,

    categoryFilterProject,

    setCategoryFilter,
    setSubCategoryFilterCourses,
    socket,
    courses,
    navigate,
  } = useUserAuth();
  const [activeCategory, setActiveCategory] = useState("All Categories");
  const [isOpen, setIsOpen] = useState(true);

  const [secOpen, setISecsOpen] = useState(true);
  const [isOpenbtn, setIsOpenbtn] = useState(false);

  const [showAllCategories, setShowAllCategories] = useState(null);
  const [showAllSubCategories, setShowAllSubCategories] = useState(null);

  //pagination
  const [currentPage, setCurrentPage] = useState(
    Projects && Projects.currentPage
  ); // Assuming initial page is 1
  const totalPages = Projects && Projects.totalPages; // Total number of pages
  const pageRangeDisplayed = 10; // Number of page numbers to display
  const pageNeighbours = 2; // Number of page numbers to show before and after the current page
  const startPage = Math.max(1, currentPage - pageNeighbours);
  const endPage = Math.min(totalPages, currentPage + pageNeighbours);
  const [checkedCategory, setCheckedCategory] = useState(null);
  const pagesToShow = [];
  const toggleShowAllCategories = (data) => {
    if (showAllCategories !== null) {
      return setShowAllCategories(null);
    }
    setShowAllCategories(data);
  };

  const toggleShowAllSubCategories = (data) => {
    if (showAllSubCategories !== null) {
      return setShowAllSubCategories(null);
    }
    setShowAllSubCategories(data);
  };
  const toggleCoursesAccordion = () => {
    setIsOpen(!isOpen);
  };

  const toggleCoursesAccordionSecound = () => {
    setISecsOpen(!secOpen);
  };

  const setPage = (page) => {
    setCurrentPage(page);
  };

  const handleCategory = (e) => {
    setCategoryFiltersproject(e);
    setActiveCategory(e);
    setPage(1);
  };

  const handleAddToCart = (e) => {
    if (!token) {
      toast.error("Please Login to your Account");
      return;
    }
    const id = user._id;

    // Check if the ID is already in the cart
    const isAlreadyInCart = user.cart.some((item) => item.ID === e._id);

    // If the ID is already in the cart, do not add it again
    if (isAlreadyInCart) {
      toast("Item is already in the cart.", {
        icon: "ℹ️",
        style: {
          borderRadius: "10px",
          background: "#blue",
          color: "#222",
          padding: "10px",
        },
      });
      return; // Exit the function early
    }
    // If the ID is not in the cart, add it
    const payload = {
      cart: [
        ...user.cart,
        {
          VendorID: e.userID,
          ID: e._id,
          courseCategory: e.courseCategory,
          imageUrl: e.OutputScreens,
          mainCategory: e.mainCategory,
          subCategory: e.subCategory,
          postDesc: e.ProjectDescription,
          postName: e.ProjectTitle,
          Price: e.Price,
          tags: e.TechnologiesUsed,
          Abstract: e.Abstract200Words,
          zipUrl: e.UploadCodeFile,
          DocumentUrl: e.UploadDocumentFile,
          timestamp: Date.now(),
        },
      ],
    };

    axios
      .patch(`https://smartuter.com/api/enduser/edit/${id}`, payload)
      .then((res) => {
        if (res.data.msg === true) {
          toast.success("Project Added Successfully to the Cart");
          getUserData(token);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchDataProject(currentPage);
  }, [currentPage, categoryFilterProject, searchProject]);

  const handleCheckboxChange = (category) => {
    setCategoryFilter(category);
    setSubCategoryFilterCourses("");
    if (checkedCategory === category) {
      // If the clicked category is already checked, uncheck it
      setCategoryFiltersproject("");

      fetchDataProject();
      setCheckedCategory(null);
    } else {
      // Otherwise, set the clicked category as checked
      setCheckedCategory(category);
    }
  };
  //MUlti Categories selections
  const [categoryProjects, setCatergoryProject] = useState([]);
  const [selectedMainCategories, setSelectedMainCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);

  useEffect(() => {
    if (selectedMainCategories.length > 0 || selectedSubCategories.length > 0) {
      fetchProjects();
    } else {
      setCatergoryProject([]);
    }
  }, [selectedMainCategories, selectedSubCategories]);

  const fetchProjects = async () => {
    try {
      const response = await axios.get(
        "https://smartuter.com/api/enduser/multiProjects",
        {
          params: {
            mainCategories: selectedMainCategories.join(","),
            subCategories: selectedSubCategories.join(","),
          },
        }
      );
      setCatergoryProject(response.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const handleMainCategoryChange = (category) => {
    setSelectedMainCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
  };

  const handleSubCategoryChange = (category) => {
    setSelectedSubCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
  };

  // console.log(categoryProjects);
  for (let i = startPage; i <= endPage; i++) {
    pagesToShow.push(i);
  }

  return (
    <section className="ProjectsSection">
      <div className="projectSectionOne">
        <div className="ProjectHeadline">
          {/* <div className="ProjectHeading">
            <h1>ALL PROJECTS</h1>
          </div>
          <div>
            <p className="ProjectHeading-para">
              We’re on a mission to deliver engaging, curated projects at a
              reasonable price.
            </p>{" "}
          </div> */}

          {/* <div className="all-courses-main">
      <h3 className="course-section-card-head">ALL PROJECTS</h3>
      <p className="course-section-card-para">
      We’re on a mission to deliver engaging, curated projects at a
      reasonable price.
      </p>
      
      </div> */}

          <div className="all-courses-main">
            <div>
              <h3 className="course-section-card-head">ALL PROJECTS</h3>
              <p className="course-section-card-para">
                We’re on a mission to deliver engaging, curated projects at a
                reasonable price.{" "}
              </p>

              <div className="header-navitems">
                <Link to={"/"}>
                  <span className="header-links">Home</span>
                </Link>
                <span className="mx-1">{">"}</span>
                <Link to={"/project"}>
                  <span className="header-links underline-headerlink">
                    Project
                  </span>
                </Link>
              </div>
            </div>
            <img src={project} alt="Project Img" />
          </div>
        </div>
      </div>

      <div className="Project-new-sesstion">
        <div className="MostPopularProjectsCategoriesComponent">
          <div className="ProjectsCategories">
            <div className="dropdown">
              <div
                className={`${
                  isOpen ? "open" : ""
                } project-section-each-category`}
                onClick={toggleCoursesAccordion}
              >
                <div className="project-section-each-category-title">
                  Projects Category
                </div>
                <div className="project-section-each-category-arrow">
                  {isOpen ? (
                    <RiArrowDropUpLine className="up-arrow" />
                  ) : (
                    <RiArrowDropDownLine className="down-arrow" />
                  )}
                </div>
              </div>
              {isOpen && (
                <div>
                  {allMainCategory &&
                    allMainCategory
                      .slice(
                        0,
                        showAllCategories === "category"
                          ? allMainCategory.length
                          : 3
                      )
                      .map((cat, i) => (
                        <label
                          key={i}
                          className="studymaterial-section-category-checkbox-container"
                        >
                          <input
                            className="project-section-category-checkbox"
                            type="checkbox"
                            value={cat.category}
                            onChange={() =>
                              handleMainCategoryChange(cat.category)
                            }
                          />
                          <p className="studymaterial-section-category-checkbox-para">
                            {cat.category}
                          </p>
                        </label>
                      ))}
                  {(allMainCategory && allMainCategory.length) > 3 && (
                    <button
                      style={{ color: "blue" }}
                      onClick={() => toggleShowAllCategories("category")}
                      className="study-material-show-more"
                    >
                      {showAllCategories === "category"
                        ? "Show Less"
                        : "Show More"}
                    </button>
                  )}
                </div>
              )}
            </div>
            <hr />

            <div
              className={`Courses-accordion-header ${
                secOpen ? "open" : ""
              } course-section-each-category`}
              onClick={toggleCoursesAccordionSecound}
            >
              <div className="course-section-each-category-title">
                SubCategory
              </div>
              <div className="course-section-each-category-arrow">
                {secOpen ? (
                  <RiArrowDropUpLine className="up-arrow" />
                ) : (
                  <RiArrowDropDownLine className="down-arrow" />
                )}
              </div>
            </div>
            {secOpen && (
              <div className="course-section-category-container-each">
                {allSubCategory &&
                  allSubCategory
                    .slice(
                      0,
                      showAllSubCategories === "category"
                        ? allSubCategory.length
                        : 3
                    )
                    .map((cat, i) => (
                      <label
                        key={i}
                        className="course-section-category-checkbox-container"
                      >
                        <input
                          className="project-section-category-checkbox"
                          type="checkbox"
                          value={cat.category}
                          onChange={() => handleSubCategoryChange(cat.category)}
                        />
                        <p className="course-section-category-checkbox-para">
                          {cat.category}
                        </p>
                      </label>
                    ))}
                {(allSubCategory && allSubCategory.length) > 3 && (
                  <button
                    style={{ color: "blue" }}
                    onClick={() => toggleShowAllSubCategories("category")}
                    className="study-material-show-more"
                  >
                    {showAllSubCategories === "category"
                      ? "Show Less"
                      : "Show More"}
                  </button>
                )}
              </div>
            )}
            <hr />
          </div>
        </div>

        <div className="projectsContainer">
          <div className="project-section-display-filter">
            {/* <input
              type="search"
              value={searchProject}
              onChange={(e) => setSearchProject(e.target.value)}
              placeholder="search..."
            /> */}

            <p className="project-section-filter-para">
              Showing{" "}
              { categoryProjects && categoryProjects.length?  categoryProjects.length:Projects && Projects.Projects && Projects.Projects.length
           
              +" "}              total results
            </p>

            <div className="course-section-search">
              <div className="input-container">
                <CiSearch className="search-icon" />
                <input
                  type="search"
                  value={searchProject}
                  onChange={(e) => setSearchProject(e.target.value)}
                  placeholder="Search Projects..."
                  className="courses-search"
                />
              </div>
            </div>
          </div>
          {categoryProjects.length > 0 ? (
            categoryProjects.map((item, index) => (
              // <div className="project-Card" key={index}>
              //   <div className="projectImageCard">
              //     <img
              //       className="project-img"
              //       src={item.OutputScreens}
              //       alt="porject-img"
              //       onError={(e) => {
              //         e.target.src =
              //           "https://www.timeoutdubai.com/cloud/timeoutdubai/2021/09/11/hfpqyV7B-IMG-Dubai-UAE-1200x800.jpg";
              //       }}
              //     />
              //   </div>
              //   <div className="projectInfoCard">
              //     <h4 className="projectTitle">
              //       <Link
              //         to={`/project/${item._id}`}
              //         onClick={() => setSingleProjectId(item._id)}
              //       >
              //         {/* {truncateText(item.ProjectTitle, 3)} */}
              //         {item.ProjectTitle.substring(0, 25)+"..."}

              //       </Link>
              //     </h4>

              //     <div className="projecttags">
              //       {item.TechnologiesUsed.slice(0, 2).map((item, i) => (
              //         <span key={i}>
              //           {" "}
              //           <i className="fa-regular fa-bookmark"></i> {item.text}
              //         </span>
              //       ))}
              //     </div>

              //     {/* <div className="Categories">
              //       <span style={{ color: "#06517a" }}>
              //         {item.mainCategory}
              //       </span>
              //       >>
              //       <span style={{ color: "#346581" }}>{item.subCategory}</span>
              //       >>
              //       <span style={{ color: "#506e7f" }}>
              //         {item.courseCategory}
              //       </span>
              //     </div> */}
              //     <div className="projectBuyBtn">
              //       {user &&
              //         user.cart &&
              //         user.orders &&
              //         (user.cart.some(
              //           (cartItem) => cartItem.ID === item._id
              //         ) ? (
              //           <Link to={"/cart"}>
              //             <button className="disableBTN">
              //               Already In Cart
              //             </button>
              //           </Link>
              //         ) : user.orders.some(
              //             (orderItem) => orderItem.ID === item._id
              //           ) ? (
              //           <Link to={"/order"}>
              //             <button className="disableBTN">
              //               Please Go to Orders
              //             </button>
              //           </Link>
              //         ) : (
              //           <button
              //             className="enableBTN"
              //             onClick={() => handleAddToCart(item)}
              //           >
              //             Add To Cart
              //           </button>
              //         ))}
              //       {!user && (
              //         <button
              //           className="enableBTN"
              //           onClick={() => (
              //             navigate("/login"), handleAddToCart(item)
              //           )}
              //         >
              //           Add To Cart
              //         </button>
              //       )}
              //     </div>
              //     {/* <button onClick={() => handleAddToCart(item)}>ADD</button> */}
              //   </div>
              // </div>
              <div className="project-Card" key={index}>
                <div className="projectImageCard">
                  <img
                    className="project-img"
                    src={item.OutputScreens}
                    alt="porject-img"
                    onError={(e) => {
                      e.target.src =
                        "https://www.timeoutdubai.com/cloud/timeoutdubai/2021/09/11/hfpqyV7B-IMG-Dubai-UAE-1200x800.jpg";
                    }}
                  />
                </div>
                <div className="projectInfoCard">
                  <div>
                    <div className="projectTitle">
                      <Link
                        to={`/project/${item._id}`}
                        onClick={() => setSingleProjectId(item._id)}
                      >
                        {/* {truncateText(item.ProjectTitle, 3)} */}

                        {item.ProjectTitle.substring(0, 25) + "..."}
                      </Link>
                    </div>
                  </div>
                  <div className="projecttags">
                    {item.TechnologiesUsed.slice(0, 2).map((item, i) => (
                      <span className="tags" key={i}>
                        {" "}
                        {item.text}
                      </span>
                    ))}
                  </div>

                  {/* <div className="Categories">
                  <span style={{ color: "#06517a" }}>
                    {item.mainCategory}
                  </span>
                  >>
                  <span style={{ color: "#346581" }}>{item.subCategory}</span>
                  >>
                  <span style={{ color: "#506e7f" }}>
                    {item.courseCategory}
                  </span>
                </div> */}
                  <div className="projectBuyBtn">
                    {user &&
                      user.cart &&
                      user.orders &&
                      (user.cart.some(
                        (cartItem) => cartItem.ID === item._id
                      ) ? (
                        <Link to={"/cart"}>
                          <button className="disableBTN">
                            Already In Cart
                          </button>
                        </Link>
                      ) : user.orders.some(
                          (orderItem) => orderItem.ID === item._id
                        ) ? (
                        <Link to={"/order"}>
                          <button className="disableBTN">
                            Please Go to Orders
                          </button>
                        </Link>
                      ) : (
                        <button
                          className="enableBTN"
                          onClick={() => handleAddToCart(item)}
                        >
                          Add To Cart
                        </button>
                      ))}
                    {!user && (
                      <button
                        className="enableBTN"
                        onClick={() => (
                          navigate("/login"), handleAddToCart(item)
                        )}
                      >
                        Add To Cart
                      </button>
                    )}
                  </div>
                  <div className="CoursePrice">
                    <p>
                      Price: <span className="card-Price">₹{item.Price}</span>
                    </p>
                  </div>
                  {/* <button onClick={() => handleAddToCart(item)}>ADD</button> */}
                </div>
              </div>
            ))
          ) : Projects !== null &&
            Projects.Projects &&
            Projects.Projects.length > 0 ? (
            Projects.Projects.map((item, index) => (
              <div className="project-Card" key={index}>
                <div className="projectImageCard">
                  <img
                    className="project-img"
                    src={item.OutputScreens}
                    alt="porject-img"
                    onError={(e) => {
                      e.target.src =
                        "https://www.timeoutdubai.com/cloud/timeoutdubai/2021/09/11/hfpqyV7B-IMG-Dubai-UAE-1200x800.jpg";
                    }}
                  />
                </div>
                <div className="projectInfoCard">
                  <div>
                    <div className="projectTitle">
                      <Link
                        to={`/project/${item._id}`}
                        onClick={() => setSingleProjectId(item._id)}
                      >
                        {/* {truncateText(item.ProjectTitle, 3)} */}

                        {item.ProjectTitle.substring(0, 25) + "..."}
                      </Link>
                    </div>
                  </div>
                  <div className="projecttags">
                    {item.TechnologiesUsed.slice(0, 2).map((item, i) => (
                      <span className="tags" key={i}>
                        {" "}
                        {item.text}
                      </span>
                    ))}
                  </div>

                  {/* <div className="Categories">
                    <span style={{ color: "#06517a" }}>
                      {item.mainCategory}
                    </span>
                    >>
                    <span style={{ color: "#346581" }}>{item.subCategory}</span>
                    >>
                    <span style={{ color: "#506e7f" }}>
                      {item.courseCategory}
                    </span>
                  </div> */}
                  <div className="projectBuyBtn">
                    {user &&
                      user.cart &&
                      user.orders &&
                      (user.cart.some(
                        (cartItem) => cartItem.ID === item._id
                      ) ? (
                        <Link to={"/cart"}>
                          <button className="disableBTN">
                            Already In Cart
                          </button>
                        </Link>
                      ) : user.orders.some(
                          (orderItem) => orderItem.ID === item._id
                        ) ? (
                        <Link to={"/order"}>
                          <button className="disableBTN">
                            Please Go to Orders
                          </button>
                        </Link>
                      ) : (
                        <button
                          className="enableBTN"
                          onClick={() => handleAddToCart(item)}
                        >
                          Add To Cart
                        </button>
                      ))}
                    {!user && (
                      <button
                        className="enableBTN"
                        onClick={() => (
                          navigate("/login"), handleAddToCart(item)
                        )}
                      >
                        Add To Cart
                      </button>
                    )}
                  </div>
                  <div className="CoursePrice">
                    <p>
                      Price: <span className="card-Price">₹{item.Price}</span>
                    </p>
                  </div>
                  {/* <button onClick={() => handleAddToCart(item)}>ADD</button> */}
                </div>
              </div>
            ))
          ) : (
            <div className="no-course-found-container">
              <p className="no-course-found-text">
                Sorry..!, we couldn't find any result.
              </p>

              <img src={NoData} alt="No Projects" />
            </div>
          )}
        </div>
      </div>
      {/* //pagination */}
      {categoryProjects.length <= 0 && (
        <div className="pagination">
          {currentPage > 1 && (
            <button onClick={() => setPage(currentPage - 1)}>
              <TfiArrowCircleLeft className="prev_page" />
            </button>
          )}
          {startPage > 1 && (
            <>
              <button onClick={() => setPage(1)}>1</button>
              {startPage > 2 && <span>...</span>}
            </>
          )}
          {pagesToShow.map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => setPage(pageNumber)}
              className={pageNumber === currentPage ? "active" : ""}
            >
              {pageNumber}
            </button>
          ))}
          {endPage < totalPages && (
            <>
              {endPage < totalPages - 1 && <span>...</span>}
              <button onClick={() => setPage(totalPages)}>{totalPages}</button>
            </>
          )}
          {currentPage < totalPages && (
            <button onClick={() => setPage(currentPage + 1)}>
              <TfiArrowCircleRight className="next_page" />
            </button>
          )}
        </div>
      )}
    </section>
  );
};

export default Project;
