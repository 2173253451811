import React from 'react';
 import "./Policies.css"

const CancellationPolicy = () => {
  return (
    <div className="private-policy-container">
      <h2>Cancellation Policy</h2>
      <p>
        At smartuter.com, we strive to ensure customer satisfaction. Please read
        our Cancellation policy carefully.
      </p>

      <h4>1. Digital Products</h4>
      <ul>
        <li>
          Due to the nature of digital products (study materials, courses,
          videos), all sales are final and non-refundable.
        </li>
        <li>
          If you experience any issues accessing or using a digital product,
          please contact our support team within 7 days of purchase for
          assistance.
        </li>
      </ul>

      <h4>2. Contact Us</h4>
      <ul>
        <li>
          If you have any questions or concerns about our Cancellation policy,
          please contact us at{" "}
          <a href="mailto:smartuter@gmail.com">smartuter@gmail.com</a>.
        </li>
      </ul>
    </div>
  );
};

export default CancellationPolicy;
