import React from "react";
import { Link } from "react-router-dom";
import NoData from "../../../images/NoData.gif"


const VendorCourse = ({ course }) => {
  return (
    <div className="ven-cours-container">
      <h1 className="description">VendorCourse</h1>
      {course && course.length > 0 ? (
        <div className="courses-container">
          {course.map((e, i) => (
            <div key={i} className="instructor-card-cour">
              <img
                src={e.imageUrl}
                alt={`course${i}`}
                className="instructor-card-cour-img"
              />
              {/* <div className="overlay"></div> */}
              <div className="instructor-card-cour-des-c">
                <Link to={`/course/${e._id}`}>
                  <h1 className="instructor-card-cour-sub-h">{e.postName}</h1>
                </Link>
                <p className="instructor-card-cour-des">{e.courseCategory}</p>

                <p className="instructor-card-cour-des">₹{e.price}/-</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="no-course-found-container">
            <p className="no-course-found-text">
              Sorry..!, we couldn't find any result.
            </p>

            <img src={NoData} />
          </div>
      )}
    </div>
  );
};

export default VendorCourse;
