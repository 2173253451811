// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main_login{
    width: 100%;
   
}
.resetpassword{
    margin: 8vh auto;
}
.reset-input-group{
    display: flex;
    flex-direction: column;
  
  }
.reset-input-group label{
    font-size: 1rem;
    margin-top: 2vh;
}
.reset-input-group input{
    border-radius: 10vh;
}
.resetpassword button{
    width: 100%;
   
    margin-right: auto;
    margin-top: 3 0px;
    padding: 15px 40px;
    background-color: #06517a;
    border: none;
    border: 2px solid #06517a;
    border-radius: 5px;
    margin-top: 20px;
    /* background-color: transparent; */
    color: white;
    font-size: 1.2rem;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .resetpassword button:hover {
    border: 2px solid #06517a;
    background-color: transparent;
    transition: all 2s ease;
    color: #06517a;
  }`, "",{"version":3,"sources":["webpack://./src/Pages/Profile/passwordReset.css"],"names":[],"mappings":"AAAA;IACI,WAAW;;AAEf;AACA;IACI,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,sBAAsB;;EAExB;AACF;IACI,eAAe;IACf,eAAe;AACnB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,WAAW;;IAEX,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,mCAAmC;IACnC,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;IACnB,eAAe;EACjB;EACA;IACE,yBAAyB;IACzB,6BAA6B;IAC7B,uBAAuB;IACvB,cAAc;EAChB","sourcesContent":[".main_login{\n    width: 100%;\n   \n}\n.resetpassword{\n    margin: 8vh auto;\n}\n.reset-input-group{\n    display: flex;\n    flex-direction: column;\n  \n  }\n.reset-input-group label{\n    font-size: 1rem;\n    margin-top: 2vh;\n}\n.reset-input-group input{\n    border-radius: 10vh;\n}\n.resetpassword button{\n    width: 100%;\n   \n    margin-right: auto;\n    margin-top: 3 0px;\n    padding: 15px 40px;\n    background-color: #06517a;\n    border: none;\n    border: 2px solid #06517a;\n    border-radius: 5px;\n    margin-top: 20px;\n    /* background-color: transparent; */\n    color: white;\n    font-size: 1.2rem;\n    margin-bottom: 20px;\n    cursor: pointer;\n  }\n  .resetpassword button:hover {\n    border: 2px solid #06517a;\n    background-color: transparent;\n    transition: all 2s ease;\n    color: #06517a;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
