import "./CourseSkeliton.css";
import Skeleton from 'react-loading-skeleton';


const SkelitonCourses = () => {
  const categories = [
    "All Categories",
    "Animation",
    "Design",
    "Photography",
    "Art",
    "Programming",
    "Writing",
  ];

 return (
    <section className="MostPopularCourses-component">
      <div className="header-text-component">

    <div>
      <Skeleton width={300} height={30} />
      <br></br>
      <Skeleton width={350} height={20} />
    </div>
  
</div>
      <div className="MostPopularCoursesCategoriesComponent">
      <div className="CoursesCategories">
 
   { Array.from({ length: categories.length }).map((_, index) => (
      <div key={index} className="category">
        <Skeleton width={100} height={30} />
      </div>
    ))}
 
</div>
      </div>

      <div className="CoursesContainer">
       {Array.from({ length: 5 }).map((_, index) => (
          <div key={index} className="CourseCard">
            <div className="ImageAndTitle">
              <Skeleton width={250} height={180} />
            </div>
            <div className="CourseTitle-border">
              <div>
                <a href="#" className="CourseTitle">
                  <Skeleton width={200} height={25} />
                </a>
              </div>
              <div className="TagsContainer">
                <Skeleton width={150} count={2} />
              </div>
              <div className="CategoriesCard">
                <Skeleton width={150} />
              </div>
              <div className="CourseAddtoCart">
                <Skeleton width={250} height={40} />
              </div>
            </div>
            <div className="AuthorAndPrice">
              <div className="Author">
                <Skeleton circle={true} width={50} height={50} />
                <Skeleton width={100} />
              </div>
              <div className="CoursePrice">
                <Skeleton width={50} />
              </div>
            </div>
          </div>

          ))}
       
       
      </div>
    </section>
  );
};

export default SkelitonCourses;
