import React from "react";
import "./footerNew.css";
import { encodeCategoryForURL } from "../EncodedUrlComponent";
import { useUserAuth } from "../../Context/UseAuthContext";
import ScrollToTopLink from "../../Pages/ScroolToTop";
import Logo from "../../images/logo (2).png";
import { Link } from "react-router-dom";
import { FaHandPointer, FaLink, FaGift } from "react-icons/fa";

const Footer = () => {
  const { allMainCategory, setCategoryFilter } = useUserAuth();
  const handleScroolTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div>
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-sectio footer-sesstion-1">
            <img src={Logo} alt="Smartuter Logo" className="footer-logo" />
            <div className="Footer_meadia footer-slide-one">
              <h4>Become a vendor earn upto ₹2k to ₹5k</h4>

              <div className="steps-container">
                <div className="step">
                  <div className="icon-container">
                    <a href="https://vendor.smartuter.com/">
                      {" "}
                      <FaHandPointer className="step-icon" />
                    </a>
                  </div>
                  <div className="step-text">
                    Join
                  </div>
                </div>
                <div className="arrow"></div>
                <div className="step">
                  <div className="icon-container">
                    <FaLink className="step-icon" />
                    <a href="https://vendor.smartuter.com/">
                      {" "}
                      <FaLink className="step-icon" />
                    </a>
                  </div>
                  <div className="step-text">
                   Share
                  </div>
                </div>
                <div className="arrow"></div>
                <div className="step">
                  <div className="icon-container">
                    <FaGift className="step-icon" />
                  </div>
                  <div className="step-text">Rewards</div>
                </div>
              </div>

              <a href="https://vendor.smartuter.com/">
                <button className="clickhere-button">Click Here</button>
              </a>
            </div>
          </div>

          <div className="footer-sesstion-subdiv">
            <div className="footer-section">
              <div className="footer-title">About</div>
              <ul className="footer-links">
                <li>
                  <Link onClick={handleScroolTop} to={"/about"}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link onClick={handleScroolTop} to={"/"}>
                    Learner Stories
                  </Link>
                </li>
                <li>
                  <Link onClick={handleScroolTop} to={"/"}>
                    Careers
                  </Link>
                </li>
                <li>
                  <Link onClick={handleScroolTop} to={"/"}>
                    Press
                  </Link>
                </li>
                <li>
                  <Link onClick={handleScroolTop} to={"/allvendor"}>
                    Leadership
                  </Link>
                </li>
                <li>
                  <Link onClick={handleScroolTop} to={"/contact"}>
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-section">
              <div className="footer-title">Categories</div>
              <ul className="footer-links">
                {allMainCategory &&
                  allMainCategory.slice(0, 6).map((e, i) => (
                    <li key={i}>
                      <Link
                        key={i}
                        to={`/coursecatgory/${encodeCategoryForURL(
                          e.category
                        )}`}
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          setCategoryFilter(encodeCategoryForURL(e.category));
                        }}
                      >
                        {e.category}
                      </Link>{" "}
                    </li>
                    // <a key={i} href={e._id}>{e.category}</a>
                  ))}
              </ul>
            </div>
            <div className="footer-section">
              <div className="footer-title">Get in touch</div>
              <p>We don’t send spam so don’t worry.</p>
              <form className="footer-form">
                <input
                  type="email"
                  placeholder="email"
                  className="footer-input"
                />
                <button type="submit" className="footer-button">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="footer-bottom-links">
            {/* <a href="#">Contact</a> */}
            <Link onClick={handleScroolTop} to={"/contact"}>
              Contact
            </Link>
            <Link onClick={handleScroolTop} to={"/privacypolicy"}>
              Privacy Policy
            </Link>
            <Link onClick={handleScroolTop} to={"/cancellationpolicy"}>
              Cancellation Policy
            </Link>
            <Link onClick={handleScroolTop} to={"/refundpolicy"}>
              Refund
            </Link>
            <Link onClick={handleScroolTop} to={"/terms-and-conditions"}>
              Terms & Conditions
            </Link>{" "}
          </div>
          <div className="footer-bottom-text">© 2024 Smartuter By Monosage</div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
