import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../Context/UseAuthContext";

import { Link, useParams } from "react-router-dom";
import axios from "axios";
import "./singleproject.css";
import { CiCalendar } from "react-icons/ci";
import { FaLocationDot, FaRegClipboard, FaWhatsapp } from "react-icons/fa6";
import { BiLogoGmail } from "react-icons/bi";
import { ImLocation } from "react-icons/im";
import toast from "react-hot-toast";
import { FaAsterisk } from "react-icons/fa";
import { IoMdShareAlt } from "react-icons/io";

const SingleProject = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleParagraph = () => {
    setIsExpanded(!isExpanded);
  };
  const [isExpanded2, setIsExpanded2] = useState(false);

  const toggleParagraph2 = () => {
    setIsExpanded2(!isExpanded2);
  };

  const {
    setSingleProjectId,
    singleProject,
    user,
    getUserData,
    token,
    navigate,
  } = useUserAuth();
  const param = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const url = new URL(window.location.href);

  // Get the value of the 'referalcode' query parameter
  const referralCode = url.searchParams.get("referalcode");
  // Get the value of the 'referalcode' query parameter
  const referalLink = `${window.location.pathname}?referalcode=${referralCode}`;
  const referredLink = localStorage.setItem("referredLink", referalLink);
  // Log or use the referral cod

  const handleShare = () => {
    if (!token) {
      toast.error("Please Login to your Account");
      return;
    }
    const referalcode = localStorage.getItem("referralCode");
    const url = window.location.href;
    const urlwithoutParams = url.split("?")[0];

    const referalLink = `${urlwithoutParams}?referalcode=${referalcode}`;

    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const shareOnWhatsApp = () => {
    const referalcode = localStorage.getItem("referralCode");
    const url = window.location.href;
    const urlwithoutParams = url.split("?")[0];
    const referalLink = `${urlwithoutParams}?referalcode=${referalcode}`; // Replace with your actual referral link
    const message = `Hey there, I'm using WhatsApp! Check this out This is course which i have used in smartuter: ${referalLink}`;
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      message
    )}`;

    window.open(whatsappUrl, "_blank");
  };

  const copyToClipboard = () => {
    const referalcode = localStorage.getItem("referralCode");
    const url = window.location.href;
    const urlwithoutParams = url.split("?")[0];

    const referalLink = `${urlwithoutParams}?referalcode=${referalcode}`;
    navigator.clipboard.writeText(referalLink).then(
      () => {
        toast.success("Referral link copied to clipboard!");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const shareViaGmail = () => {
    const referalcode = localStorage.getItem("referralCode");
    const url = window.location.href;
    const urlwithoutParams = url.split("?")[0];

    const referalLink = `${urlwithoutParams}?referalcode=${referalcode}`;
    const subject = "Check out this referral link!";
    const body = `Hi, check out this referral link: ${referalLink}`;
    const gmailUrl = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = gmailUrl;
  };

  const handleAddToCart = (e) => {
    if (!token) {
      toast.error("Please Login to your Account");
      return;
    }
    const referalcode = localStorage.getItem("referralCode");
    const url = window.location.href;

    const test = url.split("=");

    if (referalcode === test[1]) {
      const url = window.location.href;
      window.location.href = url.split("?")[0];
    }
    const id = user._id;

    // Check if the ID is already in the cart
    const isAlreadyInCart = user.cart.some((item) => item.ID === e._id);

    // If the ID is already in the cart, do not add it again
    if (isAlreadyInCart) {
      toast("Item is already in the cart.", {
        icon: "ℹ️",
        style: {
          borderRadius: "10px",
          background: "#blue",
          color: "#222",
          padding: "10px",
        },
      });
      return; // Exit the function early
    }
    // If the ID is not in the cart, add it
    const payload = {
      cart: [
        ...user.cart,
        {
          VendorID: e.userID,
          ID: e._id,
          courseCategory: e.courseCategory,
          imageUrl: e.OutputScreens,
          mainCategory: e.mainCategory,
          subCategory: e.subCategory,
          postDesc: e.ProjectDescription,
          postName: e.ProjectTitle,
          Price: e.Price,
          tags: e.TechnologiesUsed,
          Abstract: e.Abstract200Words,
          zipUrl: e.UploadCodeFile,
          DocumentUrl: e.UploadDocumentFile,
          timestamp: Date.now(),
          referralCode: referralCode,
        },
      ],
    };

    axios
      .patch(`https://smartuter.com/api/enduser/edit/${id}`, payload)
      .then((res) => {
        if (res.data.msg === true) {
          toast.success("Project Added Successfully to the Cart");
          getUserData(token);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setSingleProjectId(param.projectID);
  }, []);

  return (
    <div className="single-project">
      {singleProject && (
        <div className="event-container">
          <div className="event">
            <div className="timing-container">
            {singleProject &&
                      singleProject.TechnologiesUsed &&
                      singleProject.TechnologiesUsed.map((tech, j) => (
                        <span key={j} className="tech m-2">
                          {tech.text}
                        </span>
                      ))}
              <div className="timing"></div>
            </div>
            <div className="event-card">
              
              {/* accordinans start here */}
<div className="timmig-ccourdian-card">
<div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                  {/* <h2 className="accordion-header">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
        Description..
      </button>
    </h2> */}
                  <div
                    id="panelsStayOpen-collapseOne"
                    className="accordion-collapse collapse show"
                  >
                    <div className="accordion-body">
                      <h3>{singleProject.ProjectTitle}</h3>
                      <p className={isExpanded ? "full" : "truncated"}>
                        {singleProject.ProjectDescription}
                      </p>
                      <p
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={toggleParagraph}
                      >
                        {isExpanded ? "Show Less" : "Read More..."}
                      </p>
                      {/* <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow. */}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseTwo"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseTwo"
                    >
                      Abstract...
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseTwo"
                    className="accordion-collapse collapse"
                  >
                    <div className="accordion-body">
                      <p className={isExpanded2 ? "full" : "truncated"}>
                        {singleProject.Abstract200Words}
                      </p>
                      <p
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={toggleParagraph2}
                      >
                        {isExpanded2 ? "Show Less" : "Read More..."}
                      </p>
                    </div>
                  </div>
                </div>
              
              </div>
</div>
             

              {/* accordinans end here */}

              <div className="card-event-2">
                <div className="timing-card-main">
                  <img src={singleProject.OutputScreens} alt="project-img" />
           
                <div className="ratiming-card-sub">
                  <div>
                    <h5 className="mt-3"><span>Title</span>: {singleProject.ProjectTitle.substring(0, 23)+ "..."}</h5>
                    {" "}
                    <p><span>Price</span>: ₹{singleProject.Price} </p>
                  </div>
                </div>
     
                <div className="events-cart">
                  <button onClick={() => handleShare()}><IoMdShareAlt />
                  </button>
                  {isModalOpen && (
                    <div className="modal">
                      <div className="modal-content">
                        <span className="close" onClick={closeModal}>
                          &times;
                        </span>
                        <h2>Share Via</h2>
                        <div>
                          <FaWhatsapp
                            className="share-logo whatsapp "
                            onClick={shareOnWhatsApp}
                          />
                          <FaRegClipboard
                            className="share-logo"
                            onClick={copyToClipboard}
                          />
                          <BiLogoGmail
                            className="share-logo gmail"
                            onClick={shareViaGmail}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {user &&
                    user.cart &&
                    user.orders &&
                    (user.cart.some(
                      (cartItem) => cartItem.ID === singleProject._id
                    ) ? (
                      <button disabled className="disableBTN">
                        Already In Cart
                      </button>
                    ) : user.orders.some(
                        (orderItem) => orderItem.ID === singleProject._id
                      ) ? (
                      <Link to={"/order"} style={{ width: "100%" }}>
                        <button className="disableBTN">
                          Please Go to Orders
                        </button>
                      </Link>
                    ) : (
                      <button
                        className="enableBTN"
                        onClick={() => handleAddToCart(singleProject)}
                      >
                        Add To Cart
                      </button>
                    ))}
                  {!user && (
                    <button
                      className="enableBTN"
                      onClick={() => (
                        navigate("/login"), handleAddToCart(singleProject)
                      )}
                    >
                      Add To Cart
                    </button>
                  )}
                </div>

                </div>
                
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleProject;
