import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../Context/UseAuthContext";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { encodeCategoryForURL } from "../../Component/EncodedUrlComponent";
import "./courses.css";
import SkelitonCourses from "../../Skeliton/CourseSekliton/CourseSkeliton";
import ScrollToTopLink from "../ScroolToTop";
import Slider from "react-slick";
import nocourse from "../../images/no-Course.jpg";
import SampleNextArrow from "../../Component/ReactSlickBtn/Next";
import SamplePrevArrow from "../../Component/ReactSlickBtn/Prev";
import toast from "react-hot-toast";
import { truncateText } from "../trunacateText";
import NoData from '../../images/NoData.gif'

const Course = () => {
  const {
    getUserData,
    fetchDataCourse,
    courses,
    navigate,
    setSingleCourseId,
    user,
    token,
    allMainCategory,
    setCategoryFilter,
    CategoryCourses,
  } = useUserAuth();

  const [activeCategory, setActiveCategory] = useState("All Categories");
  const handleCategory = (e) => {
    setCategoryFilter(e);
    setActiveCategory(e);
  };
  const handleAddToCart = (e) => {
    if (!token) {
      toast.error("Please Login to your Account");
      return (
        <div>
          <Link to="/login">Login</Link>
        </div>
      );
    }
    const id = user._id;

    // Check if the ID is already in the cart
    const isAlreadyInCart = user.cart.some((item) => item.ID === e._id);

    // If the ID is already in the cart, do not add it again
    if (isAlreadyInCart) {
      toast("Item is already in the cart.", {
        icon: "ℹ️",
        style: {
          borderRadius: "10px",
          background: "#blue",
          color: "#222",
          padding: "10px",
        },
      });
      return; // Exit the function early
    }
    // If the ID is not in the cart, add it
    const payload = {
      cart: [
        ...user.cart,
        {
          VendorID: e.userID,
          ID: e._id,
          courseCategory: e.courseCategory,
          imageUrl: e.imageUrl,
          mainCategory: e.mainCategory,
          subCategory: e.subCategory,
          postDesc: e.postMessage,
          postName: e.postName,
          Price: e.price,
          tags: e.tags,
          videoUrl: e.videoUrl,
          zipUrl: e.zipUrl,
          timestamp: Date.now(),
        },
      ],
    };

    axios
      .patch(`https://smartuter.com/api/enduser/edit/${id}`, payload)
      .then((res) => {
        if (res.data.msg === true) {
          toast.success("Course Added Successfully to the Cart");
          getUserData(token);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (!courses) {
      fetchDataCourse();
      window.scrollTo(0, 0);
    }
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
        },
      },
    ],
  };
  if (!courses) {
    return <SkelitonCourses />;
  }
  return (
    <section className="MostPopularCourses-component pb-5 mb-3">
      <div className="header-text-component">
        <h2 className="sectionTitle mt-5">Our Most Popular Courses</h2>
        <p className="sectionTitle-text">
          10,000+ unique online course list designs
        </p>
        <button
          className="popular-course-button"
          // to={"/coursepage"}
          onClick={() => setCategoryFilter("")}
        >
          All Courses
        </button>
      </div>
      <div className="MostPopularCoursesCategoriesComponent">
        <div className="CoursesCategories">
          <Slider {...settings}>
            {allMainCategory &&
              allMainCategory.map((item, index) => (
                <div key={index} className="category">
                  <span
                    className={
                      activeCategory === encodeCategoryForURL(item.category)
                        ? "course_active"
                        : ""
                    }
                    onClick={() =>
                      handleCategory(encodeCategoryForURL(item.category))
                    }
                  >
                    {item.category}
                  </span>
                </div>
              ))}
          </Slider>
        </div>
      </div>

      <div className="CoursesContainer">
        {(CategoryCourses
          ? CategoryCourses.slice(0, 4)
          : courses !== null
          ? courses.slice(0, 4)
          : []
        ).map((item, index) => {
          return (
            
            <div key={index} className="CourseCard">
              <div className="ImageAndTitle">
                <img
                  className="courseImg"
                  alt="course-img"
                  src={item.imageUrl}
                  onClick={() => navigate(`/course/${item._id}`)}
                />
              </div>
              <div className="CourseTitle-border">
                <div>
                  <NavLink
                    to={`/course/${item._id}`}
                    onClick={() => setSingleCourseId(item._id)}
                    className="CourseTitle"
                  >
                    {/* {truncateText(item.postName,3)} */}
                    {item.postName.substring(0, 25)+"..."}

                  </NavLink>
                </div>
                {/* <div className="TagsContainer">
                  {item.tags.slice(0, 3).map((tag, tagindex) => (
                    <span key={tagindex} className="tag">
                      #{tag.text}
                    </span>
                  ))}
                </div> */}


                <div className="TagsContainer">
                  {item.tags.slice(0, 3).map((tag, tagindex) => (
                    <span key={tagindex} className="tag">
                      {tag.text}
                    </span>
                  ))}
</div>









                <div className="CourseAddtoCart">
                  {user &&
                    user.cart &&
                    user.orders &&
                    (user.cart.some((cartItem) => cartItem.ID === item._id) ? (
                      <Link to={"/cart"}>
                        <button className="disableBTN">Already In Cart</button>
                      </Link>
                    ) : user.orders.some(
                        (orderItem) => orderItem.ID === item._id
                      ) ? (
                      <Link to={"/order"}>
                        <button className="disableBTN">
                          Please Go to Orders
                        </button>
                      </Link>
                    ) : (
                      <button
                        className="enableBTN"
                        onClick={() => handleAddToCart(item)}
                      >
                        Add To Cart
                      </button>
                    ))}
                  {!user && (
                    <button
                      className="enableBTN"
                      onClick={() => (
                        navigate("/login"), handleAddToCart(item)
                      )}
                    >
                      Add To Cart
                    </button>
                  )}
                </div>
              </div>
              <div className="AuthorAndPrice">
                {/* <div className="Author">
                  <img
                    className="AuthorImage"
                    src={item.SubAdminProfileUrl}
                    alt="vendor"
                  />
                  <p className="AuthorName">{item.SubAdminName}</p>
                </div> */}

                {/* <div className="CoursePrice">
                  <p>₹{item.price}</p>
                </div> */}
                <div className="CoursePrice">
                  <p>Price: <span className="card-Price">₹{item.price}</span></p>
                </div>
              </div>
            </div>
          );
        })}
        {CategoryCourses && CategoryCourses.length === 0 && (
          <div className="CourseCard noimage-course">
            <div className="ImageAndTitle">
              <img className="courseImg" alt="course-img" src={NoData} />
            </div>
            <div className="CourseTitle-border">
              <div>No_Course_Found_This_Category</div>
            </div>
          </div>
        )}
        <div className="Home_All_course_link">
          <div className="Learn-from-anywhere">
            <h1>
              Learn From <span>Anywhere</span>
            </h1>
            <p>
              Take classes on the go with the Smartuter app. Stream or download
              to watch on the plane, the subway, or wherever you learn best.
            </p>
          </div>
          <Link to={"/coursepage"} onClick={() => setCategoryFilter("")}>
            <span className="home-all-courses"> View All</span>
          </Link>
        </div>
      </div>
      {/* <button>allcourses</button> */}
    </section>
  );
};

export default Course;
