import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const userAuthContext = createContext();

const UserAuthContextProvider = ({ children }) => {
  const [socket, updateSocket] = useState("");
  const navigate = useNavigate();
  const TOKEN_KEY = "endusertoken";
  const token = Cookies.get(TOKEN_KEY);

  const [user, setUser] = useState(null);
  const [courses, setCourses] = useState(null);
  const [searchCourse, setSearchCourse] = useState("");
  const [CategoryCourses, setFilterCourses] = useState(null);
  const [loading, setLoading] = useState(true);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [subCategoryFilter, setSubCategoryFilterCourses] = useState("");

  const [searchText, setSearchText] = useState("");
  const [singleCourseId, setSingleCourseId] = useState("");
  const [singelCourse, setSingleCourse] = useState("");

  const [allMainCategory, setMainCategoryes] = useState(null);
  const [allSubCategory, setSubCategoryes] = useState(null);
  const [allCourseCategory, setCourseCategoryes] = useState(null);

  // stydymaterila
  const [StudyMatrials, setStudyMaterial] = useState(null);
  const [searchStudymaterial, setSearchStudymaterial] = useState("");
  const [categoryFilterstudymaterial, setCategoryFilterstudymaterial] =
    useState("");
  const [CategoryStudymaterial, setFilterStudymaterial] = useState(null);
  const [singleStudyMaterialId, setSingleStudyMaterialId] = useState("");
  const [singelStudyMaterial, setSingleStudyMaterial] = useState("");

  //Projects
  const [Projects, setProjects] = useState(null);
  const [searchProject, setSearchProject] = useState("");
  // console.log(searchProject);
  const [categoryFilterProject, setCategoryFiltersproject] = useState("");
  const [CategoryProject, setFilterProjects] = useState(null);
  const [singleProjectId, setSingleProjectId] = useState("");
  const [singleProject, setSingleProject] = useState("");
  //search
  const [searchData, setSearchData] = useState(null);

  //SUBADMIN
  const [subAdmins, setSubAdmins] = useState(null);
  const [singleSubAdmin, setSingleSubAdmin] = useState(null);
  const [singleSubAdminCourse, setSingleSubAdminCourse] = useState(null);
  const [singleSubAdminProject, setSingleSubAdminProject] = useState(null);
  const [singleSubAdminStudymaterial, setSingleSubAdminStudymaterial] =
    useState(null);
  //VENDORS FILTE AN SEARCH STATES
  const [FilterAuth, setFilterAuth] = useState("");
  const [searchQuary, setSearchQuary] = useState("");
  const [orderFilter, setOrderFilte] = useState(-1);
  const [referralNotification, setReferralNotification] = useState(null);

  //handleToTop
  const handleScroolTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  //Pagination
  const R = "ARRE USE AUTH";
  const getUserData = (token) => {
    fetch("https://smartuter.com/api/enduser/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setUser(res.user);
      })
      .catch((err) => console.log(err));
  };
  const getMainCategory = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://smartuter.com/api/category/main`
      );
      setMainCategoryes(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getSubCategory = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://smartuter.com/api/category/sub`
      );
      setSubCategoryes(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getCourseCategory = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://smartuter.com/api/category/course`
      );
      setCourseCategoryes(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //courses

  const fetchDataCourse = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://smartuter.com/api/enduser/courses?category=${categoryFilter}&subCategory=${subCategoryFilter}&search=${searchCourse}`
      );
      setCourses(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchDataCourseCategorys = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://smartuter.com/api/enduser/courses?category=${categoryFilter}&search=${searchCourse}`
      );

      setFilterCourses(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchDataCoursID = async () => {
    if (!singleCourseId) return;

    try {
      setLoading(true);
      const response = await axios.get(
        `https://smartuter.com/api/enduser/courses?id=${singleCourseId}`
      );

      setSingleCourse(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // StudyMaterial
  const fetchDataStudyMaterial = async (page) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://smartuter.com/api/enduser/studymaterials`,
        {
          params: {
            category: categoryFilterstudymaterial,
            page,
            limit: 6,
            search: searchStudymaterial,
          },
        }

        // `https://smartuter.com/api/enduser/studymaterials?category=${categoryFilterstudymaterial}`
      );
      setStudyMaterial(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchDataStudyMaterialCategorys = async (page) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://smartuter.com/api/enduser/studymaterials`,
        {
          params: {
            category: categoryFilterstudymaterial,
            page,
            limit: 6,
          },
        }
      );
      //   console.log(`https://smartuter.com/api/enduser/courses?category=${categoryFilter}&search=${searchText}`)

      setFilterStudymaterial(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataStudyMaterialID = async () => {
    if (!singleStudyMaterialId) return;

    try {
      setLoading(true);
      const response = await axios.get(
        `https://smartuter.com/api/enduser/studymaterials?id=${singleStudyMaterialId}`
      );
      // console.log(`https://smartuter.com/api/enduser/courses?category=${categoryFilter}&search=${searchText}`)

      setSingleStudyMaterial(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //Projects
  const fetchDataProject = async (page) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://smartuter.com/api/enduser/projects`,
        {
          params: {
            category: categoryFilterProject,
            page,
            limit: 6,
            search: searchProject,
          },
        }
      );

      setProjects(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchDataProjectCategorys = async (page) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://smartuter.com/api/enduser/projects`,
        {
          params: {
            category: categoryFilterProject,
           
            search: searchProject,
          },
        }
      );
      //   console.log(`https://smartuter.com/api/enduser/projects?category=${categoryFilter}&search=${searchText}`)

      setFilterProjects(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchDataProjectID = async () => {
    if (!singleProjectId) return;
    try {
      setLoading(true);
      const response = await axios.get(
        `https://smartuter.com/api/enduser/projects?id=${singleProjectId}`
      );
      // console.log(`https://smartuter.com/api/enduser/Projectes?category=${categoryFilter}&search=${searchText}`)

      setSingleProject(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataSearchInput = async () => {
    try {
      setLoading(true);
      const [courseResponse, projectResponse] = await Promise.all([
        axios.get(
          `https://smartuter.com/api/enduser/courses?search=${searchText}`
        ),
        axios.get(
          `https://smartuter.com/api/enduser/projects?search=${searchText}`
        ),
      ]);

      const mergedData = {
        courses: courseResponse.data,
        projects: projectResponse.data.Projects,
      };

      setSearchData(mergedData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //TIMESTAMP
  function timestampToDateTime(timestamp) {
    // Create a new Date object with the timestamp
    const date = new Date(timestamp);

    // Extract individual components of the date
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-based, so we add 1
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Format the date as a string
    const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;
    const formattedTime = `${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }:${seconds < 10 ? "0" + seconds : seconds}`;

    // Combine date and time
    const dateTime = `${formattedDate} ${formattedTime}`;

    return dateTime;
  }
  //SubAdmin
  const getSubAdmins = async () => {
    try {
      // setLoading(true);
      const response = await axios.get(
        `https://smartuter.com/api/enduser/all-sub-admin?filter=${FilterAuth}&search=${searchQuary}&sortOrder=${orderFilter}`
      );
      setSubAdmins(response.data);
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getSingelSubAdmin = async (userID) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://smartuter.com/api/enduser/subadmin/${userID}`
      );
      setSingleSubAdmin(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getSingelSubAdminCourse = async (userID) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://smartuter.com/api/enduser/subadmin/getpost/${userID}`
      );
      setSingleSubAdminCourse(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getSingelSubAdminProject = async (userID) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://smartuter.com/api/enduser/subadmin/getproject/${userID}`
      );
      setSingleSubAdminProject(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getSingelSubAdminStudymaterial = async (userID) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://smartuter.com/api/enduser/subadmin/getstudymaterial/${userID}`
      );
      setSingleSubAdminStudymaterial(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // Function to handle the selection change
  const handleSelectFilterChange = (event) => {
    setFilterAuth(event.target.value);
  };
  //Function Handle Search Changes
  const handleSearchVendorNameChange = (e) => {
    setSearchQuary(e.target.value);
  };
  //Function  Handle By ORDER
  const handleOrderFilter = (e) => {
    setOrderFilte(e.target.value);
  };

  const fetchUserReferralNotifications = async () => {
    try {
      const response = await axios.get(
        "https://smartuter.com/api/notification/getuserreferralnotificationcontroller"
      );
      // const referralNotificationArray = []
      const referralNotifyArray = response.data.getNotificationData.map(
        (item) => item.data.referralUserId
      );
      // );
      // console.log(referralNotifyArray);
      setReferralNotification(referralNotifyArray);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      return null;
    }
  };
  // fetchUserReferralNotifications()
  useEffect(() => {
    getMainCategory();
    getSubCategory();
    getCourseCategory();
    //course
    fetchDataCourse();
    //studymaterial
    fetchDataStudyMaterial();
    //project
    fetchDataProject();
    //Subadmin
    getSubAdmins();
    // getSingelSubAdmin();
    if (token) {
      getUserData(token);
    }
  }, [token, socket]);
  useEffect(() => {
    fetchDataCourseCategorys();
    fetchDataCoursID();
  }, [categoryFilter, singleCourseId, socket]);

  useEffect(() => {
    fetchDataProjectCategorys();
    fetchDataProjectID();
  }, [categoryFilterProject, singleProjectId, socket]);
  useEffect(() => {
    fetchDataStudyMaterialCategorys();
    fetchDataStudyMaterialID();
  }, [categoryFilterstudymaterial, singleStudyMaterialId, socket]);

  // useEffect(() => {
  //   fetchDataSearchInput();
  // }, [searchText, socket]);
  return (
    <userAuthContext.Provider
      value={{
        TOKEN_KEY,
        fetchDataSearchInput,
        navigate,
        socket,
        updateSocket,
        R,
        token,
        fetchDataCourse,
        courses,
        loading,
        categoryFilter,
        setCategoryFilter,
        searchText,
        setSearchText,
        searchData,
        // catagorys
        getMainCategory,
        getSubCategory,
        getCourseCategory,
        allMainCategory,
        allSubCategory,
        allCourseCategory,
        //Course
        searchCourse,
        setSearchCourse,
        CategoryCourses,
        singleCourseId,
        setSingleCourseId,
        singelCourse,
        subCategoryFilter,
        setSubCategoryFilterCourses,
        //User
        getUserData,
        user,
        setUser,

        //StudyMaterials
        StudyMatrials,
        searchStudymaterial,
        setSearchStudymaterial,
        fetchDataStudyMaterial,
        fetchDataStudyMaterialCategorys,
        fetchDataStudyMaterialID,
        setCategoryFilterstudymaterial,
        categoryFilterstudymaterial,
        CategoryStudymaterial,
        setSingleStudyMaterialId,
        singelStudyMaterial,
        setSingleStudyMaterial,

        //Porjects
        Projects,
        fetchDataProject,
        searchProject,
        setSearchProject,
        fetchDataProjectCategorys,
        fetchDataProjectID,
        setCategoryFiltersproject,
        categoryFilterProject,
        CategoryProject,
        setSingleProjectId,
        singleProject,
        setSingleProject,

        //TIMESTAMP
        timestampToDateTime,
        //SUBADMIN
        getSubAdmins,
        subAdmins,
        getSingelSubAdmin,
        singleSubAdmin,
        setFilterAuth,
        setSearchQuary,
        setOrderFilte,
        searchQuary,
        orderFilter,
        handleSearchVendorNameChange,
        handleOrderFilter,
        handleSelectFilterChange,
        //SUBADMINCOURSE
        getSingelSubAdminCourse,
        setSingleSubAdminCourse,
        singleSubAdminCourse,
        //SUBADMINPROJECT
        getSingelSubAdminProject,
        setSingleSubAdminProject,
        singleSubAdminProject,
        //SUBADMIN STUDYMATERIAL
        getSingelSubAdminStudymaterial,
        setSingleSubAdminStudymaterial,
        singleSubAdminStudymaterial,
        //handletotop
        handleScroolTop,
        //Referal Tree
        referralNotification,
        fetchUserReferralNotifications,
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
};

const useUserAuth = () => {
  return useContext(userAuthContext);
};

export { UserAuthContextProvider, useUserAuth };
