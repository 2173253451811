import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../Context/UseAuthContext";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import "./singlestudymaterial.css";
import { BiLogoGmail } from "react-icons/bi";
import { FaRegClipboard, FaWhatsapp } from "react-icons/fa6";
import toast from "react-hot-toast";
import { IoMdShareAlt } from "react-icons/io";
const SingleStudymaterial = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleParagraph = () => {
    setIsExpanded(!isExpanded);
  };
  const defaultImageUrl =
    "https://iconicentertainment.in/wp-content/uploads/2013/11/dummy-image-square.jpg";
  const {
    singelStudyMaterial,
    setSingleStudyMaterialId,
    user,
    getUserData,
    token,
    navigate,
  } = useUserAuth();
  const param = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const url = new URL(window.location.href);

  // Get the value of the 'referalcode' query parameter
  const referralCode = url.searchParams.get("referalcode");
  // Get the value of the 'referalcode' query parameter
  const referalLink = `${window.location.pathname}?referalcode=${referralCode}`;
  const referredLink = localStorage.setItem("referredLink", referalLink);
  // Log or use the referral code
  const handleShare = () => {
    if (!token) {
      toast.error("Please Login to your Account");
      return;
    }
    const referalcode = localStorage.getItem("referralCode");
    const url = window.location.href;
    const urlwithoutParams = url.split("?")[0];

    const referalLink = `${urlwithoutParams}?referalcode=${referalcode}`;

    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const shareOnWhatsApp = () => {
    const referalcode = localStorage.getItem("referralCode");
    const url = window.location.href;
    const urlwithoutParams = url.split("?")[0];
    const referalLink = `${urlwithoutParams}?referalcode=${referalcode}`; // Replace with your actual referral link
    const message = `Hey there, I'm using WhatsApp! Check this out This is course which i have used in smartuter: ${referalLink}`;
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      message
    )}`;

    window.open(whatsappUrl, "_blank");
  };

  const copyToClipboard = () => {
    const referalcode = localStorage.getItem("referralCode");
    const url = window.location.href;
    const urlwithoutParams = url.split("?")[0];

    const referalLink = `${urlwithoutParams}?referalcode=${referalcode}`;
    navigator.clipboard.writeText(referalLink).then(
      () => {
        toast.success("Referral link copied to clipboard!");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const shareViaGmail = () => {
    const referalcode = localStorage.getItem("referralCode");
    const url = window.location.href;
    const urlwithoutParams = url.split("?")[0];

    const referalLink = `${urlwithoutParams}?referalcode=${referalcode}`;
    const subject = "Check out this referral link!";
    const body = `Hi, check out this referral link: ${referalLink}`;
    const gmailUrl = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = gmailUrl;
  };

  const handleAddToCart = (e) => {
    if (!token) {
      toast.error("Please Login to your Account");
      return;
    }
    const referalcode = localStorage.getItem("referralCode");
    const url = window.location.href;

    const test = url.split("=");

    if (referalcode === test[1]) {
      const url = window.location.href;
      window.location.href = url.split("?")[0];
    }

    const id = user._id;

    // Check if the ID is already in the cart
    const isAlreadyInCart = user.cart.some((item) => item.ID === e._id);

    // If the ID is already in the cart, do not add it again
    if (isAlreadyInCart) {
      toast("Item is already in the cart.", {
        icon: "ℹ️",
        style: {
          borderRadius: "10px",
          background: "#blue",
          color: "#222",
          padding: "10px",
        },
      });

      return; // Exit the function early
    }
    // If the ID is not in the cart, add it
    const payload = {
      cart: [
        ...user.cart,
        {
          VendorID: e.userID,
          ID: e._id,
          courseCategory: e.MaterialCategory,
          imageUrl: e.MaterialImage,
          // mainCategory: e.mainCategory,
          // subCategory: e.subCategory,
          postDesc: e.MaterialDescription,
          postName: e.MaterialName,
          Price: e.Price,
          // tags: e.tags,
          // videoUrl: e.videoUrl,
          zipUrl: e.MaterialZip,
          timestamp: Date.now(),
          referralCode: referralCode,
        },
      ],
    };

    axios
      .patch(`https://smartuter.com/api/enduser/edit/${id}`, payload)
      .then((res) => {
        if (res.data.msg === true) {
          toast.success("StudyMaterial Added Successfully to the Cart");
          getUserData(token);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    setSingleStudyMaterialId(param.studymaterialID);
  }, []);
  return (
    <div className="">
      
      {/* <h1>SingleStudymaterial</h1> */}
      {singelStudyMaterial && (
        <div className="head">
          {/* <h1>{singelStudyMaterial._id}</h1> */}


          {/* <div className="s_s_m_content_two">
            <h1>
              <strong>{singelStudyMaterial.MaterialName}</strong>
            </h1>
            <p className={isExpanded ? "full" : "truncated"}>
              {singelStudyMaterial.MaterialDescription}
            </p>
            <p style={{ color: "blue" }} onClick={toggleParagraph}>
              {isExpanded ? "Show Less" : "Read More..."}
            </p>
            <span>{singelStudyMaterial.MaterialCategory}</span>


          </div> */}
          <div className="acordiance-course-study">
          <span className="tech">{singelStudyMaterial.MaterialCategory}</span>

          <div className="accordion" id="accordionExample">
  <div className="accordion-item">
    {/* <h2 className="accordion-header">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        Accordion Item #1
      </button>
    </h2> */}
    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <h3>
              <strong>{singelStudyMaterial.MaterialName}</strong>
            </h3>  
            
            <p className={isExpanded ? "full" : "truncated"}>
              {singelStudyMaterial.MaterialDescription}
            </p>
            <p style={{ color: "blue" }} onClick={toggleParagraph}>
              {isExpanded ? "Show Less" : "Read More..."}
            </p>
            
                </div>
    </div>
  </div>
  {/* <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        Accordion Item #2
      </button>
    </h2>
    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div> */}
  
</div>

          </div>


        




















          <div className="card-course1-study">
            <img src={singelStudyMaterial.MaterialImage} alt="Img" />
            {/* <p>
              <strong>₹</strong>
              {singelStudyMaterial.Price}
            </p> */}

            <div className="price p-2">
                <p className="price1">
                  <span>Title</span>: {singelStudyMaterial.MaterialName}
                </p>

                <p className="price1 ">
                  <span>Price</span>: ₹ {singelStudyMaterial.Price}

                </p>
                {/* <h1 className="price2">199</h1> */}
              </div>


            <div className="card-button studycard-new">
              <button
                onClick={() => handleShare()}
                className=""
              >
                  <IoMdShareAlt />
              </button>
              {isModalOpen && (
                <div className="modal">
                  <div className="modal-content">
                    <span className="close" onClick={closeModal}>
                      &times;
                    </span>
                    <h2>Share Via</h2>
                    <div>
                      <FaWhatsapp
                        className="share-logo whatsapp"
                        onClick={shareOnWhatsApp}
                      />
                      <FaRegClipboard
                        className="share-logo"
                        onClick={copyToClipboard}
                      />
                      <BiLogoGmail
                        className="share-logo gmail"
                        onClick={shareViaGmail}
                      />
                    </div>
                  </div>
                </div>
              )}
              {user &&
                user.cart &&
                user.orders &&
                (user.cart.some(
                  (cartItem) => cartItem.ID === singelStudyMaterial._id
                ) ? (
                  <Link to={"/cart"}>
                    <button className="disableBTN">Already In Cart</button>
                  </Link>
                ) : user.orders.some(
                    (orderItem) => orderItem.ID === singelStudyMaterial._id
                  ) ? (
                  <Link to={"/order"}>
                    <button className="disableBTN">Please Go to Orders</button>
                  </Link>
                ) : (
                  <button
                    className="enableBTN"
                    onClick={() => handleAddToCart(singelStudyMaterial)}
                  >
                    Add To Cart
                  </button>
                ))}
              {!user && (
                <button
                  className="enableBTN"
                  onClick={() => (
                    navigate("/login"), handleAddToCart(singelStudyMaterial)
                  )}
                >
                  Add To Cart
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleStudymaterial;
