import React from 'react'
import { Link } from 'react-router-dom';
import NoData from "../../../images/NoData.gif";


const VendorStudymaterial = ({studymaterial}) => {
  return (
    <div className="ven-cours-container">
      <h1 className="description">VendorStudymaterial</h1>
      {studymaterial && studymaterial.length > 0 ? (
        <div className="courses-container">
          {studymaterial.map((e, i) => (
            <div key={i} className="instructor-card-cour">
              <img
                src={e.MaterialImage}
                alt={`course${i}`}
                className="instructor-card-cour-img"
              />
              {/* <div className="overlay"></div> */}
              <div className="instructor-card-cour-des-c">
                <Link to={`/studymaterial/${e._id}`}>
                  <h1 className="instructor-card-cour-sub-h">
                    {e.MaterialName}
                  </h1>
                </Link>
                <p className="instructor-card-cour-des">{e.MaterialCategory}</p>

                <p className="instructor-card-cour-des">₹{e.Price}/-</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="no-course-found-container">
        <p className="no-course-found-text">
          Sorry..!, we couldn't find any result.
        </p>

        <img src={NoData} />
      </div>
      )}
    </div>
  );
}

export default VendorStudymaterial