import React from 'react';
import { Button, Container, Paper, Typography } from '@mui/material';
import BankDetailsDialog from './BankDetailsDialog';

const BankDetailsDisplay = ({handleOpenDialog,open,onClose,onSave,bankDetails}) => {


  return (
    <Container>
      <Button variant="contained" color="primary" onClick={handleOpenDialog}>
        Enter Bank Details
      </Button>
      <BankDetailsDialog open={open} onClose={onClose} onSave={onSave} />
      {bankDetails &&
        bankDetails.map((e, i) => (
          <Paper key={i} style={{ padding: 16, marginTop: 16 }}>
            <Typography variant="h6">Bank Details</Typography>
            <Typography
              variant="body1"
              sx={{ overflowX: "auto", whiteSpace: "nowrap" }}
            >
              Account Number: {e.accountNumber}
            </Typography>
            <Typography
              variant="body1"
              sx={{ overflowX: "auto", whiteSpace: "nowrap" }}
            >
              Bank Name: {e.bankName}
            </Typography>
            <Typography
              variant="body1"
              sx={{ overflowX: "auto", whiteSpace: "nowrap" }}
            >
              IFSC Code: {e.ifscCode}
            </Typography>
          </Paper>
        ))}
    </Container>
  );
};

export default BankDetailsDisplay;
