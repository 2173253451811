import React, { useState } from 'react';
import './faqAccordion.css';

const faqData = [
    {
        question: "What is the purpose of this website?",
        answer: "This website provides online study materials for students, offering a wide range of resources to enhance their learning experience.",
      },
      {
        question: "How can I access the study materials?",
        answer: "You can access the study materials by signing up and logging in to your account. Please note that access to the materials requires a subscription.",
      },
      {
        question: "Is there any cost to use the study materials?",
        answer: "Yes, there is a subscription fee to access the study materials. We offer various plans to suit different needs, which you can find on our pricing page.",
      },
      {
        question: "How can I contact support?",
        answer: "You can contact support through the 'Contact Us' page or by emailing info@smartuter.com Our support team is available 24/7 to assist you with any issues or inquiries.",
      },
      {
        question: "Can I contribute my own study materials?",
        answer: "Yes, you can contribute by submitting your materials through the 'Contribute' page after logging in. We review all submissions to ensure they meet our quality standards before making them available to other users.",
      },
    ];

const FaqAccordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className='faq-main-container'>
       
<img  src='https://img.freepik.com/free-vector/curiosity-people-concept-illustration_114360-11034.jpg?t=st=1722659114~exp=1722662714~hmac=ab2802903cc12fdfcd18378862c6a64dd6e1088e76d417510a6645ea85d1558b&w=740'/>
<div className="faq-container">
<h2 className='faq-head'>FAQ...?</h2>
      {faqData.map((item, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleAccordion(index)}>
            <span>{item.question}</span>
            <span className="faq-arrow">{activeIndex === index ? '▲' : '▼'}</span>
          </div>
          <div className={`faq-answer ${activeIndex === index ? 'open' : ''}`}>
            {item.answer}
          </div>
        </div>
      ))}
    </div>
    </div>
   
  );
};

export default FaqAccordion;
