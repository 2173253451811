import React from "react";
import { Route, Routes } from "react-router-dom";
import About from "../Pages/About";
import Contact from "../Pages/Contact";
import Course from "../Pages/Courses/Course";
import { Cart } from "../Pages/Cart/Cart";
import Login from "../Pages/Profile/Login";
import Register from "../Pages/Profile/Register";
import Search from "../Pages/Search/Search";
import Profile from "../Pages/Profile/Profile";
import Home from "../Pages/Home/Home";
import Project from "../Pages/Project/Project";
import StudyMaterial from "../Pages/StudyMaterila/StudyMaterial";
import CategoryCourse from "../Pages/Courses/CategoryCourse";
import SingleCourse from "../Pages/Courses/SingleCourse";
import Order from "../Pages/Order/Order";
import SingleStudymaterial from "../Pages/StudyMaterila/SingleStudymaterial";
import CategoryStudymaterial from "../Pages/StudyMaterila/CategoryStudymaterial";
import SingleProject from "../Pages/Project/SingleProject";
import CategoryProject from "../Pages/Project/CategoryProject";
import Vendor from "../Pages/Vendor/Vendro";
import SingleVendor from "../Pages/Vendor/SingleVendor/SingleVendor";
import AllVendor from "../Pages/Vendor/AllVendor/AllVendor";
import CoursePage from "../Pages/Courses/CoursePage";
import CancellationPolicy from "../Pages/Policys/CancellationPolicy";
import PrivacyPolicy from "../Pages/Policys/Privacypolicy";
import RefundPolicy from "../Pages/Policys/RefundPolicy";
import TermsAndConditions from "../Pages/Policys/Terms&Conditions";
import ReferralTree from "./Tree/Tree";
import Payment from "../Pages/payments/payments";
import PasswordReset from "../Pages/Profile/PasswordReset";
import ForgotPassword from "../Pages/Profile/ForgotPassword";
import PageNotFound from "./PageNotFound/PageNotFound";
import ShippingDelivery from "../Pages/Policys/Shipping&Delivery";

const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      {/* //coursers routes */}
      <Route
        path="/coursecatgory/:category/:courseID"
        element={<SingleCourse />}
      />
      <Route path="/coursecatgory/:category" element={<CategoryCourse />} />

      <Route path="/course/:courseID" element={<SingleCourse />} />
      <Route path="/course" element={<Course />} />
      <Route path="/coursepage" element={<CoursePage />} />
      {/* Project Routes */}
      <Route
        path="/projectcatgory/:category/:projectID"
        element={<SingleProject />}
      />
      <Route path="/projectcatgory/:category" element={<CategoryProject />} />

      <Route path="/project/:projectID" element={<SingleProject />} />
      <Route path="/project" element={<Project />} />

      {/* studymaterial routes */}

      <Route
        path="/studymaterialcatgory/:category/:studymaterialID"
        element={<SingleStudymaterial />}
      />
      <Route
        path="/studymaterialcatgory/:category"
        element={<CategoryStudymaterial />}
      />

      <Route
        path="/studymaterial/:studymaterialID"
        element={<SingleStudymaterial />}
      />
      <Route path="/studymaterial" element={<StudyMaterial />} />

      {/* VendroRoutes */}

      {/* <Route path="/homevendor" element={<Vendor />} />
      <Route path="/allvendor" element={<AllVendor />} />
      <Route path="/allvendor/:vendorID" element={<SingleVendor />} /> */}

      <Route path="/cart" element={<Cart />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/passwordreset" element={<PasswordReset />} />
      <Route path="/forgotpassword/:id/:token" element={<ForgotPassword />} />

      <Route path="/profile" element={<Profile />} />
      <Route path="/search" element={<Search />} />
      <Route path="/order" element={<Order />} />

      {/* Tree */}
      <Route path="/tree" element={<ReferralTree />} />
      {/* <Route path="/tree/:userId/:name" element={<ReferralTree />} /> */}

      {/* Policys */}
      <Route path="/cancellationpolicy" element={<CancellationPolicy />} />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      <Route path="/shipping" element={<ShippingDelivery />} />

      <Route path="/refundpolicy" element={<RefundPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

      <Route path="/payments" element={<Payment />} />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AllRoutes;
