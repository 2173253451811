// Category.js
import React from "react";

import "./category.css"; // Import your custom CSS
import Slider from "react-slick";
import { useUserAuth } from "../../../Context/UseAuthContext";
import { encodeCategoryForURL } from "../../../Component/EncodedUrlComponent";
import ScrollToTopLink from "../../ScroolToTop";
import { Link } from "react-router-dom";

const Category = () => {
  const {
    allMainCategory,
    setCategoryFilter,
  } = useUserAuth();

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="main_slider_catagory">
      <div className="main_slider_category_header">
        <h2>Top Categories</h2>
        <p>
          Most Popular Categories Across the Globe, Which Improve Your Career
          and Offer Lucrative Opportunities.
        </p>
      </div>
      <div className="category_slider_container">
        <Slider className="slider_cont" {...settings}>
          {allMainCategory &&
            allMainCategory.map((course, index) => (
              <Link
                key={index}
                to={`/coursecatgory/${encodeCategoryForURL(course.category)}`}
                onClick={() =>
                  setCategoryFilter(encodeCategoryForURL(course.category))
                }
              >
                <div key={index} className="category_slider_card">
                  <div className="category_slider_featured-icons">
                    <img
                      src={`https://avatar.iran.liara.run/username?username=${course.category}&size=100&bold=false`}
                      alt="icon"
                      className="icon"
                      onError={(e) => {
                        e.target.src =
                        `https://ui-avatars.com/api/?name=${course.category}`
                      }}
                    />
                  </div>
                  <p className="uppercase">{course.category}</p>
                </div>
              </Link>
            ))}
        </Slider>
      </div>
    </div>
  );
};

export default Category;
