import React from 'react';
import './SingleCourseBanner.css';

const SingleCourseBanner = () => {
  return (
    <div className="course-container">
      <div className="image-container">
        <img src="https://eduport.webestica.com/assets/images/element/category-1.svg" alt="Left" className="course-image" />
      </div>
      <div className="text-container">
        <h1>Suitable Online Course</h1>
        <p>This is a great course to enhance your skills and knowledge in the field. Join us to learn from the best!</p>
      </div>
      <div className="image-container">
        <img src="https://eduport.webestica.com/assets/images/element/category-2.svg" alt="Right" className="course-image" />
      </div>
    </div>
  );
};

export default SingleCourseBanner;
