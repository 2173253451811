import React, { useState } from "react";
import toast from "react-hot-toast";
import "./passwordReset.css";

const PasswordReset = () => {
  const [email, setEmail] = useState("");

  const setVal = (e) => {
    setEmail(e.target.value);
  };
  const sendLink = async () => {
    const res = await fetch(
      "https://smartuter.com/api/enduser/sendpasswordlink",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      }
    );

    const data = await res.json();
    if (data.status === 201) {
      setEmail("");
      toast.success("Email sent successfully");
    } else {
      toast.error("Email sent Error");
    }
  };
  return (
    <div className="main_login">
      <div className="login-container resetpassword">
        <h1>Password Reset</h1>
        <div className="input-group reset-input-group">
          <label>Email:</label>
          <input
            type="email"
            placeholder="ex:smartuter@gmail.com"
            value={email}
            onChange={setVal}
          />
        </div>
        <button onClick={sendLink}>Send</button>
      </div>
    </div>
  );
};

export default PasswordReset;
