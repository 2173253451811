import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../Context/UseAuthContext";
import { Link } from "react-router-dom";
import axios from "axios";
import { FaArrowAltCircleDown } from "react-icons/fa";
import NoData from "../../images/NoData.gif";

import { RiArrowDropUpLine } from "react-icons/ri";
import { RiArrowDropDownLine } from "react-icons/ri";
import "./studymaterials.css";
import { TfiArrowCircleLeft, TfiArrowCircleRight } from "react-icons/tfi";
import { encodeCategoryForURL } from "../../Component/EncodedUrlComponent";
import toast from "react-hot-toast";
import { truncateText } from "../trunacateText";
import { CiSearch } from "react-icons/ci";
import studymete from "../../images/studymete.gif";
import Waves from "../Home/waves/Waves";
import { Height } from "@mui/icons-material";

const StudyMaterial = () => {
  const {
    searchStudymaterial,
    setSearchStudymaterial,
    allMainCategory,
    StudyMatrials,
    fetchDataStudyMaterial,
    setSingleStudyMaterialId,
    user,
    getUserData,
    token,
    setCategoryFilterstudymaterial,
    categoryFilterstudymaterial,
    navigate,
  } = useUserAuth();
  const [isOpen, setIsOpen] = useState(true);

  const [showAllCategories, setShowAllCategories] = useState(null);
  const [checkedCategory, setCheckedCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(
    StudyMatrials && StudyMatrials.currentPage
  ); // Assuming initial page is 1
  const totalPages = StudyMatrials && StudyMatrials.totalPages; // Total number of pages// Number of page numbers to display
  const pageNeighbours = 2; // Number of page numbers to show before and after the current page
  const startPage = Math.max(1, currentPage - pageNeighbours);
  const endPage = Math.min(totalPages, currentPage + pageNeighbours);
  const pagesToShow = [];

  const setPage = (page) => {
    setCurrentPage(page);
  };

  const toggleShowAllCategories = (data) => {
    if (showAllCategories !== null) {
      return setShowAllCategories(null);
    }
    setShowAllCategories(data);
  };

  const toggleCoursesAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleAddToCart = (e) => {
    if (!token) {
      toast.error("Please Login to your Account");
      return;
    }
    const id = user._id;

    // Check if the ID is already in the cart
    const isAlreadyInCart = user.cart.some((item) => item.ID === e._id);

    // If the ID is already in the cart, do not add it again
    if (isAlreadyInCart) {
      toast("Item is already in the cart.", {
        icon: "ℹ️",
        style: {
          borderRadius: "10px",
          background: "#blue",
          color: "#222",
          padding: "10px",
        },
      });

      return; // Exit the function early
    }
    // If the ID is not in the cart, add it
    const payload = {
      cart: [
        ...user.cart,
        {
          VendorID: e.userID,
          ID: e._id,
          courseCategory: e.MaterialCategory,
          imageUrl: e.MaterialImage,
          // mainCategory: e.mainCategory,
          // subCategory: e.subCategory,
          postDesc: e.MaterialDescription,
          postName: e.MaterialName,
          Price: e.Price,
          zipUrl: e.MaterialZip,
          timestamp: Date.now(),
        },
      ],
    };

    axios
      .patch(`https://smartuter.com/api/enduser/edit/${id}`, payload)
      .then((res) => {
        if (res.data.msg === true) {
          toast.success("StudyMaterial Added Successfully to the Cart");
          getUserData(token);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchDataStudyMaterial(currentPage);
  }, [currentPage, categoryFilterstudymaterial, searchStudymaterial]);
  const handleCheckboxChange = (e, category) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      // Checkbox is checked, do something with the value (category)

      setPage(1);
      setCategoryFilterstudymaterial(encodeCategoryForURL(category));
      setCheckedCategory(category);
    } else if (checkedCategory === category) {
      setCheckedCategory(null);
      setCategoryFilterstudymaterial("");
      fetchDataStudyMaterial();
    } else {
      // Checkbox is unchecked
    }
  };
  //MUlti Categories selections

  const [materials, setMaterials] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    if (selectedCategories.length > 0) {
      fetchMaterials();
    } else {
      setMaterials([]);
    }
  }, [selectedCategories]);

  const fetchMaterials = async () => {
    try {
      const response = await axios.get(
        "https://smartuter.com/api/enduser/multiStudyMaterials",
        {
          params: { categories: selectedCategories.join(",") },
        }
      );
      setMaterials(response.data);
    } catch (error) {
      console.error("Error fetching materials:", error);
    }
  };

  const handleCategoryChange = (category) => {
    setSelectedCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
  };
  console.log(materials && materials);
  for (let i = startPage; i <= endPage; i++) {
    pagesToShow.push(i);
  }

  return (
    <div className="studymaterial-section-card-container">
      {/* <h1 className="studymaterial-section-card-head">ALL STUDYMATERIALS</h1>
      <p className="studymaterial-section-card-para">
        Write an introductory description of the category.
      </p> */}

      <div className="all-courses-main">
        <div>
          <h3 className="course-section-card-head">ALL STUDYMATERIALS</h3>
          <p className="course-section-card-para">
            Write an introductory description of the category.
          </p>

          <div className="header-navitems">
            <Link to={"/"}>
              <span className="header-links">Home</span>
            </Link>
            <span className="mx-1">{">"}</span>
            <Link to={"/studymaterial"}>
              <span className="header-links underline-headerlink">
                Studymaterial
              </span>
            </Link>
          </div>
        </div>
        <img src={studymete} />
      </div>

      {/* <Waves /> */}
      <div className="studymaterial-section-all-card">
        <div className="studymaterial-section-category-container">
          {/* Dropdonw Categori Start here */}

          <div
            className={`studymaterial-section-each-category ${
              isOpen ? "open" : ""
            }`}
            onClick={toggleCoursesAccordion}
          >
            <div className="studymaterial-section-each-category-title">
              Main Category
            </div>
            <div className="studymaterial-section-each-category-arrow">
              {isOpen ? (
                <RiArrowDropUpLine className="up-arrow" />
              ) : (
                <RiArrowDropDownLine className="down-arrow" />
              )}
            </div>
          </div>
          {isOpen && (
            <div>
              {allMainCategory &&
                allMainCategory
                  .slice(
                    0,
                    showAllCategories === "category"
                      ? allMainCategory.length
                      : 3
                  )
                  .map((cat, i) => (
                    <label
                      key={i}
                      className="studymaterial-section-category-checkbox-container"
                    >
                      <input
                        type="checkbox"
                        value={cat.category}
                        onChange={() => handleCategoryChange(cat.category)}
                      />
                      <p className="studymaterial-section-category-checkbox-para">
                        {cat.category}
                      </p>
                    </label>
                  ))}
              {(allMainCategory && allMainCategory.length) > 3 && (
                <button
                  style={{ color: "blue" }}
                  onClick={() => toggleShowAllCategories("category")}
                  className="study-material-show-more"
                >
                  {showAllCategories === "category" ? "Show Less" : "Show More"}
                </button>
              )}
            </div>
          )}

          <hr />
          {/* <div className="studymaterial-section-each-category-title">
              Main Category
            </div>
          <div>
                {allMainCategory&&allMainCategory.map((category,i) => (
                    <label 
                    className="studymaterial-section-category-checkbox-container"
                    
                    key={i}>
                        <input
                            type="checkbox"
                            value={category.category}
                            onChange={() => handleCategoryChange(category.category)}
                        />
                         <p className="studymaterial-section-category-checkbox-para">
                        {category.category}
                      </p>
                    </label>
                ))}
            </div> */}
          {/* Dropdonw Categori end here */}
        </div>
        <div className="studymaterial-section-display-container">
          <div className="studymaterial-section-display-filter">
            {/* <input
              type="search"
              value={searchStudymaterial}
              onChange={(e) => setSearchStudymaterial(e.target.value)}
              placeholder="search..."
            /> */}

            <div className="course-section-search">
              <div className="input-container">
                <CiSearch className="search-icon" />
                <input
                  type="search"
                  value={searchStudymaterial}
                  onChange={(e) => setSearchStudymaterial(e.target.value)}
                  placeholder="Search Projects..."
                  className="courses-search"
                />
              </div>
            </div>

            <p className="studymaterial-section-filter-para">
              Showing{" "}
              {materials && materials.length > 0
                ? materials.length
                : StudyMatrials && StudyMatrials.approvedMaterials
                ? StudyMatrials.approvedMaterials.length
                : 0}{" "}
              total results
            </p>
          </div>

          <div className="studymaterial-section-display-cards-container">
            {(materials && materials.length > 0
              ? materials
              : StudyMatrials && StudyMatrials.approvedMaterials
              ? StudyMatrials.approvedMaterials
              :[]
            ).map((course, index) => (
              <div
                className="studymaterial-section-display-each-card"
                key={course._id}
              >
                <Link
                  to={`/studymaterial/${course._id}`}
                  onClick={() => setSingleStudyMaterialId(course._id)}
                  className="studymaterial-section-link-item"
                >
                  <div className="studymaterial-section-display-card">
                    <img
                      src={course.MaterialImage}
                      // src="https://img.freepik.com/free-photo/front-view-male-student-wearing-black-backpack-holding-copybooks-files-blue-wall_140725-42636.jpg?t=st=1722399613~exp=1722403213~hmac=fe9d76e6d5bd29b4e266d156ee3108f7fafa9deb988b4627877893313f0fc980&w=900"
                      className="studymaterial-section-display-card-img"
                      alt={course.MaterialName}
                    />
                    <div className="studymaterial-section-display-card-body">
                      <div className="studymaterial-section-display-card-body-titleAndPrice">
                        <h6 className="CourseTitle">
                          {/* {truncateText(course.MaterialName,4)} */}
                          {course.MaterialName.substring(0, 30) + "..."}
                        </h6>
                      </div>
                      <button className="studymaterial-section-display-card-body-category">
                        {" "}
                        {course.MaterialCategory}
                      </button>
                      {/* <div className="studymaterial-section-display-card-body-auth-container"> */}
                      {/* <div className="studymaterial-section-auth-container">
                            <img
                              className="studymaterial-section-display-card-body-auth-img"
                              src={course.SubAdminProfileUrl}
                              alt={course.SubAdminName}
                            />{" "}
                            <h1 className="studymaterial-section-display-card-body-auth-name">
                              {course.SubAdminName}
                            </h1>
                          </div> */}

                      {/* <h1 className="studymaterial-section-display-card-body-price">
                           ₹
                            <span style={{ color: "green", margin: "0px" }}>
                              {course.Price}
                            </span>
                          </h1> */}
                      {/* </div> */}
                    </div>
                  </div>
                </Link>

                <div className="CourseAddtoCart">
                  {user &&
                    user.cart &&
                    user.orders &&
                    (user.cart.some(
                      (cartItem) => cartItem.ID === course._id
                    ) ? (
                      <Link to={"/cart"}>
                        <button className="disableBTN">Already In Cart</button>
                      </Link>
                    ) : user.orders.some(
                        (orderItem) => orderItem.ID === course._id
                      ) ? (
                      <Link to={"/order"}>
                        <button className="disableBTN">
                          Please Go to Orders
                        </button>
                      </Link>
                    ) : (
                      <button
                        className="enableBTN"
                        onClick={() => handleAddToCart(course)}
                      >
                        Add To Cart
                      </button>
                    ))}
                  {!user && (
                    <button
                      className="enableBTN"
                      onClick={() => (
                        navigate("/login"), handleAddToCart(course)
                      )}
                    >
                      Add To Cart
                    </button>
                  )}
                </div>

                {/* <h1 className="studymaterial-section-display-card-body-price">
                          ₹
                          <span style={{ color: "green", margin: "0px" }}>
                            {course.Price}
                          </span>
                        </h1> */}
                <div className="CoursePrice">
                  <p>
                    Price: <span className="card-Price">₹{course.Price}</span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Pagination code  */}
      {/* <div className="pagination">
        <button onClick={prevPage} disabled={page === 1}>
          Previous
        </button>

        {Array.from(
          { length: 100},
          (_, i) => (
            <button
              key={i + 1}
              onClick={() => setPage(i + 1)}
              className={
                StudyMatrials && StudyMatrials.currentPage === i + 1
                  ? "active"
                  : ""
              }
            >
              {i + 1}
            </button>
          )
        )}
        <button
          onClick={nextPage}
          disabled={
            StudyMatrials &&
            StudyMatrials.currentPage === StudyMatrials.totalPages
          }
        >
          Next
        </button>
      </div> */}
      <div className="pagination">
        {currentPage > 1 && (
          <button onClick={() => setPage(currentPage - 1)}>
            <TfiArrowCircleLeft className="prev_page" />
          </button>
        )}
        {startPage > 1 && (
          <>
            <button onClick={() => setPage(1)}>1</button>
            {startPage > 2 && <span>...</span>}
          </>
        )}
        {pagesToShow.map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => setPage(pageNumber)}
            className={pageNumber === currentPage ? "active" : ""}
          >
            {pageNumber}
          </button>
        ))}
        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && <span>...</span>}
            <button onClick={() => setPage(totalPages)}>{totalPages}</button>
          </>
        )}
        {currentPage < totalPages && (
          <button onClick={() => setPage(currentPage + 1)}>
            <TfiArrowCircleRight className="next_page" />
          </button>
        )}
      </div>
    </div>
  );
};

export default StudyMaterial;
