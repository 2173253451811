
import React from "react";
import { useState, useEffect } from "react";

import "./singlevendor.css";
import Skeleton from "react-loading-skeleton";
import { useUserAuth } from "../../../Context/UseAuthContext";
import { useParams } from "react-router-dom";
import vendorImg from "../../../images/logo.png";
import VendorCourse from "../VendorCourse/VendorCourse";
import VendorProject from "../VendorProject/VendorProject";
import VendorStudymaterial from "../VendorStudymaterial/VendorStudymaterial";

const SingleVendor = () => {
  const {
    loading,
    getSingelSubAdmin,
    singleSubAdmin,
    getSingelSubAdminCourse,
    singleSubAdminCourse,
    getSingelSubAdminProject,
    singleSubAdminProject,
    getSingelSubAdminStudymaterial,
    singleSubAdminStudymaterial,
     
  } = useUserAuth();
  const { vendorID } = useParams();
  const [currentView, setCurrentView] = useState("overview");
  const [skeletonCount, setSkeletonCount] = useState(3);
  const [skeletonCount2, setSkeletonCount2] = useState(2);
  const [skeletonCount3, setSkeletonCount3] = useState(2);

  useEffect(() => { 
    getSingelSubAdmin(vendorID);
    getSingelSubAdminCourse(vendorID);
    getSingelSubAdminProject(vendorID);
    getSingelSubAdminStudymaterial(vendorID);
    function handleResize() {
      if (window.innerWidth < 768) {
        setSkeletonCount(10);
        setSkeletonCount2(6);
        setSkeletonCount3(6);
      } else if (window.innerWidth > 768 && window.innerWidth < 1024) {
        setSkeletonCount(7);
        setSkeletonCount2(4);
        setSkeletonCount3(4);
      } else {
        setSkeletonCount(3);
        setSkeletonCount2(2);
        setSkeletonCount3(2);
      }
    }

    handleResize(); 
    window.scrollTo(0, 0);
   
  }, []);



  const overViewContent = () => {
    return (
      <div className="description-c">
        <h1 className="description">
          {loading ? <Skeleton height={25} width={100} /> : "Description"}
        </h1>
        <p className="desc">
          {loading ? (
            <Skeleton
              className="skeleton-description-1"
              count={skeletonCount}
            />
          ) : (
            <>
              Phasellus enim magna, varius et commodo ut, ultricies vitae velit.
              Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel
              justo. In libero urna, venenatis sit amet ornare non, suscipit nec
              risus. Sed consequat justo non mauris pretium at tempor justo
              sodales. Quisque tincidunt laoreet malesuada. Cum sociis natoque
              penatibus et magnis dis parturient montes, nascetur. This course
              is aimed at people interested in UI/UX Design.
            </>
          )}
        </p>
        <p className="desc">
          {loading ? (
            <Skeleton
              className="skeleton-description-1"
              count={skeletonCount2}
            />
          ) : (
            <>

            
              Phasellus enim magna, varius et commodo ut, ultricies vitae velit.
              Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel
              justo. In libero urna, venenatis sit amet ornare non, suscipit nec
              risus. Sed consequat justo non mauris pretium at tempor justo
              sodales. Quisque tincidunt laoreet malesuada.
            </>
          )}
        </p>
        <p className="desc">
          {loading ? (
            <Skeleton
              className="skeleton-description-1"
              count={skeletonCount3}
            />
          ) : (
            <>
              Phasellus enim magna, varius et commodo ut, ultricies vitae velit.
              Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel
              justo. In libero urna, venenatis sit amet ornare non, suscipit nec
              risus. In libero urna, venenatis sit amet ornare non, suscipit nec
              risus.
            </>
          )}
        </p>
      </div>
    );
  };





  const handleViewChange = (view) => {
    setCurrentView(view);
  };
  return (
    <div className="specific-main-c">
      <div className="specific-card-c">
        
        {loading ? (
          <div className="skeleton-specific-card-c">
            {/* <Skeleton className="specific-course-logo" /> */}
            <Skeleton className="skeleton-specific-course-h" />
            <Skeleton className="skeleton-specific-course-sub-h" />
          
            <div className="skeleton-button-icons-c">
              <Skeleton className="skeleton-send-message-btn" />
          
            </div>
          </div>
        ) : (
          singleSubAdmin && (
            <div>
             
              {singleSubAdmin.profileUrl ? (
                <img
                  src={singleSubAdmin.profileUrl}
                  className="instructor-img-pro"
                  alt="Instructor"
                />
              ) : (
                <img
                  src={vendorImg}
                  className="instructor-img-pro"
                  alt="Instructor"
                />
              )}
              <h1 className="specific-course-h">{singleSubAdmin.name}</h1>
          
              {/* <div className="button-icons-c">
                <button type="button" className="send-message-btn">
                  Send Message
                </button>
              
              </div> */}
            </div>
          )
        )}
      </div>
      <div className="specific-sub-container">
        <div className="sp-su-con">
          <button
            data-info="overview"
            type="button"
            className="overview-btn"
            onClick={() => handleViewChange("overview")}
          >
            {loading ? (
              <Skeleton className="skeleton-overview" />
            ) : (
              <p className="overview-btn-text">Overview</p>
            )}
          </button>
          <button
            type="button"
            data-info="courses"
            className="overview-btn"
            onClick={() => handleViewChange("courses")}
          >
            {loading ? (
              <Skeleton className="skeleton-overview" />
            ) : (
              <p className="overview-btn-text">Courses</p>
            )}
          </button>
          <button
            type="button"
            data-info="contact"
            className="overview-btn"
            onClick={() => handleViewChange("contact")}
          >
            {loading ? (
              <Skeleton className="skeleton-overview" />
            ) : (
              <p className="overview-btn-text">Projects</p>
            )}
          </button>
          <button
            type="button"
            data-info="about"
            className="overview-btn"
            onClick={() => handleViewChange("about")}
          >
            {loading ? (
              <Skeleton className="skeleton-overview" />
            ) : (
              <p className="overview-btn-text">StudyMaterials</p>
            )}
          </button>
        </div>

        {currentView === "overview" && overViewContent()}
        {currentView === "courses" && (
          <VendorCourse course={singleSubAdminCourse} />
        )}
        {currentView === "contact" && (
          <VendorProject project={singleSubAdminProject} />
        )}
        {currentView === "about" && (
          <VendorStudymaterial studymaterial={singleSubAdminStudymaterial} />
        )}
      </div>
    </div>
  );
};

export default SingleVendor;
