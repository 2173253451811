import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  decodeCategoryFromURL,
  encodeCategoryForURL,
} from "../../Component/EncodedUrlComponent";
import { useUserAuth } from "../../Context/UseAuthContext";
import axios from "axios";
import ScrollToTopLink from "../ScroolToTop";
import { useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import NoData from "../../images/NoData.gif";
import studymete from "../../images/studymete.gif";


const CategoryStudymaterial = () => {
  const [page, setPage] = useState(1);

  const {
    CategoryStudymaterial,
    setCategoryFilterstudymaterial,
    setSingleStudyMaterialId,
    user,
    getUserData,
    token,
    navigate,
  } = useUserAuth();
  const { category } = useParams();
  const handleAddToCart = (e) => {
    if (!token) {
      toast.error("Please Login to your Account");
      return;
    }
    const id = user._id;

    // Check if the ID is already in the cart
    const isAlreadyInCart = user.cart.some((item) => item.ID === e._id);

    // If the ID is already in the cart, do not add it again
    if (isAlreadyInCart) {
      toast("Item is already in the cart.", {
        icon: "ℹ️",
        style: {
          borderRadius: "10px",
          background: "#blue",
          color: "#222",
          padding: "10px",
        },
      });
      return; // Exit the function early
    }
    // If the ID is not in the cart, add it
    const payload = {
      cart: [
        ...user.cart,
        {
          VendorID: e.userID,
          ID: e._id,
          courseCategory: e.MaterialCategory,
          imageUrl: e.MaterialImage,
          // mainCategory: e.mainCategory,
          // subCategory: e.subCategory,
          postDesc: e.MaterialDescription,
          postName: e.MaterialName,
          Price: e.Price,
          // tags: e.tags,
          // videoUrl: e.videoUrl,
          zipUrl: e.MaterialZip,
          timestamp: Date.now(),
        },
      ],
    };

    axios
      .patch(`https://smartuter.com/api/enduser/edit/${id}`, payload)
      .then((res) => {
        if (res.data.msg === true) {
          toast.success("StudyMaterial Added Successfully to the Cart");
          getUserData(token);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    setCategoryFilterstudymaterial(category);
  }, [page, CategoryStudymaterial]);

  const nextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setPage((prevPage) => prevPage - 1);
  };
  return (
    <section className="MostPopularCourses-component">
   

{/* 
      <div className="all-courses-main">
      <h3 className="course-section-card-head">Find Your {decodeCategoryFromURL(category)} Course..</h3>
      
      </div> */}
      <div className="all-courses-main">
        <div>
        <h3 className="course-section-card-head">Find Your {decodeCategoryFromURL(category)} Course..</h3>
     
        <div className="header-navitems">
<Link to={"/"}><span className="header-links">Home</span></Link>
        <span className="mx-1">{">"}</span>
<Link to={"/studymaterial"}><span className="header-links">Studymaterial</span></Link>
        <span className="mx-1">{">"}</span>
        <Link to={""}><span className="header-links underline-headerlink">Studymaterial-catgory</span></Link>
</div>


        </div>
        <img src={studymete} />
     

      
      </div>



      <div className="CoursesContainer">
        {CategoryStudymaterial &&
        CategoryStudymaterial.approvedMaterials &&
        CategoryStudymaterial.approvedMaterials.length > 0 ? (
          CategoryStudymaterial.approvedMaterials.map((item, index) => {
            return (
              <div key={index} className="CourseCard">
                <div className="ImageAndTitle">
                  <img
                    className="courseImg"
                    alt="course-img"
                    src={item.MaterialImage}
                  />
                </div>

                <div className="CourseTitle-border">
                  <div>
                    <Link
                      to={`/studymaterialcatgory/${encodeCategoryForURL(
                        item.MaterialCategory
                      )}/${item._id}`}
                      onClick={() => setSingleStudyMaterialId(item._id)}
                      className="CourseTitle"
                    >
                      {item.MaterialName.substring(0, 25)+ "..."}
                    </Link>
                  </div>

                  <div className="TagsContainer">
                  <span className="post-tag">{item.MaterialCategory.slice(0, 3)}</span>

                  </div>
                  

                  <div className="CourseAddtoCart">
                    {user &&
                      user.cart &&
                      user.orders &&
                      (user.cart.some(
                        (cartItem) => cartItem.ID === item._id
                      ) ? (
                        <button disabled className="disableBTN">
                          Already In Cart
                        </button>
                      ) : user.orders.some(
                          (orderItem) => orderItem.ID === item._id
                        ) ? (
                        <button disabled className="disableBTN">
                          Please Go to Orders
                        </button>
                      ) : (
                        <button
                          className="enableBTN"
                          onClick={() => handleAddToCart(item)}
                        >
                          Add To Cart
                        </button>
                      ))}
                    {!user && (
                      <button
                        className="enableBTN"
                        onClick={() => (
                          navigate("/login"), handleAddToCart(item)
                        )}
                      >
                        Add To Cart
                      </button>
                    )}
                  </div>
                </div>
                <div className="AuthorAndPrice">
                  {/* <div className="Author">
                    <img
                      className="AuthorImage"
                      src={item.SubAdminProfileUrl}
                      alt="vendor"
                    />
                    <p className="AuthorName">{item.SubAdminName}</p>
                  </div> */}

                  <div className="CoursePrice">
                    <p>₹{item.Price}</p>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="no-course-found-container">
          <p className="no-course-found-text">
            Sorry..!, we couldn't find any result.
          </p>

          <img src={NoData} />
        </div>
        )}
      </div>
      {/* Pagination code  */}
      <div className="pagination">
        <button onClick={prevPage} disabled={page === 1}>
          Previous
        </button>

        {Array.from(
          { length: CategoryStudymaterial && CategoryStudymaterial.totalPages },
          (_, i) => (
            <button
              key={i + 1}
              onClick={() => setPage(i + 1)}
              className={
                CategoryStudymaterial &&
                CategoryStudymaterial.currentPage === i + 1
                  ? "active"
                  : ""
              }
            >
              {i + 1}
            </button>
          )
        )}
        <button
          onClick={nextPage}
          disabled={
            CategoryStudymaterial &&
            CategoryStudymaterial.currentPage ===
              CategoryStudymaterial.totalPages
          }
        >
          Next
        </button>
      </div>
    </section>
    // </div>
  );
};

export default CategoryStudymaterial;
