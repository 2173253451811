import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useUserAuth } from "../../Context/UseAuthContext";
import {
  decodeCategoryFromURL,
  encodeCategoryForURL,
} from "../../Component/EncodedUrlComponent";
import axios from "axios";
import ScrollToTopLink from "../ScroolToTop";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import NoData from "../../images/NoData.gif";
import coursesimg from "../../images/coursesimg.gif"

const CategoryCourse = () => {
  const {
    setCategoryFilter,
    CategoryCourses,
    setSingleCourseId,
    user,
    getUserData,
    token,
    navigate,
  } = useUserAuth();
  const { category } = useParams();

  const handleAddToCart = (e) => {
    if (!token) {
      toast.error("Please Login to your Account");
      return;
    }
    const id = user._id;

    // Check if the ID is already in the cart
    const isAlreadyInCart = user.cart.some((item) => item.ID === e._id);

    // If the ID is already in the cart, do not add it again
    if (isAlreadyInCart) {
      toast("Item is already in the cart.", {
        icon: "ℹ️",
        style: {
          borderRadius: "10px",
          background: "#blue",
          color: "#222",
          padding: "10px",
        },
      });
      return; // Exit the function early
    }
    // If the ID is not in the cart, add it
    const payload = {
      cart: [
        ...user.cart,
        {
          VendorID: e.userID,
          ID: e._id,
          courseCategory: e.courseCategory,
          imageUrl: e.imageUrl,
          mainCategory: e.mainCategory,
          subCategory: e.subCategory,
          postDesc: e.postMessage,
          postName: e.postName,
          Price: e.price,
          tags: e.tags,
          videoUrl: e.videoUrl,
          zipUrl: e.zipUrl,
          timestamp: Date.now(),
        },
      ],
    };

    axios
      .patch(`https://smartuter.com/api/enduser/edit/${id}`, payload)
      .then((res) => {
        if (res.data.msg === true) {
          toast.success("Course Added Successfully to the Cart");
          getUserData(token);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    setCategoryFilter(category);
  }, []);
  return (
    <section className="MostPopularCourses-component">

      <div className="all-courses-main">
        <div>
        <h3 className="course-section-card-head">Find Your {decodeCategoryFromURL(category)} Course..</h3>
     

        <div className="header-navitems">
<Link to={"/"}><span className="header-links">Home</span></Link>
        <span className="mx-1">{">"}</span>
<Link to={"/coursepage"}><span className="header-links">Courses</span></Link>
        <span className="mx-1">{">"}</span>
        <Link to={""}><span className="header-links underline-headerlink">Courses-catgory</span></Link>
</div>
      





        </div>
        <img src={coursesimg} />
     

      
      </div>

      <div className="CoursesContainer">
        {CategoryCourses && CategoryCourses.length > 0 ? (
          CategoryCourses.map((item, index) => {
            return (
              <div key={index} className="CourseCard">
                <div className="ImageAndTitle">
                  <img
                    className="courseImg"
                    alt="course-img"
                    src={item.imageUrl}
                  />
                </div>

                <div className="CourseTitle-border">
                  <div>
                    <Link
                      to={`/coursecatgory/${encodeCategoryForURL(
                        item.mainCategory
                      )}/${item._id}`}
                      onClick={() => setSingleCourseId(item._id)}
                      className="CourseTitle"
                    >
                      {item.postName.substring(0,25)}
                    </Link>
                  </div>
                  <div className="TagsContainer">
                    {item.tags.slice(0 , 3).map((tag, i) => (
                      <span key={i} className="tag">
                        {tag.text}
                      </span>
                    ))}











                  </div>
                  {/* <div className="CategoriesCard">
                    <span>{item.mainCategory}</span>
                    <span>{item.courseCategory}</span>
                  </div> */}

                  <div className="CourseAddtoCart">
                    {user &&
                      user.cart &&
                      user.orders &&
                      (user.cart.some(
                        (cartItem) => cartItem.ID === item._id
                      ) ? (
                        <button disabled className="disableBTN">
                          Already In Cart
                        </button>
                      ) : user.orders.some(
                          (orderItem) => orderItem.ID === item._id
                        ) ? (
                        <button disabled className="disableBTN">
                          Please Go to Orders
                        </button>
                      ) : (
                        <button
                          className="enableBTN"
                          onClick={() => handleAddToCart(item)}
                        >
                          Add To Cart
                        </button>
                      ))}
                    {!user && (
                      <button
                        className="enableBTN"
                        onClick={() => (
                          navigate("/login"), handleAddToCart(item)
                        )}
                      >
                        Add To Cart
                      </button>
                    )}
                  </div>
                </div>
                <div className="AuthorAndPrice">
                  {/* <div className="Author">
                    <img
                      className="AuthorImage"
                      src={item.SubAdminProfileUrl}
                      alt="vendor"
                    />
                    <p className="AuthorName">{item.SubAdminName}</p>
                  </div> */}

                  {/* <div className="CoursePrice">
                    <p>₹{item.price}</p>
                  </div> */}
                  <div className="CoursePrice">
                  <p>Price: <span className="card-Price">₹{item.price}</span></p>
                </div> 
                </div>
              </div>
            );
          })
        ) : (
          <div className="no-course-found-container">
            <p className="no-course-found-text">
              Sorry..!, we couldn't find any result.
            </p>

            <img src={NoData} />
          </div>
        )}
      </div>
    </section>
  );
};

export default CategoryCourse;
