import React, { useEffect, useState } from "react";
import "./hero.css";
import Waves from "../waves/Waves";
import { useNavigate } from "react-router-dom";
import hero from "../../../images/hero.gif";

const Hero = () => {
  const [animationClassLeft, setAnimationClassLeft] = useState("animate__bounceInLeft");
  const [animationClassRight, setAnimationClassRight] = useState("animate__bounceInRight");

  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 350) {
        setAnimationClassLeft("animate__bounceOutLeft");
        setAnimationClassRight("animate__bounceOutRight");
      } else {
        setAnimationClassLeft("animate__bounceInLeft");
        setAnimationClassRight("animate__bounceInRight");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="LearnSkills-container">
      <div className="LearnSkills-sub-container">
        <div className={`Leanskils-heading-hero animate__animated ${animationClassLeft}`}>
          <h1>
            Learn New Skills Online with Top{" "}
            
            <span className="Leanskils-heading-text-hero">Smartuter</span>
          </h1>
          <span className="hero-text">
            Build skills with courses, certificates, and degrees online from
            world-class universities and companies.
          </span>

          <div className="learnmore-btn-hero">
            <button className="button-1" onClick={() => navigate("/contact")}>
              Join For free
            </button>
            <button className="button-2" onClick={() => navigate("/coursepage")}>
              Find Courses
            </button>
          </div>
        </div>

        <div className={`learnmore-images-hero animate__animated ${animationClassRight}`}>
          <div className="hero-img-main">
            <img src={hero} alt="Hero" />
          </div>
        </div>
      </div>

      <Waves />
    </div>
  );
};

export default Hero;
