import React from 'react';
import './waves.css';

const Waves = () => {
  return (
    <div className="waves-container">
      <div>
        <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="parallax">
            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(255,255,255,0.7)" />
          </g>
        </svg>
      </div>

      {/* <div>import React, { useRef, useEffect } from 'react';

const ImageMovement = () => {
  const imageRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const image = imageRef.current;
      const { left, top, width, height } = image.getBoundingClientRect();
      const centerX = left + width / 2;
      const centerY = top + height / 2;
      const offsetX = e.clientX - centerX;
      const offsetY = e.clientY - centerY;
      const movementScale = 0.05; // Adjust movement scale for desired sensitivity
      
      image.style.transform = `translate(${offsetX * movementScale}px, ${offsetY * movementScale}px)`;
    };

    const handleMouseLeave = () => {
      const image = imageRef.current;
      image.style.transform = 'none';
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  return (
    <div className="hero-image-card">
      <img ref={imageRef} src="https://educrat-react.vercel.app/assets/img/home-9/hero/1.png" alt="Hero Image" />
    </div>
  );
};

export default ImageMovement;
</div> */}

    </div>
  );
}

export default Waves;
