import React from "react";
import Category from "../Category/Category/Category";
import LearnSkills from "./learnskills/LearnSkills";
import Course from "../Courses/Course";
import About from "../About";
import Vendor from "../Vendor/Vendro";
import HomeProject from "./Project/HomeProject";
import Contact from "../Contact";
import Hero from "./learnskills/Hero";
import AboutTwo from "../AboutTwo";

const Home = () => {
  
 
  return (
    <>
      <div className="scroll-watcher"></div>
      {/* <LearnSkills /> */}
      <Hero/>
      <Category />
      {/* <Vendor/> */}
      <HomeProject />
      <Course />

      {/* <About /> */}
      <AboutTwo />
    </>
  );
};

export default Home;
