import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Messages from '../images/Messages.gif'

import {
  faPhone,
  faMapMarkerAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import "./ContactNew.css";
import ButtonGrid from "./buttonGrid";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <>
     <div className="all-courses-main">
        <div>
        <h3 className="course-section-card-head">Contact Us</h3>
      <p className="course-section-card-para">
      Welcome to our Smartuter Enhance your skills with best Online courses.
      </p>
      
<div className="header-navitems">
<Link to={"/"}><span className="header-links">Home</span></Link>
        <span className="mx-1">{">"}</span>
        <Link to={"/contact"}><span className="header-links underline-headerlink">Contact Us</span></Link>
</div>
       


    
        </div>
        <img src={Messages} />
     

      
      </div>
    
   
    <div className="contact-us-section">
     
  

      <div className="contactinfo-send-mail">
        <div className="contact-info">
          <h1 className="contact-info-head">Keep In Touch With Us.</h1>
       
          <div className="fao-icon-text">
            <div className="icon-container">
              <FontAwesomeIcon icon={faEnvelope} className="fao-icon" />{" "}
            </div>
            <span>
              Email:
              <a href="mailto:info@smartuter.com">info@smartuter.com</a>
            </span>
          </div>
          <div className="fao-icon-text">
            <div className="icon-container">
              <FontAwesomeIcon icon={faPhone} className="fao-icon" />
            </div>{" "}
            <span>
              <a
                href={`tel:9182774794`}
                style={{ color: "#007bff", textDecoration: "underline" }}
              >
                Phone: +91 9876543210
              </a>
            </span>
          </div>
          <div className="fao-icon-text">
            <div className="icon-container">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="fao-icon" />
            </div>
            <span className="address-text">
              Address: Hyderabad,
             Telangana, 500039.
            </span>{" "}
          </div>
        </div>

        <div className="send-message-section">
          <h1 className="send-message-head">Send a Message</h1>
         

          <form className="message-form">
            <label htmlFor="name">Name</label>
            <input
              id="name"
              type="text"
              placeholder="ex:Smartuter "
              className="input-name"
            />
            <br />
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              placeholder="ex:info@smartuter.com"
              className="input"
            />
            <br />
            <label htmlFor="message">Message...</label>
            <textarea
              id="message"
              placeholder="Enter Your Message..."
            ></textarea>
            <br />
            <button type="submit">Send</button>
          </form>
        </div>
      </div>
    </div>
     </>
  );
};
export default Contact;
