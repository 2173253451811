// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Terms-And-Conditions-container{
    width: 100%;
    background: white;
}
.Terms-And-Conditions{
    width: 93%;
    margin: 5vh auto;
  
}
.Terms-And-Conditions h1{
    text-transform: initial;
}
.Terms-And-Conditions p{
    font-size: 1.2rem;
    text-align: justify;
}
.Terms-And-Conditions h4{
    text-transform: initial;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Policys/Terms&conditions.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;AACrB;AACA;IACI,UAAU;IACV,gBAAgB;;AAEpB;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,uBAAuB;AAC3B","sourcesContent":[".Terms-And-Conditions-container{\n    width: 100%;\n    background: white;\n}\n.Terms-And-Conditions{\n    width: 93%;\n    margin: 5vh auto;\n  \n}\n.Terms-And-Conditions h1{\n    text-transform: initial;\n}\n.Terms-And-Conditions p{\n    font-size: 1.2rem;\n    text-align: justify;\n}\n.Terms-And-Conditions h4{\n    text-transform: initial;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
