import pageNotFound from "../../images/page not found.webp"
import { NavLink } from "react-router-dom"
import { useUserAuth } from "../../Context/UseAuthContext";
import "./PageNotFound.css"
const PageNotFound=()=>{
    const{darkThemesetter} = useUserAuth()
    return(
        <div className={`not-found-page-container`}>
            <div className="not-found-img-container">
                <img src={pageNotFound} alt="page not found" className="page-notfound-img"/>
            </div>
            <div className="not-found-text-container">
                <h1>Oops! It looks like you're lost.</h1>
                <p>The page you're looking for isn't available. Try to search again or use the go to.</p>
                <NavLink to="/">
                <button>Back to Home Page</button>
                </NavLink> 
            </div>
        </div>
    )
}
export default PageNotFound