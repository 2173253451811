// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.private-policy-container{
    padding: 25px;
            font-family: "Arial, sans-serif";
    font-size: 20px;
}
.shipping-button{
    background-color: #06517a;
    padding: 10px;
    border: none;
    border-radius: 10px;
    color: white;
}

.no-shipping-image{
    width: 80%;
}

@media only screen and (min-width: 768px) and (max-width: 1023px){
    .private-policy-container {
            padding: 40px;
            font-family: "Arial, sans-serif";
            font-size: 23px;
        }
}

@media only screen and (min-width: 1023px) {
    .private-policy-container {
        padding: 60px;
        font-family: "Arial, sans-serif";
        font-size: 26px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Policys/Policies.css"],"names":[],"mappings":"AAAA;IACI,aAAa;YACL,gCAAgC;IACxC,eAAe;AACnB;AACA;IACI,yBAAyB;IACzB,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI;YACQ,aAAa;YACb,gCAAgC;YAChC,eAAe;QACnB;AACR;;AAEA;IACI;QACI,aAAa;QACb,gCAAgC;QAChC,eAAe;IACnB;AACJ","sourcesContent":[".private-policy-container{\n    padding: 25px;\n            font-family: \"Arial, sans-serif\";\n    font-size: 20px;\n}\n.shipping-button{\n    background-color: #06517a;\n    padding: 10px;\n    border: none;\n    border-radius: 10px;\n    color: white;\n}\n\n.no-shipping-image{\n    width: 80%;\n}\n\n@media only screen and (min-width: 768px) and (max-width: 1023px){\n    .private-policy-container {\n            padding: 40px;\n            font-family: \"Arial, sans-serif\";\n            font-size: 23px;\n        }\n}\n\n@media only screen and (min-width: 1023px) {\n    .private-policy-container {\n        padding: 60px;\n        font-family: \"Arial, sans-serif\";\n        font-size: 26px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
