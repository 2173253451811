

import React, { useState, useEffect } from "react";
import { IoMdSearch } from "react-icons/io";
import { MdKeyboardArrowDown } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import "./allvendor.css";
import { useUserAuth } from "../../../Context/UseAuthContext";
import vendorImg from "../../../images/logo.png";
const AllVendor = () => {
  const { subAdmins, loading, timestampToDateTime, handleSearchVendorNameChange, getSubAdmins,searchQuary, orderFilter} = useUserAuth();
  
  const [isCategoryDropdownVisible, setIsCategoryDropdownVisible] =
    useState(false);
  const [isallvendorDropdownVisible, setIsallvendorDropdownVisible] =
    useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getSubAdmins();
  }, [searchQuary, orderFilter]);

  const toggleCategoryDropdown = () => {
    setIsCategoryDropdownVisible(!isCategoryDropdownVisible);
  };

  const toggleallvendorDropdown = () => {
    setIsallvendorDropdownVisible(!isallvendorDropdownVisible);
  };

  return (
    <div className="allvendor-container">
      <div className="allvendor-top-main-c">
        {loading ? (
          <div className="skeleton-allvendor-top-section">
            <div>
              <Skeleton height={40} width={130} />
            </div>
            <div>
              <Skeleton className="skeleton-allvendor-des" />
            </div>
          </div>
        ) : (
          <div className="allvendor-top-section">
            <h1 className="allvendor-h">Instructors</h1>
            <p className="allvendor-p">
              We’re on a mission to deliver engaging, curated allvendor at a
              reasonable price.
            </p>
          </div>
        )}

        <div className="allvendor-instructor">
          {loading ? (
            <div>
              <Skeleton className="skeleton-total-results" />
            </div>
          ) : (
            <p className="total-results">
              Showing {subAdmins && subAdmins.length} total results
            </p>
          )}

          <div className="allvendor-sub-instructor">
            {loading ? (
              <div>
                <Skeleton className="skeleton-search-c" />
              </div>
            ) : (
              <div className="search-c">
                <IoMdSearch size={20} className="search-icon" />
                <input
                  type="search"
                  className="search-input"
                  placeholder="Search Instructor"
                  onChange={handleSearchVendorNameChange}
                />
              </div>
            )}

            {/* <div className="dropdown-container-mb">
              <div className="dropdown-container">
                {loading ? (
                  <div>
                    <Skeleton className="skeleton-category-c" />
                  </div>
                ) : (
                  <button
                    type="button"
                    className="category-c"
                    onClick={toggleCategoryDropdown}
                  >
                    Category <MdKeyboardArrowDown />
                  </button>
                )}

                {isCategoryDropdownVisible && (
                  <div className="dropdown-content">
                    <a href="/#">Chair</a>
                    <a href="/#">Stool</a>
                  </div>
                )}
              </div>
              <div className="dropdown-container">
                {loading ? (
                  <div>
                    <Skeleton className="skeleton-category-c" />
                  </div>
                ) : (
                  <button
                    type="button"
                    className="category-c"
                    onClick={toggleallvendorDropdown}
                  >
                    allvendor <MdKeyboardArrowDown />
                  </button>
                )}
                {isallvendorDropdownVisible && (
                  <div className="dropdown-content">
                    <a href="/#">React</a>
                    <a href="/#">Node.js</a>
                  </div>
                )}
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {!subAdmins ? (
        // Show skeleton UI when loading
        <ul className="instructor-ul">
          {[...Array(4)].map((_, index) => (
            <li key={index} className="instructor-card-c">
              <Skeleton />
              <Skeleton height={30} width={100} />
              <Skeleton height={30} width={200} />
            </li>
          ))}
        </ul>
      ) : (
        // Show actual content when data is loaded
        <ul className="instructor-ul-container">
          {subAdmins &&
            subAdmins.map((eachItem) => (
              <li key={eachItem._id} className="instructor-card-container-c">
                {/* <img
                  src={eachItem.profileUrl}
                  className="instructor-img"
                  alt="instructor-img"
                /> */}
                {eachItem.profileUrl ? (
                  <img
                    src={eachItem.profileUrl}
                    className="ins-ven-img"
                    alt="Instructor"
                  />
                ) : (
                  <img
                    src={vendorImg}
                    className="ins-ven-img"
                    alt="Instructor"
                  />
                )}
                {/* <div className="overlay"></div> */}
                <div className="instructor-description">
                  <Link to={`/allvendor/${eachItem._id}`} className="link-tag">
                    <h1 className="instructor-description-h">{eachItem.name}</h1>
                  </Link>
                  {/* <p className="instructor-des">{eachItem.email}</p> */}
                  <p className="instructor-des-date">
                    Join:-{timestampToDateTime(eachItem.timestamp)}
                  </p>
                </div>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};
export default AllVendor;
