import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useUserAuth } from "../../Context/UseAuthContext";
import axios from "axios";
import "./singlecourse.css";
import { FaRegClipboard, FaWhatsapp } from "react-icons/fa6";
import { BiLogoGmail } from "react-icons/bi";
import toast from "react-hot-toast";
import SingleCourseBanner from "./SingleCourseBanner";
import { IoMdShareAlt } from "react-icons/io";
import VideoPlayer from "../../Component/VideoPlayer";
import Slider from "react-slick";

const SingleCourse = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleParagraph = () => {
    setIsExpanded(!isExpanded);
  };
  const defaultImageUrl =
    "https://iconicentertainment.in/wp-content/uploads/2013/11/dummy-image-square.jpg";
  const {
    setSingleCourseId,
    singelCourse,
    user,
    getUserData,
    token,
    navigate,
  } = useUserAuth();

  console.log(singelCourse, "=====================");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const param = useParams();

  const url = new URL(window.location.href);

  // Get the value of the 'referalcode' query parameter
  const referralCode = url.searchParams.get("referalcode");
  // Get the value of the 'referalcode' query parameter
  const referalLink = `${window.location.pathname}?referalcode=${referralCode}`;
  const referredLink = localStorage.setItem("referredLink", referalLink);
  // Log or use the referral code
  const handleShare = () => {
    if (!token) {
      toast.error("Please Login to your Account");
      return;
    }
    const referalcode = localStorage.getItem("referralCode");
    const url = window.location.href;
    const urlwithoutParams = url.split("?")[0];

    const referalLink = `${urlwithoutParams}?referalcode=${referalcode}`;

    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const shareOnWhatsApp = () => {
    const referalcode = localStorage.getItem("referralCode");
    const url = window.location.href;
    const urlwithoutParams = url.split("?")[0];
    const referalLink = `${urlwithoutParams}?referalcode=${referalcode}`; // Replace with your actual referral link
    const message = `Hey there, I'm using WhatsApp! Check this out This is course which i have used in smartuter: ${referalLink}`;
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      message
    )}`;

    window.open(whatsappUrl, "_blank");
  };

  const copyToClipboard = () => {
    const referalcode = localStorage.getItem("referralCode");
    const url = window.location.href;
    const urlwithoutParams = url.split("?")[0];

    const referalLink = `${urlwithoutParams}?referalcode=${referalcode}`;
    navigator.clipboard.writeText(referalLink).then(
      () => {
        toast.success("Referral link copied to clipboard!");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const shareViaGmail = () => {
    const referalcode = localStorage.getItem("referralCode");
    const url = window.location.href;
    const urlwithoutParams = url.split("?")[0];

    const referalLink = `${urlwithoutParams}?referalcode=${referalcode}`;
    const subject = "Check out this referral link!";
    const body = `Hi, check out this referral link: ${referalLink}`;
    const gmailUrl = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = gmailUrl;
  };

  const handleAddToCart = (e) => {
    if (!token) {
      toast.error("Please Login to your Account");
      return;
    }
    const referalcode = localStorage.getItem("referralCode");
    const url = window.location.href;

    const test = url.split("=");

    if (referalcode === test[1]) {
      const url = window.location.href;
      window.location.href = url.split("?")[0];
    }

    const id = user._id;

    // Check if the ID is already in the cart
    const isAlreadyInCart = user.cart.some((item) => item.ID === e._id);

    // If the ID is already in the cart, do not add it again
    if (isAlreadyInCart) {
      toast("Item is already in the cart.", {
        icon: "ℹ️",
        style: {
          borderRadius: "10px",
          background: "#blue",
          color: "#222",
          padding: "10px",
        },
      });

      return; // Exit the function early
    }
    // If the ID is not in the cart, add it
    const payload = {
      cart: [
        ...user.cart,
        {
          VendorID: e.userID,
          ID: e._id,
          courseCategory: e.courseCategory,
          imageUrl: e.imageUrl,
          mainCategory: e.mainCategory,
          subCategory: e.subCategory,
          postDesc: e.postMessage,
          postName: e.postName,
          Price: e.price,
          tags: e.tags,
          videoUrl: e.videoUrl,
          zipUrl: e.zipUrl,
          timestamp: Date.now(),
          referralCode: referralCode,
        },
      ],
    };

    axios
      .patch(`https://smartuter.com/api/enduser/edit/${id}`, payload)
      .then((res) => {
        if (res.data.msg === true) {
          toast.success("Course Added Successfully to the Cart");
          getUserData(token);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    setSingleCourseId(param.courseID);
    getUserData(token);
  }, []);

  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 1500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: false,
  //   autoplay: true,
  //   autoplaySpeed: 33000,
  //   cssEase: "linear",
    
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         initialSlide: 3,
  //         infinite: true,
  //         dots: false,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         initialSlide: 2,
  //         dots: false,
  //       },
  //     },
  //   ],
  // };
  return (
    <div>
      {/* <SingleCourseBanner /> */}
      {singelCourse && (
        <div className="head simgle-course-maindiv
        ">
          {/* <div className="container-head">
            <div className="container-title">
              <h1>{singelCourse.postName}</h1>
            </div>
            <div className="container-para">
              <p className={isExpanded ? "full" : "truncated"}>
                {singelCourse.postMessage}
              </p>
              <p style={{ color: "blue" }} onClick={toggleParagraph}>
                {isExpanded ? "Show Less" : "Read More..."}
              </p>
            </div>
            <div className="TagsContainer">
              <>
                {singelCourse.tags &&
                  singelCourse.tags.map((tag) => (
                    <span
                     className="tag"
                      key={tag._id}
                    >
                      {tag.text}
                    </span>
                  ))}
              </>
            </div>
          </div> */}





          {/* accordinace start here  */}
          <div className="acordiance-course-study accordian-body-align">
            {/* <div className="mb-2"> */}
            {singelCourse.tags &&
                  singelCourse.tags.map((tag) => (
                    <span
                     className="tech"
                      key={tag._id}
                    >
                      {tag.text}
                    </span>
                  ))}
            {/* </div> */}
         
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              {/* <h2 className="accordion-header">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        Accordion Item #1
      </button>
    </h2> */}
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="container-title">
                    <h1>{singelCourse.postName}</h1>
                  </div>
                  <div className="container-para">
                    <p className={isExpanded ? "full" : "truncated"}>
                      {singelCourse.postMessage}
                    </p>
                    <p className="readmore-text" onClick={toggleParagraph}>
                      {isExpanded ? "Show Less" : "Read More..."}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          
          {/* accordinace end here  */}

          <div className="card-course1">
          {/* <Slider {...settings}> */}
            

            <VideoPlayer
                        src={singelCourse.videoUrl}
                        type="video/mp4"
                        // width="640"
                        // height="360"
                        controls={true}
                        autoplay={false}
                        muted={false}
                        loop={true}
                        poster={singelCourse.imageUrl}
                      />
            {/* <img src={singelCourse.imageUrl} alt="Course" />

                    </Slider> */}
            <div className="card-bod">
              <div className="price">
                <p className="price1">
                  <span>Title</span>: {singelCourse.postName}
                </p>

                <p className="price1 ">
                  <span>Price</span>: ₹{singelCourse.price}
                </p>
                {/* <h1 className="price2">199</h1> */}
              </div>
              <div className="card-button">
                <button onClick={() => handleShare()}>
                  <IoMdShareAlt />
                </button>
                {isModalOpen && (
                  <div className="modal">
                    <div className="modal-content">
                      <span className="close" onClick={closeModal}>
                        &times;
                      </span>
                      <h2>Share Via</h2>
                      <div>
                        <FaWhatsapp
                          className="share-logo whatsapp "
                          onClick={shareOnWhatsApp}
                        />
                        <FaRegClipboard
                          className="share-logo"
                          onClick={copyToClipboard}
                        />
                        <BiLogoGmail
                          className="share-logo gmail"
                          onClick={shareViaGmail}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {user &&
                  user.cart &&
                  user.orders &&
                  (user.cart.some(
                    (cartItem) => cartItem.ID === singelCourse._id
                  ) ? (
                    <button disabled className="disableBTN">
                      Already In Cart
                    </button>
                  ) : user.orders.some(
                      (orderItem) => orderItem.ID === singelCourse._id
                    ) ? (
                    <Link to={"/order"} style={{ width: "100%" }}>
                      <button className="disableBTN">
                        Please Go to Orders
                      </button>
                    </Link>
                  ) : (
                    <button
                      className="enableBTN"
                      onClick={() => handleAddToCart(singelCourse)}
                    >
                      Add To Cart
                    </button>
                  ))}
                {!user && (
                  <button
                    className="enableBTN"
                    onClick={() => (
                      navigate("/login"), handleAddToCart(singelCourse)
                    )}
                  >
                    Add To Cart
                  </button>
                )}
                <br />
              </div>
            </div>
          </div>
          
        </div>
      )}
    </div>
  );
};

export default SingleCourse;
