import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  decodeCategoryFromURL,
  encodeCategoryForURL,
} from "../../Component/EncodedUrlComponent";
import { useUserAuth } from "../../Context/UseAuthContext";
import axios from "axios";
import toast from "react-hot-toast";
import NoData from "../../images/NoData.gif";
import project from "../../images/project.gif";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";

const CategoryProject = () => {
  const {
    CategoryProject,
    setCategoryFiltersproject,
    setSingleProjectId,
    user,
    getUserData,
    token,
    navigate,
    allSubCategory,
  } = useUserAuth();
  const { category } = useParams();
  const [secOpen, setISecsOpen] = useState(true);

  const [categoryProjects, setCatergoryProject] = useState([]);

  const [showAllSubCategories, setShowAllSubCategories] = useState(null);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);

  useEffect(() => {
    if (selectedSubCategories.length > 0) {
      fetchProjects();
    } else {
      setCatergoryProject([]);
    }
  }, [selectedSubCategories]);

  const fetchProjects = async () => {
    try {
      const response = await axios.get(
        "https://smartuter.com/api/enduser/multiProjects",
        {
          params: {
            subCategories: selectedSubCategories.join(","),
          },
        }
      );
      setCatergoryProject(response.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const toggleCoursesAccordionSecound = () => {
    setISecsOpen(!secOpen);
  };

  const handleAddToCart = (e) => {
    if (!token) {
      toast.error("Please Login to your Account");
      return;
    }
    const id = user._id;

    // Check if the ID is already in the cart
    const isAlreadyInCart = user.cart.some((item) => item.ID === e._id);

    // If the ID is already in the cart, do not add it again
    if (isAlreadyInCart) {
      toast("Item is already in the cart.", {
        icon: "ℹ️",
        style: {
          borderRadius: "10px",
          background: "#blue",
          color: "#222",
          padding: "10px",
        },
      });
      return; // Exit the function early
    }
    // If the ID is not in the cart, add it
    const payload = {
      cart: [
        ...user.cart,
        {
          VendorID: e.userID,
          ID: e._id,
          courseCategory: e.courseCategory,
          imageUrl: e.OutputScreens,
          mainCategory: e.mainCategory,
          subCategory: e.subCategory,
          postDesc: e.ProjectDescription,
          postName: e.ProjectTitle,
          Price: e.Price,
          tags: e.TechnologiesUsed,
          Abstract: e.Abstract200Words,
          zipUrl: e.UploadCodeFile,
          DocumentUrl: e.UploadDocumentFile,
          timestamp: Date.now(),
        },
      ],
    };

    axios
      .patch(`https://smartuter.com/api/enduser/edit/${id}`, payload)
      .then((res) => {
        if (res.data.msg === true) {
          toast.success("Project Added Successfully to the Cart");
          getUserData(token);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSubCategoryChange = (category) => {
    setSelectedSubCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
  };

  const toggleShowAllSubCategories = (data) => {
    if (showAllSubCategories !== null) {
      return setShowAllSubCategories(null);
    }
    setShowAllSubCategories(data);
  };
  useEffect(() => {
    setCategoryFiltersproject(category);
  }, []);
  return (
    <section className="ProjectsSection">
      {/* <div className="ProjectHeadline">
        <h1>{decodeCategoryFromURL(category)}</h1>
      </div> */}

      {/* 
      <div className="all-courses-main">
      <h3 className="course-section-card-head">Find Your {decodeCategoryFromURL(category)} Course..</h3>
 
      
      </div> */}

      <div className="all-courses-main">
        <div>
          <h3 className="course-section-card-head">
            Find Your {decodeCategoryFromURL(category)} Course..
          </h3>
          <div className="header-navitems">
            <Link to={"/"}>
              <span className="header-links">Home</span>
            </Link>
            <span className="mx-1">{">"}</span>
            <Link to={"/project"}>
              <span className="header-links">Pojects</span>
            </Link>
            <span className="mx-1">{">"}</span>
            <Link to={""}>
              <span className="header-links underline-headerlink">
                Project-catgory
              </span>
            </Link>
          </div>
        </div>
        <img src={project} alt="Project Img" />
      </div>

    
      <div className="Project-new-sesstion">
        <div className="MostPopularProjectsCategoriesComponent">
          <div className="ProjectsCategories">
            {/* <div className="dropdown">
              <div
                className={`${
                  isOpen ? "open" : ""
                } project-section-each-category`}
                onClick={toggleCoursesAccordion}
              >
                <div className="project-section-each-category-title">
                  Projects Category
                </div>
                <div className="project-section-each-category-arrow">
                  {isOpen ? (
                    <RiArrowDropUpLine className="up-arrow" />
                  ) : (
                    <RiArrowDropDownLine className="down-arrow" />
                  )}
                </div>
              </div>
              {isOpen && (
                <div>
                  {allMainCategory &&
                    allMainCategory
                      .slice(
                        0,
                        showAllCategories === "category"
                          ? allMainCategory.length
                          : 3
                      )
                      .map((cat, i) => (
                        <label
                          key={i}
                          className="studymaterial-section-category-checkbox-container"
                        >
                          <input
                            className="project-section-category-checkbox"
                            type="checkbox"
                            value={cat.category}
                            onChange={() =>
                              handleMainCategoryChange(cat.category)
                            }
                          />
                          <p className="studymaterial-section-category-checkbox-para">
                            {cat.category}
                          </p>
                        </label>
                      ))}
                  {(allMainCategory && allMainCategory.length) > 3 && (
                    <button
                      style={{ color: "blue" }}
                      onClick={() => toggleShowAllCategories("category")}
                      className="study-material-show-more"
                    >
                      {showAllCategories === "category"
                        ? "Show Less"
                        : "Show More"}
                    </button>
                  )}
                </div>
              )}
            </div>
            <hr /> */}

            <div
              className={`Courses-accordion-header ${
                secOpen ? "open" : ""
              } course-section-each-category`}
              onClick={toggleCoursesAccordionSecound}
            >
              <div className="course-section-each-category-title">
                SubCategory
              </div>
              <div className="course-section-each-category-arrow">
                {secOpen ? (
                  <RiArrowDropUpLine className="up-arrow" />
                ) : (
                  <RiArrowDropDownLine className="down-arrow" />
                )}
              </div>
            </div>
            {secOpen && (
              <div className="course-section-category-container-each">
                {allSubCategory &&
                  allSubCategory
                    .slice(
                      0,
                      showAllSubCategories === "category"
                        ? allSubCategory.length
                        : 3
                    )
                    .map((cat, i) => (
                      <label
                        key={i}
                        className="course-section-category-checkbox-container"
                      >
                        <input
                          className="project-section-category-checkbox"
                          type="checkbox"
                          value={cat.category}
                          onChange={() => handleSubCategoryChange(cat.category)}
                        />
                        <p className="course-section-category-checkbox-para">
                          {cat.category}
                        </p>
                      </label>
                    ))}
                {(allSubCategory && allSubCategory.length) > 3 && (
                  <button
                    style={{ color: "blue" }}
                    onClick={() => toggleShowAllSubCategories("category")}
                    className="study-material-show-more"
                  >
                    {showAllSubCategories === "category"
                      ? "Show Less"
                      : "Show More"}
                  </button>
                )}
              </div>
            )}
            <hr />
          </div>
        </div>

        <div className="projectsContainer">
          <div className="project-section-display-filter">
            {/* <input
              type="search"
              value={searchProject}
              onChange={(e) => setSearchProject(e.target.value)}
              placeholder="search..."
            /> */}

            {/* <p className="project-section-filter-para">
              Showing{" "}
              {CategoryProject && CategoryProject.length
                ? CategoryProject.length
                : 0}{" "}
              total results
            </p> */}

            {/* <div className="course-section-search">
              <div className="input-container">
                <CiSearch className="search-icon" />
                <input
                  type="search"
                  value={searchProject}
                  onChange={(e) => setSearchProject(e.target.value)}
                  placeholder="Search Projects..."
                  className="courses-search"
                />
              </div>
            </div> */}
          </div>
          {categoryProjects.length > 0 ? (
            categoryProjects.map((item, index) => (
              // <div className="project-Card" key={index}>
              //   <div className="projectImageCard">
              //     <img
              //       className="project-img"
              //       src={item.OutputScreens}
              //       alt="porject-img"
              //       onError={(e) => {
              //         e.target.src =
              //           "https://www.timeoutdubai.com/cloud/timeoutdubai/2021/09/11/hfpqyV7B-IMG-Dubai-UAE-1200x800.jpg";
              //       }}
              //     />
              //   </div>
              //   <div className="projectInfoCard">
              //     <h4 className="projectTitle">
              //       <Link
              //         to={`/project/${item._id}`}
              //         onClick={() => setSingleProjectId(item._id)}
              //       >
              //         {/* {truncateText(item.ProjectTitle, 3)} */}
              //         {item.ProjectTitle.substring(0, 25)+"..."}

              //       </Link>
              //     </h4>

              //     <div className="projecttags">
              //       {item.TechnologiesUsed.slice(0, 2).map((item, i) => (
              //         <span key={i}>
              //           {" "}
              //           <i className="fa-regular fa-bookmark"></i> {item.text}
              //         </span>
              //       ))}
              //     </div>

              //     {/* <div className="Categories">
              //       <span style={{ color: "#06517a" }}>
              //         {item.mainCategory}
              //       </span>
              //       >>
              //       <span style={{ color: "#346581" }}>{item.subCategory}</span>
              //       >>
              //       <span style={{ color: "#506e7f" }}>
              //         {item.courseCategory}
              //       </span>
              //     </div> */}
              //     <div className="projectBuyBtn">
              //       {user &&
              //         user.cart &&
              //         user.orders &&
              //         (user.cart.some(
              //           (cartItem) => cartItem.ID === item._id
              //         ) ? (
              //           <Link to={"/cart"}>
              //             <button className="disableBTN">
              //               Already In Cart
              //             </button>
              //           </Link>
              //         ) : user.orders.some(
              //             (orderItem) => orderItem.ID === item._id
              //           ) ? (
              //           <Link to={"/order"}>
              //             <button className="disableBTN">
              //               Please Go to Orders
              //             </button>
              //           </Link>
              //         ) : (
              //           <button
              //             className="enableBTN"
              //             onClick={() => handleAddToCart(item)}
              //           >
              //             Add To Cart
              //           </button>
              //         ))}
              //       {!user && (
              //         <button
              //           className="enableBTN"
              //           onClick={() => (
              //             navigate("/login"), handleAddToCart(item)
              //           )}
              //         >
              //           Add To Cart
              //         </button>
              //       )}
              //     </div>
              //     {/* <button onClick={() => handleAddToCart(item)}>ADD</button> */}
              //   </div>
              // </div>
              <div className="project-Card" key={index}>
                <div className="projectImageCard">
                  <img
                    className="project-img"
                    src={item.OutputScreens}
                    alt="porject-img"
                    onError={(e) => {
                      e.target.src =
                        "https://www.timeoutdubai.com/cloud/timeoutdubai/2021/09/11/hfpqyV7B-IMG-Dubai-UAE-1200x800.jpg";
                    }}
                  />
                </div>
                <div className="projectInfoCard">
                  <div>
                    <div className="projectTitle">
                      <Link
                       to={`/projectcatgory/${encodeCategoryForURL(category)}/${
                        item._id
                      }`}
                        onClick={() => setSingleProjectId(item._id)}
                      >
                        {/* {truncateText(item.ProjectTitle, 3)} */}

                        {item.ProjectTitle.substring(0, 25) + "..."}
                      </Link>
                    </div>
                  </div>
                  <div className="projecttags">
                    {item.TechnologiesUsed.slice(0, 2).map((item, i) => (
                      <span className="tags" key={i}>
                        {" "}
                        {item.text}
                      </span>
                    ))}
                  </div>

                  {/* <div className="Categories">
                  <span style={{ color: "#06517a" }}>
                    {item.mainCategory}
                  </span>
                  >>
                  <span style={{ color: "#346581" }}>{item.subCategory}</span>
                  >>
                  <span style={{ color: "#506e7f" }}>
                    {item.courseCategory}
                  </span>
                </div> */}
                  <div className="projectBuyBtn">
                    {user &&
                      user.cart &&
                      user.orders &&
                      (user.cart.some(
                        (cartItem) => cartItem.ID === item._id
                      ) ? (
                        <Link to={"/cart"}>
                          <button className="disableBTN">
                            Already In Cart
                          </button>
                        </Link>
                      ) : user.orders.some(
                          (orderItem) => orderItem.ID === item._id
                        ) ? (
                        <Link to={"/order"}>
                          <button className="disableBTN">
                            Please Go to Orders
                          </button>
                        </Link>
                      ) : (
                        <button
                          className="enableBTN"
                          onClick={() => handleAddToCart(item)}
                        >
                          Add To Cart
                        </button>
                      ))}
                    {!user && (
                      <button
                        className="enableBTN"
                        onClick={() => (
                          navigate("/login"), handleAddToCart(item)
                        )}
                      >
                        Add To Cart
                      </button>
                    )}
                  </div>
                  <div className="CoursePrice">
                    <p>
                      Price: <span className="card-Price">₹{item.Price}</span>
                    </p>
                  </div>
                  {/* <button onClick={() => handleAddToCart(item)}>ADD</button> */}
                </div>
              </div>
            ))
          ):CategoryProject &&
            CategoryProject.Projects &&
            CategoryProject.Projects.length > 0 ? (
              CategoryProject.Projects.map((item, index) => (
              // <div className="project-Card" key={index}>
              //   <div className="projectImageCard">
              //     <img
              //       className="project-img"
              //       src={item.OutputScreens}
              //       alt="porject-img"
              //       onError={(e) => {
              //         e.target.src =
              //           "https://www.timeoutdubai.com/cloud/timeoutdubai/2021/09/11/hfpqyV7B-IMG-Dubai-UAE-1200x800.jpg";
              //       }}
              //     />
              //   </div>
              //   <div className="projectInfoCard">
              //     <h4 className="projectTitle">
              //       <Link
              //         to={`/project/${item._id}`}
              //         onClick={() => setSingleProjectId(item._id)}
              //       >
              //         {/* {truncateText(item.ProjectTitle, 3)} */}
              //         {item.ProjectTitle.substring(0, 25)+"..."}

              //       </Link>
              //     </h4>

              //     <div className="projecttags">
              //       {item.TechnologiesUsed.slice(0, 2).map((item, i) => (
              //         <span key={i}>
              //           {" "}
              //           <i className="fa-regular fa-bookmark"></i> {item.text}
              //         </span>
              //       ))}
              //     </div>

              //     {/* <div className="Categories">
              //       <span style={{ color: "#06517a" }}>
              //         {item.mainCategory}
              //       </span>
              //       >>
              //       <span style={{ color: "#346581" }}>{item.subCategory}</span>
              //       >>
              //       <span style={{ color: "#506e7f" }}>
              //         {item.courseCategory}
              //       </span>
              //     </div> */}
              //     <div className="projectBuyBtn">
              //       {user &&
              //         user.cart &&
              //         user.orders &&
              //         (user.cart.some(
              //           (cartItem) => cartItem.ID === item._id
              //         ) ? (
              //           <Link to={"/cart"}>
              //             <button className="disableBTN">
              //               Already In Cart
              //             </button>
              //           </Link>
              //         ) : user.orders.some(
              //             (orderItem) => orderItem.ID === item._id
              //           ) ? (
              //           <Link to={"/order"}>
              //             <button className="disableBTN">
              //               Please Go to Orders
              //             </button>
              //           </Link>
              //         ) : (
              //           <button
              //             className="enableBTN"
              //             onClick={() => handleAddToCart(item)}
              //           >
              //             Add To Cart
              //           </button>
              //         ))}
              //       {!user && (
              //         <button
              //           className="enableBTN"
              //           onClick={() => (
              //             navigate("/login"), handleAddToCart(item)
              //           )}
              //         >
              //           Add To Cart
              //         </button>
              //       )}
              //     </div>
              //     {/* <button onClick={() => handleAddToCart(item)}>ADD</button> */}
              //   </div>
              // </div>
              <div className="project-Card" key={index}>
                <div className="projectImageCard">
                  <img
                    className="project-img"
                    src={item.OutputScreens}
                    alt="porject-img"
                    onError={(e) => {
                      e.target.src =
                        "https://www.timeoutdubai.com/cloud/timeoutdubai/2021/09/11/hfpqyV7B-IMG-Dubai-UAE-1200x800.jpg";
                    }}
                  />
                </div>
                <div className="projectInfoCard">
                  <div>
                    <div className="projectTitle">
                      <Link
                        to={`/project/${item._id}`}
                        onClick={() => setSingleProjectId(item._id)}
                      >
                        {/* {truncateText(item.ProjectTitle, 3)} */}

                        {item.ProjectTitle.substring(0, 25) + "..."}
                      </Link>
                    </div>
                  </div>
                  <div className="projecttags">
                    {item.TechnologiesUsed.slice(0, 2).map((item, i) => (
                      <span className="tags" key={i}>
                        {" "}
                        {item.text}
                      </span>
                    ))}
                  </div>

                  {/* <div className="Categories">
                  <span style={{ color: "#06517a" }}>
                    {item.mainCategory}
                  </span>
                  >>
                  <span style={{ color: "#346581" }}>{item.subCategory}</span>
                  >>
                  <span style={{ color: "#506e7f" }}>
                    {item.courseCategory}
                  </span>
                </div> */}
                  <div className="projectBuyBtn">
                    {user &&
                      user.cart &&
                      user.orders &&
                      (user.cart.some(
                        (cartItem) => cartItem.ID === item._id
                      ) ? (
                        <Link to={"/cart"}>
                          <button className="disableBTN">
                            Already In Cart
                          </button>
                        </Link>
                      ) : user.orders.some(
                          (orderItem) => orderItem.ID === item._id
                        ) ? (
                        <Link to={"/order"}>
                          <button className="disableBTN">
                            Please Go to Orders
                          </button>
                        </Link>
                      ) : (
                        <button
                          className="enableBTN"
                          onClick={() => handleAddToCart(item)}
                        >
                          Add To Cart
                        </button>
                      ))}
                    {!user && (
                      <button
                        className="enableBTN"
                        onClick={() => (
                          navigate("/login"), handleAddToCart(item)
                        )}
                      >
                        Add To Cart
                      </button>
                    )}
                  </div>
                  <div className="CoursePrice">
                    <p>
                      Price: <span className="card-Price">₹{item.Price}</span>
                    </p>
                  </div>
                  {/* <button onClick={() => handleAddToCart(item)}>ADD</button> */}
                </div>
              </div>
            ))
          ) : (
            <div className="no-course-found-container">
              <p className="no-course-found-text">
                Sorry..!, we couldn't find any result.
              </p>

              <img src={NoData} alt="No Projects" />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default CategoryProject;
